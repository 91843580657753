import React from "react";
import "./cards.scss";

export default function PreviousOrderProductCard({ product }) {
    const isPackagingOrder = !!product?.product_packaging_id;

    return (
        <div className="previous-order-product-card">
            <div className="product-title mb-3">
                {product?.name_short}
                {isPackagingOrder
                    ? ` - ${product?.product_packaging_id[1]}`
                    : ""}
            </div>
            <div className="product-quantity d-flex align-items-center">
                Qty - {product?.product_uom_qty}
            </div>
        </div>
    );
}
