import React, { useMemo, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import _ from "lodash";

import { useNavigate } from "react-router-dom";
import { useRouting } from "../../hooks/routesHooks";
import { ROUTES } from "../../config/routesConfig";

import { useGetOrdersQuery } from "../../services/orderService";
import { parseCurrencyValue } from "../../utils/currencyUtils";
import { getChipVariant } from "../../utils/dataUtils";
import { orderStatusTypes } from "../../constants/orderStatusTypes";
import { orderStatusFilters } from "../../constants/orderStatusFilters";

import PaginatedTable from "../../components/tables/PaginatedTable";
import Chip from "../../components/Chip";
import SectionTitle from "../../components/SectionTitle";
import TableFilter from "../../components/TableFilter";
import TableSkeleton from "../../components/skeletons/TableSkeleton";

import "./profilePages.scss";

dayjs.extend(customParseFormat);

export default function ProfileOrdersPage() {
    const navigate = useNavigate();
    const { getRouteLink } = useRouting();

    const [activeStatusType, setActiveStatusType] = useState(
        orderStatusTypes.ALL
    );
    const [selectedPage, setSelectedPage] = useState(1);

    const pageSize = 8;

    const getOrdersPayload = () => {
        let payload = {
            page_size: pageSize,
            page: selectedPage,
        };

        if (activeStatusType !== orderStatusTypes.ALL) {
            payload = { ...payload, state: activeStatusType };
        }

        return payload;
    };

    const { data: orderList, isLoading: isOrderListLoading } =
        useGetOrdersQuery(getOrdersPayload());

    const pageStartIndex = orderList?.previous_page
        ? orderList?.previous_page * pageSize + 1
        : 1;
    const pageEndIndex = orderList?.next_page
        ? orderList?.current_page * pageSize
        : orderList?.total_count;
    const totalPageCount = _.ceil(orderList?.total_count / pageSize);

    const formattedOrderList = orderList?.response?.map((order, index) => {
        return { ...order, order_no: index + pageStartIndex };
    });

    const statusFilterOptions = [
        {
            value: orderStatusTypes.ALL,
            label: orderStatusFilters.ALL,
        },
        {
            value: orderStatusTypes.COMPLETE,
            label: orderStatusFilters.COMPLETED,
        },
        {
            value: orderStatusTypes.PLACED,
            label: orderStatusFilters.PLACED,
        },
        {
            value: orderStatusTypes.CANCEL,
            label: orderStatusFilters.CANCELLED,
        },
    ];

    const handleViewOrderDetails = (orderId) => {
        navigate(getRouteLink(ROUTES.PROFILE_ORDER_DETAIL, { orderId }));
    };

    const handleOnFilterChange = (value) => {
        setSelectedPage(1);
        setActiveStatusType(value);
    };

    const handleScrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const handleGotoPage = (e) => {
        const selectedValue = parseInt(e.target.value);
        setSelectedPage(selectedValue + 1);
        handleScrollToTop();
    };

    const handleGotoNextPage = () => {
        setSelectedPage(orderList?.next_page);
        handleScrollToTop();
    };

    const handleGotoPreviousPage = () => {
        setSelectedPage(orderList?.previous_page);
        handleScrollToTop();
    };

    const columns = [
        {
            header: "Order No",
            accessor: (row) => row?.order_no,
            disableSortBy: true,
        },
        {
            header: "Order ID",
            accessor: (row) => row?.name,
        },
        {
            header: "Order Date",
            accessor: (row) =>
                row?.date_order && dayjs(row?.date_order).isValid()
                    ? dayjs(row?.date_order).format("DD.MM.YYYY")
                    : null,
            sortType: useMemo(() => {
                return function (rowA, rowB, id) {
                    if (
                        _.isString(rowA.values[id]) &&
                        dayjs(rowA.values[id], "DD.MM.YYYY").isValid() &&
                        _.isString(rowB.values[id]) &&
                        dayjs(rowB.values[id], "DD.MM.YYYY").isValid()
                    ) {
                        const aDate = dayjs(rowA.values[id]);
                        const bDate = dayjs(rowB.values[id]);

                        if (aDate.isBefore(bDate)) {
                            return -1;
                        }
                        if (aDate.isAfter(bDate)) {
                            return 1;
                        }
                        return 0;
                    }
                };
            }, []),
        },
        {
            header: "Price",
            accessor: (row) =>
                parseCurrencyValue(
                    row?.amount_total,
                    false,
                    row?.currency_id[1]
                ),
            sortType: useMemo(() => {
                return function (rowA, rowB, id) {
                    if (
                        _.isString(rowA.values[id]) &&
                        _.isString(rowB.values[id])
                    ) {
                        const priceA = Number(rowA.values[id].substring(1));
                        const priceB = Number(rowB.values[id].substring(1));
                        if (priceA < priceB) {
                            return -1;
                        }
                        if (priceA > priceB) {
                            return 1;
                        }
                        return 0;
                    }
                };
            }, []),
        },
        {
            header: "Order Status",
            accessor: (row) => (
                <Chip
                    text={getChipVariant(row.state).text}
                    variant={getChipVariant(row.state).variant}
                />
            ),
            disableSortBy: true,
        },
        {
            header: " ",
            accessor: (row) => (
                <div
                    role="button"
                    onClick={() => handleViewOrderDetails(row.id)}
                >
                    <img src="/vectors/kasi-chevron-right-black.svg" alt=">" />
                </div>
            ),
            disableSortBy: true,
        },
    ];

    return (
        <div>
            <div className="px-3 px-sm-5 py-4">
                <div className="row mb-4">
                    <div className="col-md-10">
                        <SectionTitle text="My Orders" />
                    </div>
                </div>
                <div className="filter-section pb-5 mb-3">
                    <TableFilter
                        options={statusFilterOptions}
                        value={activeStatusType}
                        onChange={handleOnFilterChange}
                    />
                </div>
                <div className="w-100">
                    {isOrderListLoading ? (
                        <div className="w-100">
                            <TableSkeleton columnCount={6} />
                        </div>
                    ) : !formattedOrderList?.length ? (
                        <div className="d-flex justify-content-center mt-5">
                            <div className="alert alert-primary">
                                No orders available yet...
                            </div>
                        </div>
                    ) : (
                        <PaginatedTable
                            data={formattedOrderList}
                            columns={columns}
                            pageSize={pageSize}
                            isSortable
                            startIndex={pageStartIndex}
                            endIndex={pageEndIndex}
                            currentPage={orderList?.current_page - 1}
                            totalCount={orderList?.total_count}
                            pageCount={totalPageCount}
                            isNext={!!orderList?.next_page}
                            isPrevious={!!orderList?.previous_page}
                            onGoToPage={handleGotoPage}
                            onGoToPreviousPage={handleGotoPreviousPage}
                            onGoToNextPage={handleGotoNextPage}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
