import { createContext } from "react";
import _ from "lodash";
import { useLoginStatus } from "../hooks/authHooks";
import { useGetCartQuery } from "../services/cartService";
import { ORDER_ID } from "../constants";

export const CartContext = createContext({
    cartItems: {},
    refetchCart: () => {},
});

export default function CartProvider({ children }) {
    const isLoggedIn = useLoginStatus();
    const publicOrderId = localStorage.getItem(ORDER_ID);

    const {
        data: cartData,
        refetch,
        isLoading: isCartLoading,
    } = useGetCartQuery(
        publicOrderId || !isLoggedIn
            ? { cartData: { order_id: publicOrderId } }
            : { withCredentials: true }
    );

    const refetchCart = () => {
        refetch();
    };

    const cartItems = _.isObject(cartData) ? cartData : {};

    return (
        <CartContext.Provider
            value={{
                cartItems,
                refetchCart,
            }}
        >
            {children}
        </CartContext.Provider>
    );
}
