import React from "react";
import "./pagination.scss";

export default function Pagination({
    startIndex,
    endIndex,
    currentPage,
    totalCount,
    pageCount,
    isNext = true,
    isPrevious = true,
    onGoToNext,
    onGoToPrevious,
    onGoToPage,
}) {
    const handleGotoPage = (e) => {
        if (onGoToPage) {
            onGoToPage(e);
        }
    };

    const handleGotoPrevious = () => {
        if (onGoToPrevious) {
            onGoToPrevious();
        }
    };

    const handleGotoNext = () => {
        if (onGoToNext) {
            onGoToNext();
        }
    };

    return (
        <div className="pagination-wrapper mt-5 pt-3">
            <div className="title">
                {startIndex} - {endIndex} of {totalCount} Orders
            </div>
            <div className="title d-flex align-items-center">
                <div className="me-3">This page you are on</div>

                <select
                    value={currentPage}
                    onChange={handleGotoPage}
                    className="me-4"
                >
                    {[...Array(pageCount)].map((_, p) => (
                        <option key={p} value={p}>
                            {p + 1}
                        </option>
                    ))}
                </select>
                {isNext && (
                    <button className="arrow-btn me-1" onClick={handleGotoNext}>
                        <img
                            src="/vectors/kasi-chevron-black-right-sm.svg"
                            alt=""
                        />
                    </button>
                )}
                {isPrevious && (
                    <button className="arrow-btn" onClick={handleGotoPrevious}>
                        <img
                            src="/vectors/kasi-chevron-black-left-sm.svg"
                            alt=""
                        />
                    </button>
                )}
            </div>
        </div>
    );
}
