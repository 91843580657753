import React from "react";

import "./policyPages.scss";

export default function ShippingPolicyPage() {
    return (
        <div className="container p-5">
            <div className="main-title text-center mb-5">Shipping Policy</div>
            <div className="mb-4">
                <p className="content m-0">
                    Following are the term and conditions that constitutes our
                    shipping policy.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Shipping Area and "Estimates"</div>
                <p className="content m-0">
                    We deliver only in selected areas in South Africa, we have
                    plans to rollout to multiple locations later. Please refer
                    to our Website or Mobile app for the list of the area that
                    is currently covered by us for order delivery. We strive to
                    deliver all orders within 2 days from order placed, It can
                    be as quick as with in 24 hours.
                </p>
                <br />
                <p className="content m-0">
                    If an order takes longer than the "estimate" no claims can
                    be made towards Africa Distribution Technologies Pty Ltd, we
                    will try our best to sort the situation. To constantly
                    maintain higher accuracy of on time delivery we request all
                    our customers to make sure their Default delivery address
                    has all the fields. Customers are also requested to be
                    available at the delivery address provided as per the date
                    and time slot provided during order placement for faster and
                    accurate delivery of goods.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Shipping rates</div>
                <br />
                <p className="content m-0">
                    Free delivery on orders above R500.
                </p>
                <br />
                <p className="content m-0">
                    R30 basic delivery fee on orders below R500
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Failed or missed delivery</div>
                <p className="content m-0">
                    We have an estimated delivery time of 2 working days,
                    depending on location and area given for delivery. If a
                    customer does not answer, or neglects the delivery team
                    call, We will reach out to the customer one more time to
                    arrange for stock delivery. Failing to be at the correct
                    provided address of delivery for more than 2 times can lead
                    to suspension of account and can be renewed only after
                    payment of R 100 penalty.
                </p>
            </div>
        </div>
    );
}
