import _ from "lodash";
import validateColor from "validate-color";

const productQuantities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const productVariants = [
    { label: "Red", value: "RED" },
    { label: "Blue", value: "BLUE" },
    { label: "Yellow", value: "YELLOW" },
    { label: "Green", value: "GREEN" },
];

export const productQuantityOptions = productQuantities.map((val) => ({
    value: val,
    label: val,
}));

export const resolveProductVariantLabelForEnum = (variant) => {
    const resolvedVariant = productVariants.find(
        ({ value }) => variant === value
    );
    if (resolvedVariant) {
        return resolvedVariant.label;
    }
    return variant;
};

// TODO: remove above utils after integrations
// Integration specifics

export const getColorForVariant = (product) => {
    let color;
    if (product?.product_attribute_values?.length) {
        const { product_attribute_values: attributeValues } = product;
        const colorAttribute = [...attributeValues].find(
            ({ display_type: displayType }) => displayType === "color"
        );
        if (colorAttribute?.name) {
            const { name } = colorAttribute;
            if (validateColor(name.toLowerCase())) {
                color = name.toLowerCase();
            }
        }
    }
    return color;
};

export const resolveProductAttributesWithIds = (productVariant = {}) => {
    let attributes = {};
    if (productVariant?.product_attribute_values) {
        productVariant.product_attribute_values.forEach((attribute) => {
            const attributeName = attribute.display_name.split(":")[0];
            const attributeId = attribute.id;
            attributes = {
                ...attributes,
                [attributeName]: attributeId,
            };
        });
    }

    return attributes;
};

export const getAllValidProductVariants = (
    productVariants = []
) => {
    let variants = [];
    if (productVariants?.length) {
        // Should be have attribute of type 'color' && html safe color
        const variantsWithValidColor = [];
        // Other type attributes (Invalid colors are not added here)
        const otherVariants = [];
        productVariants.forEach((
            { product_attribute_values: attributeValues },
            idx,
        ) => {
            let colorAttribute;

            if (attributeValues?.length) {
                colorAttribute = attributeValues.find(({ display_type: displayType }) => displayType === "color");
                if (colorAttribute) {
                    if (validateColor(colorAttribute.name)) {
                        variantsWithValidColor.push(productVariants[idx]);
                    }
                } else {
                    otherVariants.push(productVariants[idx]);
                }
            } else {
                otherVariants.push(productVariants[idx]);
            }
        }
        );
        variants =
            variantsWithValidColor.concat([...otherVariants]);
    }
    return variants;
};

export const getAllAttributeOptionsForProductVariants = (productVariants = []) => {
    let uniqAttributes = [];
    let groupedAttributes = {};
    let allAttributes = [];
    const variantsWithValidColors =
        getAllValidProductVariants(productVariants);
    variantsWithValidColors.forEach((product) => {
        if (product?.product_attribute_values?.length) {
            let { product_attribute_values: attributeValues } = product;
            attributeValues = attributeValues.map((attributeValue) => {
                let attribute = attributeValue.display_name.split(":")[0];
                return { ...attributeValue, attribute: `${attribute}` };
            });
            allAttributes = allAttributes.concat(attributeValues);
        }
    });
    uniqAttributes = _.uniqBy(allAttributes, 'id');

    groupedAttributes = _.mapValues(_.groupBy(uniqAttributes, 'attribute'),
        attributeList => attributeList.map(({ id, name = "" }) => ({
            value: id,
            label: name,
            name: name.toLowerCase(),
        })));

    return groupedAttributes;
};

export const getProductVariantPriceFormat = (product, selectedPriceListIdx) => {
    const format = {
        listPrice: 0,
        discountedPrice: 0,
        discountPercentage: 0,
    };
    if (product?.lst_price) {
        const { lst_price: listPrice, pricelist_items: priceListItems } =
            product;
        format.listPrice = listPrice;
        if (priceListItems?.length) {
            const [priceListItem] = priceListItems.filter(item => item.pricelist_id[0] === selectedPriceListIdx);
            if (
                priceListItem?.active &&
                priceListItem?.fixed_price < listPrice
            ) {
                const { fixed_price: discountedPriceAmount } = priceListItem;
                const percentageAsDecimal = discountedPriceAmount / listPrice;
                format.discountedPrice = discountedPriceAmount;
                format.discountPercentage = Number(
                    (1 - percentageAsDecimal) * 100
                ).toFixed(2);
            }
        }
    }
    return format;
};

export const parseTemplatesFromAlternateVariants = (variants = []) => {
    let templates = [];
    if (variants?.length) {
        const variantsWithTemplates = [...variants].filter(
            ({ product_tmpl_id: templateDetailsArray }) =>
                !!templateDetailsArray?.[0]
        );
        const duplicateRemovedVariantsWithTemplates = _.uniqBy(
            variantsWithTemplates,
            ({ product_tmpl_id: templateDetailsArray }) =>
                templateDetailsArray[0]
        );
        templates = duplicateRemovedVariantsWithTemplates.map(
            ({
                product_tmpl_id: templateDetailsArray,
                list_price: listPrice,
                qty_available: qtyAvailable,
                pricelist_item_count: pricelistItemCount,
                image_128: image128,
                currency_id: currencyId,
                name,
                description,
            }) => ({
                id: templateDetailsArray[0],
                list_price: listPrice,
                qty_available: qtyAvailable,
                pricelist_item_count: pricelistItemCount,
                image_128: image128,
                currency_id: currencyId,
                name,
                description,
            })
        );
    }
    return templates;
};

export const productFilterPriceOptions = [
    {
        id: 1,
        label: "Under ZAR 20.00",
        min: 0,
        max: 20,
    },
    {
        id: 2,
        label: "ZAR 20.00 to ZAR 55.00",
        min: 20,
        max: 55,
    },
    {
        id: 3,
        label: "Over ZAR 55.00",
        min: 55,
    },
];

export const productSortOptions = [
    { id: 1, name: "Newest" },
    {
        id: 2,
        name: "Price (low to high)",
        sortConfig: { order_by: "list_price asc" },
    },
    {
        id: 3,
        name: "Price (high to low)",
        sortConfig: { order_by: "list_price desc" },
    },
    {
        id: 4,
        name: "Name (A to Z)",
        sortConfig: { order_by: "name asc" },
    },
    {
        id: 5,
        name: "Name (Z to A)",
        sortConfig: { order_by: "name desc" },
    },
];
