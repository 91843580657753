import React, { useMemo, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import _ from "lodash";

import { parseCurrencyValue } from "../../utils/currencyUtils";
import { useNavigate } from "react-router-dom";
import { useRouting } from "../../hooks/routesHooks";
import { ROUTES } from "../../config/routesConfig";

import { useGetInvoicesQuery } from "../../services/invoiceService";
import { orderStatusTypes } from "../../constants/orderStatusTypes";
import { paymentStatusTypes } from "../../constants/paymentStatusTypes";

import PaginatedTable from "../../components/tables/PaginatedTable";
import Chip from "../../components/Chip/Chip";
import SectionTitle from "../../components/SectionTitle";
import TableFilter from "../../components/TableFilter";
import TableSkeleton from "../../components/skeletons/TableSkeleton";

import { invoiceStatusFilters } from "../../constants/invoiceStatusFilters";

import "./profilePages.scss";

dayjs.extend(customParseFormat);

export default function ProfileInvoicesPage() {
    const navigate = useNavigate();
    const { getRouteLink } = useRouting();

    const [activeStatusType, setActiveStatusType] = useState(
        paymentStatusTypes.ALL
    );
    const [selectedPage, setSelectedPage] = useState(1);

    const pageSize = 8;

    const getInvoicesPayload = () => {
        let payload = {
            page_size: pageSize,
            page: selectedPage,
        };

        if (activeStatusType !== paymentStatusTypes.ALL) {
            payload = { ...payload, payment_state: activeStatusType };
        }

        return payload;
    };

    const { data: invoicesList, isLoading: isInvoicesLoading } =
        useGetInvoicesQuery(getInvoicesPayload());

    const pageStartIndex = invoicesList?.previous_page
        ? invoicesList?.previous_page * pageSize + 1
        : 1;
    const pageEndIndex = invoicesList?.next_page
        ? invoicesList?.current_page * pageSize
        : invoicesList?.total_count;
    const totalPageCount = _.ceil(invoicesList?.total_count / pageSize);

    const formattedInvoicesList = invoicesList?.response?.map(
        (invoice, index) => {
            return { ...invoice, invoice_no: index + pageStartIndex };
        }
    );

    const statusFilterOptions = [
        {
            value: paymentStatusTypes.ALL,
            label: invoiceStatusFilters.ALL,
        },
        {
            value: paymentStatusTypes.COMPLETE,
            label: invoiceStatusFilters.PAID,
        },
        {
            value: paymentStatusTypes.PENDING,
            label: invoiceStatusFilters.PENDING,
        },
    ];

    const handleViewInvoiceDetails = (invoiceId) => {
        navigate(getRouteLink(ROUTES.PROFILE_INVOICE_DETAIL, { invoiceId }));
    };

    const handleOnFilterChange = (value) => {
        setSelectedPage(1);
        setActiveStatusType(value);
    };

    const handleScrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const handleGotoPage = (e) => {
        const selectedValue = parseInt(e.target.value);
        setSelectedPage(selectedValue + 1);
        handleScrollToTop();
    };

    const handleGotoNextPage = () => {
        setSelectedPage(invoicesList?.next_page);
        handleScrollToTop();
    };

    const handleGotoPreviousPage = () => {
        setSelectedPage(invoicesList?.previous_page);
        handleScrollToTop();
    };

    const getChipVariant = (state) => {
        let variant = "";
        let text = "";

        switch (state) {
            case paymentStatusTypes.COMPLETE:
                variant = "success";
                text = invoiceStatusFilters.PAID;
                break;
            case paymentStatusTypes.PENDING:
                variant = "warning";
                text = invoiceStatusFilters.PENDING;
                break;
            default:
                text = "Not Provided";
                break;
        }
        return { variant: variant, text: text };
    };

    const columns = [
        {
            header: "Invoice No",
            accessor: (row) => row?.invoice_no,
            disableSortBy: true,
        },
        {
            header: "Invoice ID",
            accessor: (row) => row?.name,
        },
        {
            header: "Invoice Date",
            accessor: (row) =>
                row?.date && dayjs(row?.date).isValid()
                    ? dayjs(row.date).format("DD.MM.YYYY")
                    : null,
            sortType: useMemo(() => {
                return function (rowA, rowB, id) {
                    if (
                        _.isString(rowA.values[id]) &&
                        dayjs(rowA.values[id], "DD.MM.YYYY").isValid() &&
                        _.isString(rowB.values[id]) &&
                        dayjs(rowB.values[id], "DD.MM.YYYY").isValid()
                    ) {
                        const aDate = dayjs(rowA.values[id]);
                        const bDate = dayjs(rowB.values[id]);
                        if (aDate.isBefore(bDate)) {
                            return -1;
                        }
                        if (aDate.isAfter(bDate)) {
                            return 1;
                        }
                        return 0;
                    }
                };
            }, []),
        },
        {
            header: "Price",
            accessor: (row) => parseCurrencyValue(row.amount_total),
            sortType: useMemo(() => {
                return function (rowA, rowB, id) {
                    if (rowA.values[id] < rowB.values[id]) {
                        return -1;
                    }
                    if (rowA.values[id] > rowB.values[id]) {
                        return 1;
                    }
                    return 0;
                };
            }, []),
        },
        {
            header: "Invoice Status",
            accessor: (row) => (
                <Chip
                    text={getChipVariant(row.payment_state).text}
                    variant={getChipVariant(row.payment_state).variant}
                />
            ),
            disableSortBy: true,
        },
        {
            header: " ",
            accessor: (row) => (
                <div
                    role="button"
                    onClick={() => handleViewInvoiceDetails(row.id)}
                >
                    <img src="/vectors/kasi-chevron-right-black.svg" alt=">" />
                </div>
            ),
            disableSortBy: true,
        },
    ];

    return (
        <div className="px-3 px-sm-5 py-4">
            <div className="row mb-4">
                <div className="col-md-10">
                    <SectionTitle text="Invoice" />
                </div>
            </div>
            <div className="filter-section pb-5 mb-3">
                <TableFilter
                    options={statusFilterOptions}
                    value={activeStatusType}
                    onChange={handleOnFilterChange}
                />
            </div>

            <div className="w-100">
                {isInvoicesLoading ? (
                    <TableSkeleton columnCount={6} />
                ) : formattedInvoicesList?.length ? (
                    <PaginatedTable
                        data={formattedInvoicesList}
                        columns={columns}
                        pageSize={pageSize}
                        isSortable
                        startIndex={pageStartIndex}
                        endIndex={pageEndIndex}
                        currentPage={invoicesList?.current_page - 1}
                        totalCount={invoicesList?.total_count}
                        pageCount={totalPageCount}
                        isNext={!!invoicesList?.next_page}
                        isPrevious={!!invoicesList?.previous_page}
                        onGoToPage={handleGotoPage}
                        onGoToPreviousPage={handleGotoPreviousPage}
                        onGoToNextPage={handleGotoNextPage}
                    />
                ) : (
                    <div className="d-flex justify-content-center mt-5">
                        <div className="alert alert-primary">
                            No invoice available yet...
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
