import "./skeletons.scss";

export default function InvoiceDetailSkeleton() {
    return (
        <div className="invoice-detail-skeleton">
            <div className="placeholder-glow row mb-5 pb-4 align-items-center flex-column">
                <div className="checkmark-wrapper placeholder rounded-circle" />
            </div>
            <div className="row mt-5 pt-5">
                <div className="d-flex justify-content-between">
                    <div className="placeholder-glow w-25">
                        <div className="placeholder rounded col-12 mb-4" />
                        <div className="placeholder rounded col-9 mb-4" />
                        <div className="placeholder rounded col-6 mb-4" />
                        <div className="placeholder rounded p-3 col-12 my-4" />
                    </div>
                    <div className="placeholder-glow w-25">
                        <div className="placeholder rounded col-12 mb-4" />
                        <div className="placeholder rounded col-12 mb-4" />
                        <div className="placeholder rounded col-12 mb-4" />
                        <div className="placeholder rounded p-3 col-12 my-4" />
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-end my-5 pb-5">
                <div className="placeholder-glow col-4">
                    <div className="placeholder p-4 rounded w-100" />
                </div>
                <div className="placeholder-glow col-4 ms-4">
                    <div className="placeholder p-4 rounded w-100" />
                </div>
            </div>
        </div>
    );
}
