import React from "react";
import Slider from "react-slick";
import {
    parseBase64ImgSrc,
    getFormattedValidPeriod,
} from "../../utils/dataUtils";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.scss";

export default function PromotionCarousel({
    promotions = [],
    showBackground = false,
}) {
    return (
        <div className="d-flex flex-column align-items-center">
            <div className="mw-100">
                <div className="promotion-container">
                    <Slider
                        initialSlide={0}
                        arrows
                        slidesToShow={1}
                        slidesToScroll={1}
                        infinite={true}
                        autoplay={promotions?.length > 1}
                        speed={1000}
                        autoplaySpeed={3000}
                        cssEase="linear"
                    >
                        {promotions?.map((item) => (
                            <div
                                key={item.id}
                                className="banner-image-wrapper w-100"
                            >
                                {item?.image ? (
                                    <img
                                        src={parseBase64ImgSrc(item?.image)}
                                        alt=""
                                        className={`banner-image ${
                                            showBackground ? "image-bg" : ""
                                        }`}
                                    />
                                ) : (
                                    <div className="banner-fallback">
                                        <div className="h2 text-center w-50">
                                            {item?.name}
                                        </div>
                                        <div className="valid-date px-3 py-1 mt-3">
                                            {getFormattedValidPeriod(
                                                item?.start_date,
                                                item?.end_date
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}
