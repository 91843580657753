import { createApi } from "@reduxjs/toolkit/query/react";
import { publicBaseQuery } from "./baseQueries";

export const publicProductAPI = createApi({
    reducerPath: "publicProductAPI",
    baseQuery: publicBaseQuery,
    tagTypes: [
        "ProductTemplates",
        "ProductTemplate",
        "VariantsForTemplate",
        "VariantForTemplate",
        "AlternativeProductTemplates"
    ],
    endpoints: (build) => ({
        getPaginatedProductTemplates: build.query({
            query: (params) => ({
                url: "/api/product-templates/",
                method: "POST",
                body: {
                    params: {
                        ...params,
                        method: 'GET',
                    },
                },
            }),
            transformResponse: (response) => response?.result,
            providesTags: (result, error, params) => [
                { type: "ProductTemplates", id: params?.page },
            ],
        }),
        getProductTemplate: build.query({
            query: ({ templateId: id }) => ({
                url: `/api/product-templates/${id}/`,
                method: "POST",
                body: {
                    params: {
                        method: 'GET',
                    },
                },
            }),
            transformResponse: (response) => response?.result?.response,
            providesTags: (result, error, { templateId: id }) => [
                { type: "ProductTemplate", id },
            ],
        }),
        getProductVariantsForTemplate: build.query({
            query: ({ templateId: id }) => ({
                url: `/api/product-templates/${id}/products/`,
                method: "POST",
                body: {
                    params: {
                        method: 'GET',
                    },
                },
            }),
            transformResponse: (response) => response?.result?.response,
            providesTags: (result, error, { templateId: id }) => [
                { type: "VariantsForTemplate", id },
            ],
        }),
        getProductVariantForTemplate: build.query({
            query: ({ templateId, variantId }) => ({
                url: `/api/product-templates/${templateId}/products/${variantId}/`,
                method: "POST",
                body: {
                    params: {
                        method: 'GET',
                    },
                },
            }),
            transformResponse: (response) => response?.result?.response[0],
            providesTags: ["VariantForTemplate"],
        }),
        getAlternativeTemplatesForProduct: build.query({
            query: ({ variantId }) => ({
                url: `/api/products/${variantId}/alternative-products/`,
                method: "POST",
                body: {
                    params: {
                        method: 'GET',
                    },
                },
            }),
            transformResponse: (response) => response?.result?.response,
            providesTags: (result, error, { templateId, variantId }) => [
                { type: "AlternativeProductTemplates", id: `${templateId}-${variantId}` },
            ],
        }),
    }),
});

export const {
    useGetPaginatedProductTemplatesQuery,
    useGetProductTemplateQuery,
    useGetProductVariantsForTemplateQuery,
    useGetProductVariantForTemplateQuery,
    useGetAlternativeTemplatesForProductQuery,
} = publicProductAPI;
