import { createApi } from "@reduxjs/toolkit/query/react";
import { publicBaseQuery } from "./baseQueries";

export const brandAPI = createApi({
    reducerPath: "brandAPI",
    baseQuery: publicBaseQuery,
    tagTypes: ["Brands", "Brand"],
    endpoints: (build) => ({
        getBrands: build.query({
            query: () => ({
                url: "/api/brands/",
                method: "POST",
                body: {
                    params: { method: "GET" },
                },
            }),
            providesTags: ["Brands"],
            transformResponse: (response) => response.result.response,
        }),
        getBrand: build.query({
            query: ({ id }) => ({
                url: `/api/brands/${id}/`,
                method: "POST",
                body: {
                    params: { method: "GET" },
                },
            }),
            providesTags: ["Brand"],
        }),
    }),
});

export const { useGetBrandsQuery, useGetBrandQuery } = brandAPI;
