import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { ROUTES } from "../../config/routesConfig";
import { useRouting } from "../../hooks/routesHooks";
import { useCart } from "../../hooks/cartHooks";

import ProductCard from "../../components/cards/ProductCard";
import ProductInformation from "../../components/ProductInformation/ProductInformation";
import ProductInformationSkeleton from "../../components/skeletons/ProductInformationSkeleton";
import ProductImageCardSkeleton from "../../components/skeletons/ProductImageCardSkeleton";
import ProductCardSkeleton from "../../components/skeletons/ProductCardSkeleton";
import PromotionCarousel from "../../components/carousels/PromotionCarousel";

import {
    useGetProductVariantsForTemplateQuery,
    useGetAlternativeTemplatesForProductQuery,
} from "../../services/productService";
import {
    useGetPriceListsQuery,
    useGetDealsQuery,
} from "../../services/dataService";

import {
    getAllValidProductVariants,
    parseTemplatesFromAlternateVariants,
} from "../../utils/productUtils";

import "./productDetailPage.scss";

export default function ProductDetailPage() {
    const navigate = useNavigate();
    const { getRouteLink } = useRouting();

    const { productId: productTemplateID } = useParams();

    const [activeVariantIdx, setActiveVariantIdx] = React.useState(0);

    const {
        data: availableProductVariants,
        isFetching: isAvailableVariantsLoading,
    } = useGetProductVariantsForTemplateQuery(
        { templateId: productTemplateID },
        { skip: !productTemplateID }
    );
    const { data: allPriceLists, isFetching: isAllPriceListsLoading } =
        useGetPriceListsQuery();
    const { data: allDeals } = useGetDealsQuery();

    const { cartItems } = useCart();

    const allValidProductVariants = getAllValidProductVariants(
        availableProductVariants
    );

    const activeVariantId = allValidProductVariants?.[activeVariantIdx]?.id;

    const {
        data: alternativeProductVariantsWithTemplates,
        isFetching: isAlternativeTemplatesLoading,
    } = useGetAlternativeTemplatesForProductQuery(
        { variantId: activeVariantId },
        { skip: !activeVariantId }
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [productTemplateID]);

    useEffect(() => {
        if (allValidProductVariants?.length && cartItems?.order_line?.length) {
            const itemIds = cartItems?.order_lines?.map(
                ({ product_id }) => product_id[0]
            );

            const idxToFocus = allValidProductVariants.findIndex(({ id }) =>
                itemIds.includes(id)
            );
            if (idxToFocus > -1) {
                setActiveVariantIdx(idxToFocus);
            }
        }
    }, [cartItems]);

    const handleOnClickProduct = (productId) => {
        navigate(getRouteLink(ROUTES.PRODUCT_DETAIL, { productId }));
    };

    const alternativeTemplates = parseTemplatesFromAlternateVariants(
        alternativeProductVariantsWithTemplates
    );

    const featuredDeals = allDeals?.filter((item) => item.is_featured);

    const sortedPriceList = _.orderBy(allPriceLists?.response, ["id"], ["asc"]);

    return (
        <div className="product-detail-page">
            <div className="product-detail-container m-5 mb-3">
                {isAvailableVariantsLoading && isAllPriceListsLoading ? (
                    <>
                        <div className="w-md-50 w-100 px-lg-5">
                            <ProductImageCardSkeleton />
                        </div>
                        <div className="w-md-50 w-100 mt-4 mt-md-0">
                            <ProductInformationSkeleton />
                        </div>
                    </>
                ) : (
                    <ProductInformation
                        productVariantList={allValidProductVariants}
                        activeVariantIdx={activeVariantIdx}
                        setActiveVariantIdx={setActiveVariantIdx}
                        discountList={sortedPriceList}
                    />
                )}
            </div>
            <div className="alternative-products-container mx-5">
                <div className="container-title mb-3">Alternative Products</div>
                <div className="alternative-products-wrapper row-cols-xl-5">
                    {isAlternativeTemplatesLoading ? (
                        new Array(8).fill(1).map((val, idx) => (
                            <div
                                key={idx}
                                className="col-12 col-sm-6 col-md-4 col-lg-3"
                            >
                                <ProductCardSkeleton />
                            </div>
                        ))
                    ) : alternativeTemplates?.length ? (
                        alternativeTemplates.map((productTemplate) => (
                            <div
                                key={productTemplate.id}
                                className="col-12 col-sm-6 col-md-4 col-lg-3"
                            >
                                <ProductCard
                                    product={productTemplate}
                                    onClick={() =>
                                        handleOnClickProduct(productTemplate.id)
                                    }
                                />
                            </div>
                        ))
                    ) : (
                        <div className="d-flex align-items-center w-100">
                            <div>
                                <div className="alert alert-primary">
                                    No alternative products available yet...
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {featuredDeals?.length > 0 && (
                <PromotionCarousel promotions={featuredDeals} showBackground/>
            )}
        </div>
    );
}
