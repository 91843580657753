import React from "react";

import "./policyPages.scss";

export default function ReturnPolicyPage() {
    return (
        <div className="container p-5">
            <div className="main-title text-center mb-5">
                Returns & Refund policy
            </div>
            <div className="mb-4">
                <div className="sub-title">
                    Order refund and addition deletion of products
                </div>
                <p className="content m-0">
                    It is not possible to cancel and order once an order is
                    placed at https://www.kasiwarehouse.co.za. Customers will
                    not be able to remove or add products to order once order
                    confirmation has been processed by us. For any additional
                    product customer can place another new order and select same
                    date and time slot of delivery if there is open slot
                    available.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">
                    Exchanges / Damaged Items or Missing Items (if applicable)
                </div>
                <p className="content m-0">
                    If goods received are damaged it must be mentioned on proof
                    of delivery and should be returned, we will make an
                    arrangement with you to provide replacement goods or return
                    of funds with in 48 hours.. Please note we have images and
                    tracking of every delivery that is processed by us. Send us
                    photo of the damaged item or damaged delivery on our
                    customer care.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Warranty Policy</div>
                <p className="content m-0">
                    All warranties, where applicable is the manufacturers
                    responsibility and not African Distribution technologies Pty
                    Ltd, in case of warranty issues the manufacturer should be
                    contacted.
                </p>
            </div>
        </div>
    );
}
