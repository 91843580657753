import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    formData: {},
};

export const registerSlice = createSlice({
    name: "register",
    initialState,
    reducers: {
        setRegisterData: (state, { payload }) => {
            state.formData = payload;
        },
        resetRegisterData: (state) => {
            state.formData = {};
        }

    },
});

// Action creators are generated for each case reducer function
export const {
    setRegisterData,
    resetRegisterData
} = registerSlice.actions;

export const registerReducer = registerSlice.reducer;
