import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQueries";

export const profileAPI = createApi({
    reducerPath: "profileAPI",
    baseQuery: baseQueryWithReauth,
    tagTypes: ["Profile", "Business"],
    endpoints: (build) => ({
        getProfile: build.query({
            query: () => ({
                url: `/api/profile/me/`,
                method: "POST",
                body: {
                    params: {
                        method: "GET",
                    },
                },
            }),
            providesTags: ["Profile"],
            transformResponse: (response) => response?.result?.response,
        }),
        updateProfile: build.mutation({
            query: (formData) => ({
                url: `/api/profile/me/`,
                method: "PUT",
                body: {
                    params: {
                        method: "PUT",
                        ...formData,
                    },
                },
            }),
            invalidatesTags: ["Profile", "Business"],
            transformResponse: (response) => ({
                data: response?.result?.response,
                status: response?.result?.status,
            }),
        }),

        updatePassword: build.mutation({
            query: (formData) => ({
                url: `/api/profile/me/password/`,
                method: "PUT",
                body: {
                    params: {
                        method: "PUT",
                        ...formData,
                    },
                },
            }),
            invalidatesTags: ["Profile", "Business"],
            transformResponse: (response) => ({
                data: response?.result?.response,
                status: response?.result?.status,
            }),
        }),
        updateLogin: build.mutation({
            query: (formData) => ({
                url: `/api/profile/me/login/`,
                method: "PUT",
                body: {
                    params: {
                        method: "PUT",
                        ...formData,
                    },
                },
            }),
            invalidatesTags: ["Profile", "Business"],
            transformResponse: (response) => ({
                data: response?.result?.response,
                status: response?.result?.status,
            }),
        }),
    }),
});

export const {
    useGetProfileQuery,
    useUpdateProfileMutation,
    useUpdatePasswordMutation,
    useUpdateLoginMutation,
} = profileAPI;
