import React from "react";

import "./skeletons.scss";

export default function ProductCardSkeleton() {
    return (
        <div className="product-card-skeleton">
            <div className="product-image-wrapper-skeleton border rounded">
                <div className="placeholder-glow product-image-skeleton">
                    <div className="placeholder w-100 h-100 rounded" />
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
                <div className="placeholder-glow w-100 mb-2">
                    <div className="placeholder col-7 pt-4 rounded" />
                    <div className="placeholder col-3 offset-2 pt-4 rounded" />
                </div>
            </div>
            <div className="placeholder-glow small mb-1">
                <div className="placeholder col-12 rounded" />
                <div className="placeholder col-8 rounded" />
            </div>
            <div className="placeholder-glow small mb-1">
                <div className="placeholder col-5 rounded" />
            </div>
            <div className="placeholder-glow small mb-1">
                <div className="placeholder col-4 rounded" />
            </div>
            <div className="placeholder-glow mb-2">
                <div className="placeholder col-7 rounded" />
            </div>
            <div className="placeholder-glow">
                <div className="placeholder col-6 pt-5 rounded" />
            </div>
        </div>
    );
}
