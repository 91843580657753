import React, { useRef } from "react";
import { useOutsideAlerter } from "../utils/domUtils";

export default function OutsideAlerter({ children, onClickOutside, ...rest }) {
    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef, onClickOutside);

    return (
        <div {...rest} ref={wrapperRef}>
            {children}
        </div>
    );
}
