import React from "react";
import PasswordField from "../PasswordField";
import "./viewableField.scss";

const ViewablePasswordField = React.forwardRef(
    ({ placeholder = "", label = "", name = "", isEditing }, ref) => {
        return (
            <div className="viewable-field">
                <div className="viewable-field-content">
                    {label && (
                        <label htmlFor={name} className="viewable-field-label">
                            {label}
                        </label>
                    )}

                    <PasswordField
                        placeholder={placeholder}
                        name={name}
                        disabled={!isEditing}
                        ref={ref}
                    />
                </div>
            </div>
        );
    }
);

export default ViewablePasswordField;
