import React from "react";

import "./skeletons.scss";

export default function CategoryCardSkeleton() {
    return (
        <div className="category-card-skeleton mt-3">
            <div className="d-flex align-items-center justify-content-between">
                <div className="placeholder-glow w-100">
                    <div className="placeholder pt-4 w-100" />
                </div>
            </div>
            <div className="category-image-wrapper-skeleton border">
                <div className="placeholder-glow category-image-skeleton">
                    <div className="placeholder w-100 h-100" />
                </div>
            </div>
        </div>
    );
}
