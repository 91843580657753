import { useContext } from "react";
import { CartContext } from "../providers/CartProvider";
import { ORDER_ID } from "../constants";

export const useCart = () => {
    const { cartItems, refetchCart } = useContext(CartContext);

    const getPublicOrderId = () => {
        const publicOrderId = localStorage.getItem(ORDER_ID);
        return publicOrderId;
    };

    const setPublicOrderId = (id) => {
        localStorage.setItem(ORDER_ID, id);
    };

    const removePublicOrderId = () => {
        localStorage.removeItem(ORDER_ID);
    };

    return {
        cartItems,
        refetchCart,
        getPublicOrderId,
        setPublicOrderId,
        removePublicOrderId
    };
};