import React, { useState } from "react";
import OutsideAlerter from "../OutsideAlerter";

import "./productFilter.scss";

export default function FilterMenu({
    children,
    title,
    wrapperStyle,
    dropDownStyle,
}) {
    const [isOpen, setIsOpen] = useState(false);

    const handleOnToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOutsideClick = () => {
        setIsOpen(false);
    };

    return (
        <OutsideAlerter onClickOutside={handleOutsideClick}>
            <div className="position-relative">
                <div
                    role="button"
                    className={`filter-menu-wrapper d-flex align-items-center  ${wrapperStyle} py-2 px-3`}
                    onClick={handleOnToggle}
                >
                    <div className="filter-menu-title pe-1">{title}</div>
                    <img src="/vectors/kasi-chevron-down-black.svg" alt="" />
                </div>
                {isOpen && (
                    <div className={`menu-drop-down ${dropDownStyle}`}>
                        {children}
                    </div>
                )}
            </div>
        </OutsideAlerter>
    );
}
