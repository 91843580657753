import React from "react";

import "./skeletons.scss";

export default function ProfileFormSkeleton() {
    return (
        <div>
            <div className="mb-5">
                <div className="mb-3">
                    <div className="placeholder-glow">
                        <div className="placeholder p-3 rounded col-4" />
                    </div>
                </div>
                <div className="mb-1">
                    <div className="placeholder-glow">
                        <div className="placeholder p-4 rounded col-12" />
                    </div>
                </div>
            </div>
            <div className="mb-5">
                <div className="mb-3">
                    <div className="placeholder-glow">
                        <div className="placeholder p-3 rounded col-4" />
                    </div>
                </div>
                <div className="mb-1">
                    <div className="placeholder-glow">
                        <div className="placeholder p-4 rounded col-12" />
                    </div>
                </div>
            </div>
            <div className="mb-5">
                <div className="mb-3">
                    <div className="placeholder-glow">
                        <div className="placeholder p-3 rounded col-4" />
                    </div>
                </div>
                <div className="mb-1">
                    <div className="placeholder-glow">
                        <div className="placeholder p-4 rounded col-12" />
                    </div>
                </div>
            </div>
        </div>
    );
}
