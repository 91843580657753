import React from "react";
import { useField } from "formik";
import { PatternFormat } from "react-number-format";
import parsePhoneNumber from "libphonenumber-js";
import ReactSelect from "react-select";

// Country field temporarily removed
// import { useGetCountriesQuery } from "../../../services/dataService";

import "../forms.scss";

/**
 * Native `text` input wrapper with Formik integration.
 * @param {{
 * name: string,
 * label: string,
 * placeholder?: string,
 * type?: string,
 * disabled?: boolean
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function PhoneNumberField({
    name,
    label = "Mobile number",
    placeholder,
    disabled,
    ...rest
}) {
    // Country code dropdown temporarily removed
    // const [countryField, countryMeta, countryHelpers] = useField(
    //     `${name}-country`
    // );

    const [phoneField, phoneMeta, phoneHelpers] = useField(`${name}-phone`);
    const [field, meta, helpers] = useField(name);

    // Country code dropdown temporarily removed
    // const { data: countriesWithPhoneCodes } = useGetCountriesQuery();

    // const handleCountryChange = (e) => {
    //     countryHelpers.setValue(e.value);
    // };

    const handlePhoneWithoutCountryChange = (e) => {
        phoneField.onChange(e);
    };

    // Country code dropdown temporarily removed
    // const countryListWithNames = countriesWithPhoneCodes
    //     ? countriesWithPhoneCodes.map(
    //           ({ code, phone_code: dialCode, name }) => ({
    //               value: `+${dialCode}`,
    //               label: `+${dialCode} | ${name}`,
    //               key: code,
    //           })
    //       )
    //     : [];

    const country = "+27";

    React.useEffect(() => {
        if (phoneField.value) {
            const phone = `${country}${phoneField.value || ""}`;
            helpers.setValue(phone.replace(/\s/g, ""));
        }
    }, [phoneField.value]);

    React.useEffect(() => {
        if (field.value) {
            const phone = field.value;
            const parsed = parsePhoneNumber(phone);

            // Country code dropdown temporarily removed
            // const country = parsed?.countryCallingCode
            //     ? `+${parsed.countryCallingCode}`
            //     : "";
            // countryHelpers.setValue(country);

            const phoneWithoutCode = parsed?.nationalNumber || "";
            phoneHelpers.setValue(phoneWithoutCode);
        }
    }, []);

    const customStyles = {
        dropdownIndicator: (styles) => ({
            ...styles,
            padding: 0,
            backgroundImage: "url('/vectors/kasi-chevron-down-blue.svg')",
            backgroundPositionY: "center",
            backgroundPositionX: "center",
        }),
        control: () => ({
            width: 70,
            outlineWidth: 1,
            outlineColor: "#FFD26A",
            outlineStyle: "solid",
            borderRadius: 2,
            display: "flex",
            color: "#000000",
            fontSize: 12,
        }),
        placeholder: (styles) => ({
            ...styles,
            color: "#000000",
        }),
        valueContainer: (styles) => ({
            ...styles,
            padding: 0,
        }),
        singleValue: (style, state) => ({
            ...style,
            color: state.isDisabled && "#3f3f3f",
        }),
        input: (styles) => ({
            ...styles,
            paddingLeft: 4,
            paddingTop: 0,
            paddingBottom: 0,
            margin: 0,
            color: "#000000",
        }),
        menu: (styles) => ({
            ...styles,
            width: 200,
        }),
        option: (styles) => ({
            ...styles,
            padding: 4,
            fontSize: 12,
        }),
    };

    const metaError = meta.error || phoneMeta.error;

    return (
        <div className="formfield-wrapper">
            <div
                className={`formfield-root formfield-phone ${
                    metaError ? "invalid" : ""
                }`}
            >
                {label && (
                    <label htmlFor={name} className="formfield-label">
                        {label}
                    </label>
                )}
                <ReactSelect
                    // options={countryListWithNames}  // Country code dropdown temporarily removed
                    placeholder="Country"
                    styles={customStyles}
                    isDisabled
                    value={{
                        label: country,
                    }}
                    classNamePrefix="react-select"
                    components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                    }}
                />
                <PatternFormat
                    name={`${name}-phone`}
                    format="### ### ####"
                    className="formfield-input"
                    placeholder={placeholder || ""}
                    value={phoneField.value}
                    onChange={handlePhoneWithoutCountryChange}
                    disabled={disabled}
                    {...rest}
                />
            </div>
            <div hidden={!metaError} className="formfield-error">
                {metaError}
            </div>
        </div>
    );
}
