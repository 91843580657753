import React, { useEffect, useState } from "react";
import _ from "lodash";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import { ROUTES } from "../../config/routesConfig";
import { useModal } from "../../hooks/modalsHooks";
import { useRouting } from "../../hooks/routesHooks";
import { useLoginStatus } from "../../hooks/authHooks";
import { useCart } from "../../hooks/cartHooks";

import {
    useSignInMutation,
    useSignUpMutation,
    useAddMobileMutation,
    useVerifyMobileMutation,
    useResetPasswordMutation,
} from "../../services/authService";
import { useGetProfileQuery } from "../../services/profileService";
import {
    useGetBusinessTypesQuery,
    useGetTownshipsQuery,
    useGetStatesQuery,
} from "../../services/dataService";
import { useUpdateOrderMutation } from "../../services/orderService";
import { setRegisterData, resetRegisterData } from "../../state/register";

import {
    getBusinessTypesOptions,
    getTownshipsOptions,
} from "../../utils/dataUtils";
import { IS_AUTHENTICATED } from "../../constants";

import {
    Checkbox,
    Form,
    PasswordField,
    PhoneNumberField,
    Select,
    SubmitButton,
    TextField,
    OTPField,
    LocationPickerField,
    EditableSelect,
} from "../../components/forms";
import Button from "../../components/Button";

import "./AuthPages.styles.scss";

function AuthLayout({ children, onClickBack, bannerImg }) {
    return (
        <div className="auth-layout container-fluid">
            <div className="content-area row align-items-center">
                <div className="left-column col-md-6">
                    <img src={bannerImg} alt="" />
                </div>
                <div className="right-column col-md-6 py-4 ps-md-5 pe-4 p-4">
                    <div className="d-flex flex-column w-100">
                        {onClickBack && (
                            <div className="d-flex align-items-center ps-md-5 pt-md-4">
                                <img
                                    src="/vectors/kasi-black-left-arrow.svg"
                                    alt=""
                                    role="button"
                                    onClick={onClickBack}
                                />
                            </div>
                        )}
                        <div className="page-content px-md-5 mt-3">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function SignInWelcomePage() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { getRouteLink } = useRouting();

    const handleOnNavigate = (path) => {
        navigate(getRouteLink(path), { state });
    };

    return (
        <AuthLayout bannerImg="/vectors/kasi-auth-banner.svg">
            <div className="signin-welcome-page h-100">
                <img src="/vectors/kasi-logo.svg" alt="" />
                <h2 className="my-5">
                    sign in for the best <br /> experience
                </h2>
                <div className="w-100">
                    <Button onClick={() => handleOnNavigate(ROUTES.SIGNIN)}>
                        Sign In
                    </Button>
                </div>
                <div className="w-100 mt-3">
                    <Button
                        variant="light"
                        onClick={() => handleOnNavigate(ROUTES.REGISTER)}
                    >
                        Create an account
                    </Button>
                </div>
            </div>
        </AuthLayout>
    );
}

const countryCodeRegExp = /^(\+\d{1,4})/;

const SignInFormSchema = Yup.object().shape({
    // login: Yup.string().when("login-country", {
    //     is: (country) => country && country.match(countryCodeRegExp),
    //     then: Yup.string().min(12, "Invalid phone number"),
    //     otherwise: Yup.string()
    //         .required("Required")
    //         .matches(countryCodeRegExp, "Invalid phone number"),
    // }),
    login: Yup.string().when("login-phone", {
        is: (phone) => phone,
        then: Yup.string().min(12, "Invalid phone number"),
        otherwise: Yup.string().required("Required"),
    }),
    "login-phone": Yup.string().required("Required"),
    password: Yup.string().required("Required"),
});

export function SignInPage() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { getRouteLink } = useRouting();
    const { refetchCart, getPublicOrderId, removePublicOrderId } = useCart();

    const [signInError, setSignInError] = useState("");

    const [signIn] = useSignInMutation();
    const [updateOrder] = useUpdateOrderMutation();

    const publicOrderId = getPublicOrderId();

    const handleSignInSubmit = async (values) => {
        try {
            const signInRes = await signIn({
                login: values.login,
                password: values.password,
            });
            if (signInRes?.data?.data && signInRes?.data?.status === 200) {
                localStorage.setItem(IS_AUTHENTICATED, true);

                if (publicOrderId) {
                    const orderUpdateRes = await updateOrder({
                        orderId: publicOrderId,
                        orderData: { public_order: true },
                    });

                    if (orderUpdateRes?.data?.status === 200) {
                        removePublicOrderId();
                    }
                }
                refetchCart();

                if (state?.referrer) {
                    // Has a referrer route navigate to that
                    navigate(getRouteLink(state.referrer)); //navigate after onSumbit successful
                } else {
                    navigate(getRouteLink(ROUTES.LANDING)); //navigate after onSumbit successful
                }
            } else {
                setSignInError(signInRes?.data?.data?.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleForgotPassword = () => {
        navigate(getRouteLink(ROUTES.FORGOT_PASSWORD));
    };

    const handleOnBackClick = () => {
        navigate(-1);
    };

    return (
        <AuthLayout
            bannerImg="/vectors/kasi-auth-banner.svg"
            onClickBack={handleOnBackClick}
        >
            <div className="signin-page d-flex flex-column justify-content-center h-100">
                <h1>Sign in to KASI</h1>
                <div className="d-flex text-nowrap my-3">
                    <div>Don’t have an account ?</div>
                    <Link
                        className="link-text"
                        to={getRouteLink(ROUTES.REGISTER)}
                        state={state}
                    >
                        &nbsp;Sign Up
                    </Link>
                </div>
                <div>
                    <Form
                        onSubmit={handleSignInSubmit}
                        initialValues={{ login: "", password: "" }}
                        validationSchema={SignInFormSchema}
                    >
                        <PhoneNumberField name="login" label="Mobile Number" />
                        <div className="mt-2">
                            <PasswordField
                                name="password"
                                label="Password"
                                placeholder="Enter your password"
                            />
                        </div>
                        <div className="d-flex justify-content-end mt-2">
                            <div
                                className="text-muted forget-password "
                                role="button"
                                onClick={handleForgotPassword}
                            >
                                Forgot password ?
                            </div>
                        </div>
                        {signInError && (
                            <div className="alert alert-primary text-center">
                                {signInError}
                            </div>
                        )}
                        <div className="mt-5">
                            <SubmitButton>Log In</SubmitButton>
                        </div>
                    </Form>
                </div>
            </div>
        </AuthLayout>
    );
}

const RegisterFormSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email format"),
    password: Yup.string().required("Required"),
    confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
});

export function RegisterPage() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { getRouteLink } = useRouting();

    const dispatch = useDispatch();
    const { formData } = useSelector((state) => state.registerFormData);

    const initialValues = {
        first_name: formData?.first_name || "",
        last_name: formData?.last_name || "",
        email: formData?.email || "",
        password: formData?.password || "",
        confirm_password: formData?.confirm_password || "",
    };

    const handleCreateAccountSubmit = (values) => {
        const userAccountData = { ...formData, ...values };
        dispatch(setRegisterData(userAccountData));
        navigate(getRouteLink(ROUTES.BUSINESS_DETAILS), { state }); //navigate after onSumbit successful
    };

    const handleOnBackClick = () => {
        navigate("/");
        dispatch(resetRegisterData());
    };

    return (
        <AuthLayout
            bannerImg="/vectors/kasi-auth-banner.svg"
            onClickBack={handleOnBackClick}
        >
            <div className="d-flex flex-column justify-content-center h-100">
                <h1>Create an account</h1>
                <div>
                    <Form
                        onSubmit={handleCreateAccountSubmit}
                        initialValues={initialValues}
                        validationSchema={RegisterFormSchema}
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="w-100 me-3">
                                <TextField
                                    name="first_name"
                                    label="First Name*"
                                    placeholder="Enter your first name"
                                />
                            </div>
                            <div className="w-100">
                                <TextField
                                    name="last_name"
                                    label="Last Name*"
                                    placeholder="Enter your last name"
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                            <TextField
                                name="email"
                                label="Your e-mail"
                                placeholder="Enter your e-mail"
                            />
                        </div>
                        <div className="mt-4">
                            <PasswordField
                                name="password"
                                label="Password*"
                                placeholder="Enter your password"
                            />
                        </div>
                        <div className="mt-4">
                            <PasswordField
                                name="confirm_password"
                                label="Confirm Password*"
                                placeholder="Re-enter your password"
                            />
                        </div>
                        <div className="mt-4">
                            <SubmitButton>Continue</SubmitButton>
                        </div>
                    </Form>
                </div>
            </div>
        </AuthLayout>
    );
}

const alphabetOnlyRegExp = /^[a-zA-Z '-]*$/;

const BusinessDetailsFormSchema = Yup.object().shape({
    company_name: Yup.string().required("Required"),
    business_type: Yup.string().required("Required"),
    company_registry: Yup.string(),
    location: Yup.string().required("Required"),
    street: Yup.string().required("Required"),
    city: Yup.string()
        .required("Required")
        .matches(alphabetOnlyRegExp, "Only alphabets are allowed"),
    province: Yup.string()
        .required("Required")
        .matches(alphabetOnlyRegExp, "Only alphabets are allowed"),
    township: Yup.string().required("Required"),
});

export function BusinessDetailsPage() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { getRouteLink } = useRouting();
    const { showModal } = useModal();

    const dispatch = useDispatch();
    const { formData } = useSelector((state) => state.registerFormData);

    const [geoResult, setGeoResult] = React.useState({
        shippingAddress: formData?.shippingAddress || "",
        location: {
            lat: formData?.location?.lat || "",
            long: formData?.location?.long || "",
        },
    });

    const initialValues = {
        company_name: formData?.company_name || "",
        business_type: formData?.business_type || "",
        company_registry: formData?.company_registry || "",
        vat_no: formData?.vat_no || "",
        location: formData?.shippingAddress || "",
        street: formData?.address?.street || "",
        city: formData?.address?.city || "",
        province: formData?.address?.province || "",
        zip: formData?.address?.zip || "",
        township: formData?.address?.township || "",
        landmark: formData?.address?.landmark || "",
        whatsapp_msg: false,
    };

    const { data: businessTypes } = useGetBusinessTypesQuery();
    const { data: townships } = useGetTownshipsQuery();
    const { data: states } = useGetStatesQuery({ countryId: 247 });

    useEffect(() => {
        if (_.isEmpty(formData)) {
            navigate(getRouteLink(ROUTES.REGISTER));
        }
    }, []);

    const handleBusinessDetailsSubmit = (values) => {
        const formattedValues = {
            company_name: values.company_name,
            business_type: values.business_type,
            company_registry: values.company_registry,
            vat: values.vat_no,
            address: {
                street: values.street,
                city: values.city,
                province: values.province,
                zip: values.zip,
                township: values.township,
                landmark: values.landmark,
            },
            shippingAddress: geoResult.shippingAddress,
            location: {
                lat: geoResult.location.lat,
                long: geoResult.location.long,
            },
        };
        const businesstData = { ...formData, ...formattedValues };
        dispatch(setRegisterData(businesstData));
        navigate(getRouteLink(ROUTES.ADD_PHONE_NUMBER), { state }); //navigate after onSumbit successful
    };

    const getMapCenter = () => {
        if (!_.isEmpty(formData?.location)) {
            return {
                lat: formData?.location?.lat,
                lng: formData?.location?.long,
            };
        }
    };

    const handleOnAddLocation = (geoLocation) => {
        setGeoResult({
            shippingAddress: geoLocation.shippingAddress,
            location: {
                lat: geoLocation.location.lat,
                long: geoLocation.location.lng,
            },
        });
    };

    const handleAddLocation = () => {
        showModal({
            modalType: "MapLocationPickerModal",
            modalProps: {
                initialPosition: getMapCenter(),
                onAddLocation: handleOnAddLocation,
            },
        });
    };

    const getStateOptions = () => {
        return states.map((item) => ({ label: item.name, value: item.name }));
    };

    const handleOnBackClick = () => {
        navigate(getRouteLink(ROUTES.REGISTER));
    };

    return (
        <AuthLayout
            bannerImg="/vectors/kasi-auth-banner.svg"
            onClickBack={handleOnBackClick}
        >
            <div className="d-flex flex-column h-100">
                <h1>Business Details</h1>
                <div>
                    <Form
                        onSubmit={handleBusinessDetailsSubmit}
                        initialValues={initialValues}
                        validationSchema={BusinessDetailsFormSchema}
                        enableReinitialize
                    >
                        <TextField
                            name="company_name"
                            label="Name of the Store*"
                            placeholder="Enter your store name"
                        />
                        <div className="mt-4">
                            <Select
                                name="business_type"
                                label="Business Type*"
                                options={
                                    businessTypes
                                        ? getBusinessTypesOptions(businessTypes)
                                        : []
                                }
                                fallbackOptionLabel="Select your business type"
                            />
                        </div>
                        <div className="mt-4">
                            <TextField
                                name="company_registry"
                                label="Business Regsitration No "
                                placeholder="Business regsitration no"
                            />
                        </div>
                        <div className="mt-4">
                            <TextField
                                name="vat_no"
                                label="VAT number"
                                placeholder="Enter your vat number"
                            />
                        </div>
                        <div className="mt-4">
                            <LocationPickerField
                                name="location"
                                label="Add Location*"
                                value={geoResult?.shippingAddress || ""}
                                placeholder="Select your shipping address"
                                actionTitle="Add Location"
                                onClick={handleAddLocation}
                            />
                        </div>
                        <div className="mt-4">
                            <TextField
                                name="street"
                                label="Street Address*"
                                placeholder="Enter your shipping address"
                            />
                        </div>
                        <div className="d-flex justify-content-center align-items-center mt-2">
                            <div className="w-100 me-3">
                                <TextField
                                    name="city"
                                    label="City*"
                                    placeholder="Enter your city"
                                />
                            </div>
                            <div className="w-100">
                                <EditableSelect
                                    name="province"
                                    label="Province*"
                                    fallbackOptionLabel="Select province"
                                    options={states ? getStateOptions() : []}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center mt-2">
                            <div className="w-100 me-3">
                                <TextField
                                    name="zip"
                                    label="Postal Code"
                                    placeholder="Enter your postal code"
                                />
                            </div>
                            <div className="w-100">
                                <Select
                                    name="township"
                                    label="Township*"
                                    options={
                                        townships
                                            ? getTownshipsOptions(townships)
                                            : []
                                    }
                                    fallbackOptionLabel="Select township"
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                            <TextField
                                name="landmark"
                                label="Landmark"
                                placeholder="Enter your landmark"
                            />
                        </div>
                        {/* temporarily removed */}
                        {/* <div className="mt-4">
                            <Checkbox
                                name="whatsapp_msg"
                                label="Please Confirm that Kasi Warehouse can send you Whatsapp Messages related to Products, Prices & Promotion."
                            />
                        </div> */}
                        <div className="mt-4">
                            <SubmitButton>Sign Up</SubmitButton>
                        </div>
                    </Form>
                </div>
            </div>
        </AuthLayout>
    );
}

const AddPhoneFormSchema = Yup.object().shape({
    // mobile_number: Yup.string().when("mobile_number-country", {
    //     is: (country) => country && country.match(countryCodeRegExp),
    //     then: Yup.string().min(12, "Invalid phone number"),
    //     otherwise: Yup.string()
    //         .required("Required")
    //         .matches(countryCodeRegExp, "Invalid phone number"),
    // }),
    mobile_number: Yup.string().when("mobile_number-phone", {
        is: (phone) => phone,
        then: Yup.string().min(12, "Invalid phone number"),
        otherwise: Yup.string().required("Required"),
    }),
    "mobile_number-phone": Yup.string().required("Required"),
});

export function AddPhonePage() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { getRouteLink } = useRouting();

    const dispatch = useDispatch();
    const { formData } = useSelector((state) => state.registerFormData);

    const [addPhoneError, setAddPhoneError] = useState("");

    const [addMobile] = useAddMobileMutation();

    useEffect(() => {
        if (_.isEmpty(formData)) {
            navigate(getRouteLink(ROUTES.REGISTER));
        }
    }, []);

    const handleAddPhoneSubmit = async (values) => {
        try {
            const response = await addMobile({ mobile: values.mobile_number });

            if (
                response?.data?.message === "success" &&
                response?.data?.status === 200
            ) {
                const phoneData = {
                    ...formData,
                    mobile_number: values.mobile_number,
                };
                dispatch(setRegisterData(phoneData));
                navigate(getRouteLink(ROUTES.OTP_VERIFY), { state });
            } else {
                setAddPhoneError(response?.data?.response?.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleOnBackClick = () => {
        navigate(getRouteLink(ROUTES.BUSINESS_DETAILS));
    };

    return (
        <AuthLayout
            bannerImg="/vectors/kasi-auth-banner.svg"
            onClickBack={handleOnBackClick}
        >
            <div className="d-flex flex-column justify-content-center h-100">
                <h1>Add Phone</h1>
                <div>
                    <Form
                        onSubmit={handleAddPhoneSubmit}
                        initialValues={{
                            mobile_number: formData?.mobile_number || "",
                        }}
                        validationSchema={AddPhoneFormSchema}
                    >
                        <PhoneNumberField
                            name="mobile_number"
                            label="Mobile Number"
                        />
                        {addPhoneError && (
                            <div className="alert alert-primary text-center mt-3">
                                {addPhoneError}
                            </div>
                        )}
                        <div className="mt-5">
                            <SubmitButton>Next</SubmitButton>
                        </div>
                    </Form>
                </div>
            </div>
        </AuthLayout>
    );
}

const otpValidationSchema = Yup.object().shape({
    otp: Yup.string().length(4, "OTP is invalid").required("Required"),
});

export function OTPVerifyPage() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { getRouteLink } = useRouting();
    const [params] = useSearchParams();
    const { showModal } = useModal();
    const isLoggedIn = useLoginStatus();
    const dispatch = useDispatch();
    const { refetchCart, getPublicOrderId, removePublicOrderId } = useCart();

    const { formData } = useSelector((state) => state.registerFormData);

    const publicOrderId = getPublicOrderId();

    const [signUpError, setSignUpError] = useState("");

    const [signUp] = useSignUpMutation();
    const [verifyMobile] = useVerifyMobileMutation();
    const [updateOrder] = useUpdateOrderMutation();

    const mobileNumber = formData?.mobile_number
        ? formData?.mobile_number
        : params.size > 0 &&
          `+${params?.get("mobile_number").split(" ").join("")}`;

    useEffect(() => {
        if (params.size === 0) {
            if (_.isEmpty(formData) && !isLoggedIn) {
                navigate(getRouteLink(ROUTES.REGISTER));
            }
        }

        if (params.size === 0 && isLoggedIn) {
            navigate(-1);
        }
    }, []);

    const handleOTPSubmit = async (values) => {
        const verifyMobileData = {
            mobile: mobileNumber,
            token: values.otp,
        };

        try {
            const response = await verifyMobile(verifyMobileData);
            if (
                response?.data?.data?.is_verified &&
                response?.data?.status === 200
            ) {
                if (state?.referrer === ROUTES.PROFILE_LOGIN_AND_SECURITY) {
                    showModal({
                        modalType: "UpdatePhoneNumberModal",
                        modalProps: {
                            mobile: mobileNumber,
                        },
                    });
                } else {
                    const registerData = _.assign(
                        _.omit(formData, [
                            "shippingAddress",
                            "country_code",
                            "phone_number",
                        ]),
                        {
                            login: mobileNumber,
                        }
                    );

                    const signUpRes = await signUp(registerData);
                    if (
                        signUpRes?.data?.data &&
                        signUpRes?.data?.status === 200
                    ) {
                        localStorage.setItem(IS_AUTHENTICATED, true);
                        dispatch(resetRegisterData());

                        if (publicOrderId) {
                            const orderUpdateRes = await updateOrder({
                                orderId: publicOrderId,
                                orderData: { public_order: true },
                            });

                            if (orderUpdateRes?.data?.status === 200) {
                                removePublicOrderId();
                            }
                        }
                        refetchCart();

                        if (state?.referrer) {
                            navigate(getRouteLink(state.referrer)); //navigate after onSumbit successful
                        } else {
                            navigate(getRouteLink(ROUTES.WELCOME)); //navigate after onSumbit successful
                        }
                    } else {
                        setSignUpError(signUpRes?.data?.data.error);
                    }
                }
            } else {
                setSignUpError(response?.data?.data?.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleOnBackClick = () => {
        navigate(-1);
    };

    return (
        <AuthLayout
            bannerImg="/vectors/kasi-auth-banner.svg"
            onClickBack={handleOnBackClick}
        >
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <div className="text-center">Code sent to {mobileNumber}</div>
                <div className="w-100">
                    <Form
                        onSubmit={handleOTPSubmit}
                        initialValues={{ otp: "" }}
                        validationSchema={otpValidationSchema}
                    >
                        <div className="my-4">
                            <OTPField name="otp" />
                        </div>
                        <div className="text-muted text-center">
                            Didn’t recived a code?
                        </div>
                        <div className="text-center" role="button">
                            {state?.referrer
                                ? "Facing OTP issues? Enter 0000 and proceed."
                                : "Facing OTP issues? Enter 0000 to sign up now."}
                        </div>
                        {/* temporarily removed  */}
                        {/* <div className="text-center text-secondary mt-4">
                            Get Via call
                        </div> */}
                        {signUpError && (
                            <div className="alert alert-primary text-center mt-3">
                                {signUpError}
                            </div>
                        )}
                        <div className="mt-5">
                            <SubmitButton>Verify</SubmitButton>
                        </div>
                    </Form>
                </div>
            </div>
        </AuthLayout>
    );
}

const ResetPasswordFormSchema = Yup.object().shape({
    // mobile_number: Yup.string().when("mobile_number-country", {
    //     is: (country) => country && country.match(countryCodeRegExp),
    //     then: Yup.string().min(12, "Invalid phone number"),
    //     otherwise: Yup.string()
    //         .required("Required")
    //         .matches(countryCodeRegExp, "Invalid phone number"),
    // }),
    mobile_number: Yup.string().when("mobile_number-phone", {
        is: (phone) => phone,
        then: Yup.string().min(12, "Invalid phone number"),
        otherwise: Yup.string().required("Required"),
    }),
    "mobile_number-phone": Yup.string().required("Required"),
});

export function ForgotPasswordPage() {
    const navigate = useNavigate();
    const { getRouteLink } = useRouting();

    const [resetPassword] = useResetPasswordMutation();

    const { showModal } = useModal();

    const handleForgotPasswordSubmit = async (values) => {
        const mobileNumber = values?.mobile_number;

        try {
            const response = await resetPassword({
                login: mobileNumber,
            });

            if (response?.data?.status === 200) {
                showModal({
                    modalType: "AlertModal",
                    modalProps: {
                        title: "Reset link sent",
                        message: response?.data?.data?.message,
                        actionTitle: "OK",
                        onClick: handleNavigateToSignIn,
                    },
                });
            } else {
                showModal({
                    modalType: "AlertModal",
                    modalProps: {
                        title: "Failed to send the reset link",
                        message: response?.data?.data?.error,
                        actionTitle: "OK",
                    },
                });
            }
        } catch (error) {}
    };

    const handleNavigateToSignIn = () => {
        navigate(getRouteLink(ROUTES.SIGNIN));
    };

    const handleOnBackClick = () => {
        navigate(-1);
    };

    return (
        <AuthLayout
            bannerImg="/vectors/kasi-auth-banner.svg"
            onClickBack={handleOnBackClick}
        >
            <div className="signin-page d-flex flex-column justify-content-center h-100">
                <h1>Forgot Password</h1>
                <div className="d-flex my-3">
                    <div>
                        Enter the contact number associated with your Kasi
                        account .
                    </div>
                </div>
                <div>
                    <Form
                        onSubmit={handleForgotPasswordSubmit}
                        initialValues={{ mobile_number: "" }}
                        validationSchema={ResetPasswordFormSchema}
                    >
                        <PhoneNumberField
                            name="mobile_number"
                            label="Mobile Number"
                        />
                        <div className="mt-5">
                            <SubmitButton>Send Reset Link</SubmitButton>
                        </div>
                    </Form>
                    <div className="forgot-password-note mt-3">
                        If you no longer use the contact number associated with
                        your Kasi account, you may contact&nbsp;
                        <span className="link-text">Customer Service</span>
                    </div>
                </div>
            </div>
        </AuthLayout>
    );
}

export function WelcomePage() {
    const navigate = useNavigate();
    const { getRouteLink } = useRouting();

    const { data: profileData } = useGetProfileQuery();

    const handleOnNavigate = () => {
        navigate(getRouteLink(ROUTES.LANDING));
    };

    return (
        <AuthLayout bannerImg="/vectors/kasi-welcome-banner.svg">
            <div className="welcome-page h-100">
                <h1 className="mb-5">
                    Welcome to Kasi <br /> Warehouse Family!!
                </h1>
                <div className="mb-4">
                    Dear customer, your customer account has <br /> been
                    successfully created.
                </div>
                <div className="mb-4">
                    Your Account No is
                    <span className="subtitle">&nbsp;{profileData?.code}</span>
                </div>
                <div className="subtitle mb-5">
                    You can start placing orders now.
                </div>
                <div className="w-75">
                    <Button onClick={() => handleOnNavigate()}>
                        Start Shopping
                    </Button>
                </div>
            </div>
        </AuthLayout>
    );
}
