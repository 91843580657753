import React from "react";
import { useField } from "formik";
import OtpInput from "react-otp-input";

/**
 * OTP input. Integrated with Formik.
 * @param {{
 * name: string,
 * placeholder?: string,
 * disabled?: boolean
 * }} props
 * @returns {React.FunctionComponent}
 */

export default function OTPField({ name, ...rest }) {
    const [field, meta, helpers] = useField(name);

    const inputField = (props) => {
        return <input {...props} {...rest} />;
    };

    const handleOnChange = (changedOtp) => {
        helpers.setValue(changedOtp);
    };
    return (
        <>
            <OtpInput
                value={field.value}
                onChange={handleOnChange}
                numInputs={4}
                renderSeparator={<div className="mx-2" />}
                renderInput={inputField}
                containerStyle="justify-content-center my-3"
                inputStyle={`otp-input ${field.value ? "selected-bg" : ""} ${meta.touched && meta.error ? 'invalid' : ''}`}
            />
            <div
                hidden={!(meta.touched && meta.error)}
                className="otp-field-error"
            >
                {meta.error}
            </div>
        </>
    );
}
