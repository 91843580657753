import React from "react";
import dayjs from "dayjs";
import { parseCurrencyValue } from "../../utils/currencyUtils";
import PreviousOrderProductCard from "./PreviousOrderProductCard";

import "./cards.scss";

export default function PreviousOrderCard({ order, onClickBuyAgain }) {
    const allOrderProducts = order?.order_lines?.length
        ? order?.order_lines
        : [];
    const selectedProductsForRender = allOrderProducts.slice(0, 3);
    const unselectedProductsCount =
        allOrderProducts.length && selectedProductsForRender.length
            ? allOrderProducts.length - selectedProductsForRender.length
            : 0;

    const formattedOrderDate =
        order?.create_date && dayjs(order?.create_date).isValid()
            ? dayjs(order?.create_date).format("MMM DD,YYYY | HH:mm")
            : null;

    const getTotalForPreviousOrder = () => {
        return parseCurrencyValue(
            order?.amount_total,
            false,
            order?.currency_id[1]
        );
    };

    return (
        <div className="previous-order-card">
            <div className="order-header">
                <div className="d-block">
                    <div className="order-label">
                        Order&nbsp;-&nbsp;
                        <span className="order-number">{order?.name}</span>
                    </div>
                    <div className="order-date">{formattedOrderDate}</div>
                </div>
                <div className="d-block">
                    <button
                        className="btn buy-again-btn"
                        onClick={onClickBuyAgain}
                    >
                        Buy again
                    </button>
                </div>
            </div>
            <div className="products-preview-bar justify-content-start">
                <div className="d-flex gap-3">
                    {selectedProductsForRender?.map((product) => (
                        <PreviousOrderProductCard
                            key={product?.id}
                            product={product}
                        />
                    ))}
                </div>
                {!!unselectedProductsCount && (
                    <div className="truncated-product-count ms-2">
                        +{unselectedProductsCount}
                    </div>
                )}
            </div>
            <div className="order-footer">
                <div className="d-block">
                    <div className="products-count-label">Products</div>
                    <div className="products-count">
                        {order.cart_quantity} Products
                    </div>
                </div>
                <div className="d-block">
                    <div className="products-price-label text-md-end">
                        Order Price
                    </div>
                    <div className="products-price text-md-end">
                        {getTotalForPreviousOrder()}
                    </div>
                </div>
            </div>
        </div>
    );
}
