import React from "react";

import "./sectionTitle.scss";

export default function SectionTitle({
    text = "",
    textClassName = "h4 fw-normal text-capitalize",
}) {
    return (
        <div className="section-title">
            <div className="pb-3 mb-3 title-container">
                <div className={textClassName}>{text}</div>
            </div>
        </div>
    );
}
