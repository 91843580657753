import React from "react";
import { parseCurrencyValue } from "../../utils/currencyUtils";
import { ChangeableNumber } from "../forms/ChangeableNumberField";
import { parseBase64ImgSrc } from "../../utils/dataUtils";

const fallbackImagePath = "/images/product_placeholder.png";

export default function CartItemCard({
    quantity,
    cartItem,
    onDelete,
    onChangeItemQuantity,
    onClick,
    isPackagingLine,
}) {
    const handleCartItemDelete = (e) => {
        e.stopPropagation();
        if (onDelete) {
            onDelete();
        }
    };

    const handleOnClick = () => {
        if (onClick) {
            onClick();
        }
    };

    const unitPrice = cartItem?.price_unit;
    const subTotal = cartItem?.price_reduce;
    const isProductInStock = !!cartItem?.free_qty_today;
    const packagingName = cartItem?.product_packaging_id[1];

    return (
        <div className="cart-item-card container-fluid px-0">
            <div className="row">
                <div className="col-11 col-md-3 py-2">
                    <div
                        role="button"
                        className="d-flex align-items-center"
                        onClick={handleOnClick}
                    >
                        <div className="cart-item-image-wrapper">
                            <img
                                src={
                                    parseBase64ImgSrc(
                                        cartItem?.product_image_128
                                    ) || fallbackImagePath
                                }
                                alt=" "
                                className="cart-item-image"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="d-flex flex-column justify-content-between h-100">
                        <div className="d-block">
                            <div className="h5 mb-2">
                                {cartItem?.name_short}
                                {packagingName ? ` - ${packagingName}` : ""}
                            </div>
                            {!cartItem.is_reward_line && (
                                <small
                                    className={`stock-status ${
                                        isProductInStock ? "in-stock" : ""
                                    }`}
                                >
                                    {isProductInStock
                                        ? "In Stock"
                                        : "Out of Stock"}
                                </small>
                            )}
                        </div>
                        <div className="d-block">
                            {!cartItem.is_reward_line && (
                                <div className="qty-select-container mb-2">
                                    {isPackagingLine ? "Pack " : ""}Qty:
                                    <ChangeableNumber
                                        value={quantity || ""}
                                        onChange={onChangeItemQuantity}
                                        min={1}
                                        disabled={!isProductInStock}
                                    />
                                    {isPackagingLine && (
                                        <div className="small text-muted ms-2">
                                            ({cartItem?.product_uom_qty} Units)
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="d-flex align-items-center">
                                <button
                                    className="btn btn-sm cart-action-btn"
                                    onClick={handleCartItemDelete}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="h5">
                        {parseCurrencyValue(
                            subTotal,
                            false,
                            cartItem?.currency_id[1]
                        )}
                    </div>
                    {cartItem?.discount > 0 && (
                        <div className="unit-price">
                            {parseCurrencyValue(
                                unitPrice,
                                false,
                                cartItem?.currency_id[1]
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
