import "./skeletons.scss";

export default function OrderDetailSkeleton() {
    return (
        <>
            <div className="placeholder-glow row mb-3">
                <div className="placeholder rounded p-3 col-md-6" />
            </div>
            <div className="row">
                <div className="d-flex justify-content-between col-md-10 p-0">
                    <div className="placeholder-glow w-25">
                        <div className="placeholder rounded py-3 mb-2 col-12" />
                        <div className="placeholder rounded col-12" />
                        <div className="placeholder rounded col-12" />
                    </div>
                    <div className="placeholder-glow w-25">
                        <div className="placeholder rounded py-3 mb-2 col-12" />
                        <div className="placeholder rounded col-12" />
                    </div>
                </div>
            </div>
        </>
    );
}
