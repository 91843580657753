import React from "react";
import _ from "lodash";
import {
    GoogleMap,
    useJsApiLoader,
    StandaloneSearchBox,
} from "@react-google-maps/api";
import Geocode from "react-geocode";

import { useModal } from "../../../hooks/modalsHooks";
import Button from "../../Button";

import "./mapLocationPickerModal.scss";

Geocode.setApiKey(process.env.REACT_APP_GEOCODE_API_KEY);

// defaults to cape town
const initialMapCenter = {
    lat: -33.918861,
    lng: 18.4233,
};

const library = ["places"];

export default function MapLocationPickerModal({
    initialPosition,
    onAddLocation,
}) {
    const { showModal, hideModal } = useModal();
    const mapRef = React.createRef();
    const [searchBox, setSearchBox] = React.useState(null);

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
        libraries: library,
    });

    const [map, setMap] = React.useState(null);

    const [location, setLocation] = React.useState({
        streetAddress: "",
        city: "",
    });
    const [isSelecting, setIsSelecting] = React.useState(false);

    const [geoResult, setGeoResult] = React.useState({
        shippingAddress: "",
        location: {
            lat: "",
            lng: "",
        },
    });

    const [currentLocation, setCurrentLocation] = React.useState({});
    const [locationPermission, setLocationPermission] = React.useState(true);

    React.useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setCurrentLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            });
            setLocationPermission(true);
        }, setLocationPermission(false));
    }, []);

    const getInfoBoxContentFromGeocode = (geocodeResult) => {
        const content = {
            streetAddress: "",
            city: "",
        };
        if (geocodeResult?.address_components?.length) {
            const { address_components: addressComponents } = geocodeResult;
            const validCity = addressComponents.find((component) => {
                if (component?.types?.length) {
                    return component.types.includes(
                        "administrative_area_level_2"
                    );
                }
                return false;
            });
            if (validCity) {
                content.city = validCity?.short_name || "";
            }
        }
        if (geocodeResult?.formatted_address) {
            const { formatted_address: addressStr } = geocodeResult;
            const addressParts = addressStr.split(", ");
            const validAddress = addressParts.slice(0, 2);
            content.streetAddress = validAddress.join(", ");
        }
        return content;
    };

    const resolveAddressForLatLng = async (lat, lng) => {
        try {
            const response = await Geocode.fromLatLng(lat, lng);
            const selectedLocation = response?.results[0];
            setGeoResult({
                shippingAddress: selectedLocation?.formatted_address,
                location: selectedLocation?.geometry?.location,
            });
            const { streetAddress, city } =
                getInfoBoxContentFromGeocode(selectedLocation);
            setLocation({ city, streetAddress });
            setIsSelecting(false);
        } catch (err) {
            // error
            console.error(err);
        }
    };

    const debouncedGeocodeFetch = React.useCallback(
        _.debounce(resolveAddressForLatLng, 3000),
        []
    );

    const handleBoundsChanged = () => {
        if (mapRef.current) {
            const { lat: getLat, lng: getLng } =
                mapRef.current.state.map.center;
            const mapCenter = {
                lat: getLat(),
                lng: getLng(),
            };
            setIsSelecting(true);
            debouncedGeocodeFetch(mapCenter.lat, mapCenter.lng);
        }
    };

    const handleOnChangePlaces = () => {
        const location = {
            lat: searchBox.getPlaces()[0].geometry.location.lat(),
            lng: searchBox.getPlaces()[0].geometry.location.lng(),
        };

        if (mapRef.current) {
            map.panTo(location);
        }
    };

    const handleRelocate = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const location = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };

                if (mapRef.current) {
                    map.panTo(location);
                }
                setLocationPermission(true);
            }, handlePermissionDenied);
        }
    };

    const handlePermissionDenied = () => {
        setLocationPermission(false);
        showModal({
            modalType: "AlertModal",
            modalProps: {
                title: "Current location disabled",
                message: (
                    <>
                        You have disabled location access for this app. <br />{" "}
                        Please go to settings and allow location permissions.
                    </>
                ),
                actionTitle: "OK",
            },
        });
    };
    const handleModalClose = (e) => {
        e.stopPropagation();
        hideModal();
    };

    const handleAddLocation = (e) => {
        e.stopPropagation();
        if (onAddLocation) {
            onAddLocation(geoResult);
        }
        hideModal();
    };

    const mapCenter = _.isEmpty(initialPosition)
        ? _.isEmpty(currentLocation)
            ? initialMapCenter
            : currentLocation
        : initialPosition;

    return (
        <div className="map-location-picker-container">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        {isLoaded ? (
                            <GoogleMap
                                ref={mapRef}
                                mapContainerClassName="map-container"
                                center={mapCenter}
                                zoom={10}
                                onBoundsChanged={handleBoundsChanged}
                                options={{
                                    fullscreenControl: false,
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                }}
                                onLoad={(map) => setMap(map)}
                            >
                                <StandaloneSearchBox
                                    onPlacesChanged={handleOnChangePlaces}
                                    onLoad={(ref) => setSearchBox(ref)}
                                >
                                    <div className="search-container mx-3 mb-3 mb-lg-0">
                                        <img
                                            src="/vectors/kasi-search-icon.svg"
                                            alt=" "
                                            className="search-start-icon"
                                        />
                                        <input
                                            className="search-input"
                                            type="text"
                                            placeholder="Search"
                                            aria-label="Search"
                                        />
                                    </div>
                                </StandaloneSearchBox>
                                {(location?.city ||
                                    location?.streetAddress) && (
                                    <div className="info-box-container">
                                        <div className="info-box-content">
                                            <img
                                                src="/vectors/kasi-blue-home-icon.svg"
                                                alt=""
                                                className="home-img"
                                            />
                                            <div className="d-block">
                                                {!isSelecting ? (
                                                    <div className="street-address">
                                                        {
                                                            location?.streetAddress
                                                        }
                                                    </div>
                                                ) : (
                                                    <div className="placeholder-glow">
                                                        <div className="street-address placeholder rounded" />
                                                    </div>
                                                )}
                                                {!isSelecting ? (
                                                    <div className="city">
                                                        {location?.city}
                                                    </div>
                                                ) : (
                                                    <div className="placeholder-glow">
                                                        <div className=" city placeholder w-50 rounded" />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <img
                                    src="/vectors/kasi-map-marker-icon.svg"
                                    className="marker-img"
                                    alt=""
                                />
                                <Button
                                    isLoading={isSelecting}
                                    disabled={isSelecting}
                                    className={
                                        "btn btn-lg btn-primary add-location-btn"
                                    }
                                    onClick={handleAddLocation}
                                >
                                    Add
                                </Button>
                                <button
                                    className="close-btn"
                                    onClick={handleModalClose}
                                >
                                    <img
                                        src="/vectors/kasi-close-circle-red-icon.svg"
                                        className="close-btn-img"
                                        alt=""
                                    />
                                </button>
                                <button
                                    className="relocate-btn"
                                    onClick={handleRelocate}
                                >
                                    {locationPermission ? (
                                        <img
                                            src="/vectors/kasi-current-location-icon.svg"
                                            className="relocate-btn-img"
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            src="/vectors/kasi-no-current-location-icon.svg"
                                            className="relocate-btn-img"
                                            alt=""
                                        />
                                    )}
                                </button>
                            </GoogleMap>
                        ) : (
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="d-block text-center">
                                    <div
                                        className="spinner-border text-primary"
                                        role="status"
                                    />
                                    <div className="text-white mt-3">
                                        Map is loading...
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
