import { createApi } from "@reduxjs/toolkit/query/react";
import { publicBaseQuery } from "./baseQueries";
export const dataAPI = createApi({
    reducerPath: "dataAPI",
    baseQuery: publicBaseQuery,
    endpoints: (build) => ({
        getBusinessTypes: build.query({
            query: () => ({
                url: `/api/business/types/`,
                method: "POST",
                body: {
                    params: {
                        method: 'GET',
                    }
                },
            }),
            transformResponse: (response) => response?.result?.response,
        }),
        getTownships: build.query({
            query: () => ({
                url: `/api/address/townships/`,
                method: "POST",
                body: {
                    params: {
                        method: 'GET',
                    }
                },
            }),
            transformResponse: (response) => response?.result?.response,
        }),
        getCountries: build.query({
            query: () => ({
                url: `/api/countries/`,
                method: 'POST',
                body: {
                    params: {
                        method: 'GET',
                    },
                },
            }),
            transformResponse: response => response?.result,
        }),
        getStates: build.query({
            query: ({ countryId: id }) => ({
                url: `/api/countries/${id}/states/`,
                method: 'POST',
                body: {
                    params: {
                        method: 'GET',
                    },
                },
            }),
            transformResponse: response => response?.result,
        }),
        getPaymentMethods: build.query({
            query: () => ({
                url: 'api/payment-methods/',
                method: 'POST',
                body: {
                    params: {
                        method: 'GET',
                    },
                },
            }),
            transformResponse: response => response?.result,
        }),
        getPriceLists: build.query({
            query: () => ({
                url: '/api/pricelists/',
                method: 'POST',
                body: {
                    params: {
                        method: 'GET',
                    },
                },
            }),
            transformResponse: response => response?.result,
        }),
        getDeals: build.query({
            query: () => ({
                url: '/api/deals/',
                method: 'POST',
                body: {
                    params: {
                        method: 'GET',
                    },
                },
            }),
            transformResponse: response => response?.result?.response,
        }),
    }),
});

export const {
    useGetBusinessTypesQuery,
    useGetTownshipsQuery,
    useGetCountriesQuery,
    useGetStatesQuery,
    useGetPaymentMethodsQuery,
    useGetPriceListsQuery,
    useGetDealsQuery
} = dataAPI;
