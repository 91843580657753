import React from "react";
import { useField } from "formik";

import "../forms.scss";

/**
 * Native `password` input wrapper with Formik integration.
 * @param {{
 * name: string,
 * label: string,
 * placeholder?: string,
 * disabled?: boolean
 * }} props
 * @returns {React.FunctionComponent}
 */
const PasswordField = React.forwardRef(
    ({ name, label, placeholder, disabled, ...rest }, ref) => {
        const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
        const [field, meta] = useField({ name, label, placeholder, ...rest });

        const handleTogglePasswordVisibility = () => {
            setIsPasswordVisible((prevState) => !prevState);
        };

        return (
            <div className="formfield-wrapper">
                <div
                    className={`formfield-root ${
                        meta.touched && meta.error ? "invalid" : ""
                    }`}
                >
                    {label && (
                        <label htmlFor={name} className="formfield-label">
                            {label}
                        </label>
                    )}
                    <input
                        className="formfield-input"
                        placeholder={
                            meta.touched && meta.error ? "" : placeholder || ""
                        }
                        type={isPasswordVisible ? "text" : "password"}
                        disabled={disabled}
                        {...field}
                        {...rest}
                    />
                    <div
                        role="button"
                        className={`password-toggle-btn ${
                            disabled && "disabled"
                        }`}
                        onClick={handleTogglePasswordVisibility}
                    >
                        {!isPasswordVisible && (
                            <img src="/vectors/kasi-lock-icon.svg" alt="Hide" />
                        )}
                        {isPasswordVisible && (
                            <img
                                src="/vectors/kasi-unlock-icon.svg"
                                alt="Show"
                            />
                        )}
                    </div>
                </div>
                <div
                    hidden={!(meta.touched && meta.error)}
                    className="formfield-error"
                >
                    {meta.error}
                </div>
            </div>
        );
    }
);

export default PasswordField;
