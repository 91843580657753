import React from "react";
import { Link } from "react-router-dom";
import { useRouting } from "../../hooks/routesHooks";
import { ROUTES } from "../../config/routesConfig";

import "./footer.scss";

export default function Footer() {
    const { getRouteLink } = useRouting();
    const currentYear = new Date().getFullYear();

    return (
        <div className="footer-container p-5">
            <div className="footer-section-1">
                <div className="d-flex align-items-start align-items-sm-end justify-content-between flex-column flex-sm-row">
                    <div className="logo-wrapper pt-2 mb-3 mb-sm-0">
                        <img src="/images/kasi-logo.png" alt="" />
                    </div>
                    {/* Get App temporarily removed */}
                    {/* <div className="store-logo-wrapper">
                        <div className="title text-sm-center mb-3">
                            Get The App!
                        </div>
                        <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
                            <img
                                role="button"
                                src="/images/play-store.png"
                                alt=""
                                className="me-0 me-sm-3 mb-3 mb-sm-0"
                            />
                            <img
                                role="button"
                                src="/images/app-store.png"
                                alt=""
                            />
                        </div>
                    </div> */}
                </div>
            </div>
            <hr />
            <div className="footer-section-2">
                <div className="d-flex justify-content-between align-items-start align-items-md-end flex-column-reverse flex-md-row">
                    <div className="footer-link-containter">
                        <div className="sub-title my-4">
                            ©Kasi Warehouse. {currentYear} All Rights Reserved.
                        </div>
                        <div className="footer-links-wrapper">
                            <div className="d-flex align-items-start align-items-sm-center justify-content-between flex-column-reverse flex-sm-row">
                                <Link
                                    className="footer-link me-2 mb-2 mb-sm-0"
                                    to={getRouteLink(
                                        ROUTES.TERMS_AND_CONDITIONS
                                    )}
                                    target="_blank"
                                >
                                    Terms & Conditions
                                </Link>
                                <Link
                                    className="footer-link me-2 mb-2 mb-sm-0"
                                    to={getRouteLink(ROUTES.PRIVACY_POLICY)}
                                    target="_blank"
                                >
                                    Privacy Policy
                                </Link>
                                <Link
                                    className="footer-link me-2 mb-2 mb-sm-0"
                                    to={getRouteLink(ROUTES.RETURN_POLICY)}
                                    target="_blank"
                                >
                                    Return Policy
                                </Link>
                                <Link
                                    className="footer-link me-2 mb-2 mb-sm-0"
                                    to={getRouteLink(ROUTES.SHIPPING_POLICY)}
                                    target="_blank"
                                >
                                    Shipping Policy
                                </Link>
                                <Link
                                    className="footer-link me-2 mb-2 mb-sm-0"
                                    to={getRouteLink(
                                        ROUTES.CONSUMER_PROTECTION_ACT
                                    )}
                                    target="_blank"
                                >
                                    Consumer Protection Act
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* Social media handlers temporarily removed */}
                    {/* <div className="social-links-wrapper mt-4">
                        <div className="d-flex align-items-center justify-content-between">
                            <img
                                role="button"
                                src="/vectors/facebook-logo.svg"
                                alt=""
                                className="ms-md-3 me-3 me-md-0"
                            />
                            <img
                                role="button"
                                src="/vectors/instagram-logo.svg"
                                alt=""
                                className="ms-md-3 me-3 me-md-0"
                            />
                            <img
                                role="button"
                                src="/vectors/linkedin-logo.svg"
                                alt=""
                                className="ms-md-3 me-3 me-md-0"
                            />
                            <img
                                role="button"
                                src="/vectors/twitter-logo.svg"
                                alt=""
                                className="ms-3"
                            />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
