import "./skeletons.scss";

export default function TableSkeleton({ columnCount = 4 }) {
    return (
        <div className="table-skeleton">
            <table className="table table-borderless">
                <tbody>
                    {[...Array(4)].map((_, index) => (
                        <tr key={index}>
                            {[...Array(columnCount)].map((_, index) => (
                                <td className="placeholder-glow" key={index}>
                                    <div className="placeholder p-3 rounded w-100" />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
