import React from "react";

import "./chip.scss";

/**
 * Chip component to show status
 * @param {{
 *  text: string
 *  variant: "success" | "danger" | "warning"
 * }} props 
 * @returns 
 */
export default function Chip({
    text = "",
    variant = "",
}) {
    return <div className={`chip p-1 ${variant || ''}`}>{text}</div>;
}
