import React, { useState } from "react";

import "./discountDropDown.scss";

export default function DiscountDropDown({ options, value, onChange }) {
    const [showOption, setShowOption] = useState(false);

    const handleToggleDropDown = () => {
        setShowOption((prevState) => !prevState);
    };

    const handleOnChange = (value) => {
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div className="discount-drop-down-wrapper mb-2">
            <div className="discount-title-wrapper py-2 px-3">
                <div
                    role="button"
                    className="discount-drop-down"
                    onClick={handleToggleDropDown}
                >
                    <div className="discount-title">Select Discount</div>
                    <div className="drop-down-btn ms-3">
                        <img
                            className={`${showOption && "active"}`}
                            src="/vectors/kasi-chevron-down-white.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
            {showOption && (
                <div className="drop-down-option-group py-2 ps-3 pe-5">
                    {options?.map((option, index) => (
                        <div
                            key={index}
                            className="d-flex align-items-baseline mt-3"
                        >
                            <input
                                type="radio"
                                name={option.label}
                                id={option.label}
                                value={option.value}
                                checked={value === option.value}
                                className="radio-btn"
                                onChange={() => handleOnChange(option.value)}
                            />
                            <label
                                htmlFor={option.label}
                                className="radio-label ms-2"
                            >
                                {option.label}
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
