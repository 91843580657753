import React, { useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ceil } from "lodash";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../config/routesConfig";
import { useLoginStatus } from "../../hooks/authHooks";
import { useRouting } from "../../hooks/routesHooks";
import { useModal } from "../../hooks/modalsHooks";
import { useCart } from "../../hooks/cartHooks";

import { useGetPaginatedProductTemplatesQuery } from "../../services/productService";
import {
    useGetOrdersQuery,
    useGetOrderQuery,
    useReorderMutation,
} from "../../services/orderService";
import { useGetDealsQuery } from "../../services/dataService";
import {
    productFilterPriceOptions,
    productSortOptions,
} from "../../utils/productUtils";

import PreviousOrderCard from "../../components/cards/PreviousOrderCard";
import ProductCard from "../../components/cards/ProductCard";
import Pagination from "../../components/Pagination";
import PromotionCarousel from "../../components/carousels/PromotionCarousel";
import ProductCardSkeleton from "../../components/skeletons/ProductCardSkeleton";

import "./productListPage.scss";

export default function ProductListPage() {
    const navigate = useNavigate();
    const { getRouteLink } = useRouting();
    const { showModal } = useModal();
    const { refetchCart } = useCart();

    const prevOrderRef = useRef(null);
    const [scrolledWidth, setScrolledWidth] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [params] = useSearchParams();

    const { data: allDeals } = useGetDealsQuery();

    const [reorder] = useReorderMutation();

    const isLoggedIn = useLoginStatus();
    const pageSize = 20;

    const filteredPriceRangeOptions = params.get("price");

    const filteredPriceRangeMin = params.get("min");
    const filteredPriceRangeMax = params.get("max");

    // sort search parameter
    const sortSearchParam = params.get("sort");

    const filteredCategories =
        params.get("category_id") &&
        params
            .get("category_id")
            .split(",")
            .map((id) => Number(id));

    const filteredBrands =
        params.get("brand_id") &&
        params
            .get("brand_id")
            .split(",")
            .map((id) => Number(id));

    const searchedText = params.get("search") && params.get("search");

    const { data: orderList } = useGetOrdersQuery({}, { skip: !isLoggedIn });

    const latestOrderId =
        orderList?.response?.length > 0
            ? orderList?.response?.reduce((prev, current) =>
                  prev.id > current.id ? prev : current
              ).id
            : null;

    const { data: latestOrderData } = useGetOrderQuery(
        { orderId: latestOrderId },
        { skip: !latestOrderId }
    );

    const parsePriceFilters = () => {
        let priceFilters = {};
        const priceOption = productFilterPriceOptions.find(
            ({ label }) => label === filteredPriceRangeOptions
        );
        if (priceOption) {
            priceFilters.list_price_gte = priceOption.min;
            if (priceOption.max) {
                priceFilters.list_price_lte = priceOption.max;
            }
        }
        if (filteredPriceRangeMin || filteredPriceRangeMax) {
            priceFilters = {};
            priceFilters.list_price_gte = Number(filteredPriceRangeMin) || 0;
            if (filteredPriceRangeMax) {
                priceFilters.list_price_lte = Number(filteredPriceRangeMax);
            }
        }
        return priceFilters;
    };

    const parseSortParameters = () => {
        let sortParams = {};
        const sortOption = productSortOptions.find(
            ({ name }) => name === sortSearchParam
        );
        if (sortOption?.sortConfig) {
            sortParams = sortOption.sortConfig;
        }
        return sortParams;
    };

    const getProductTemplatesPayload = () => {
        const priceFilters = parsePriceFilters();
        const sortParameters = parseSortParameters();

        let payload = {
            page_size: pageSize,
            page: selectedPage,
            ...priceFilters,
            ...sortParameters,
        };
        if (searchedText) {
            payload = { ...payload, search: searchedText };
        }

        if (filteredCategories) {
            payload = { ...payload, category_ids: filteredCategories };
        }

        if (filteredBrands) {
            payload = { ...payload, brand_ids: filteredBrands };
        }

        return payload;
    };

    const { data: productTemplates, isFetching: isProductTemplatesLoading } =
        useGetPaginatedProductTemplatesQuery(getProductTemplatesPayload());

    const handleOnClickProduct = (productId) => {
        navigate(getRouteLink(ROUTES.PRODUCT_DETAIL, { productId }));
    };

    const handleHorizantalScroll = (scrollDistance) => {
        prevOrderRef.current.scrollLeft += scrollDistance;
        setScrolledWidth(scrolledWidth + scrollDistance);
    };

    const handlePreviousOrdersScroll = () => {
        setScrolledWidth(prevOrderRef.current.scrollLeft);
    };

    const handleReorder = async () => {
        try {
            const response = await reorder({ order_id: latestOrderData?.id });
            if (response?.data?.status === 200) {
                refetchCart();
                navigate(getRouteLink(ROUTES.CART_SUMMARY));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleBuyAgainPreviousOrder = () => {
        showModal({
            modalType: "AlertModal",
            modalProps: {
                title: "Note",
                message:
                    "Your existing cart items will be cleared. However, you can still update the cart as required when you reorder.",
                actionTitle: "OK",
                showCancelBtn: true,
                onClick: handleReorder,
            },
        });
    };

    const handleScrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const handleGotoPage = (e) => {
        const selectedValue = parseInt(e.target.value);
        setSelectedPage(selectedValue + 1);
        handleScrollToTop();
    };

    const handleGotoNext = () => {
        setSelectedPage(productTemplates?.next_page);
        handleScrollToTop();
    };

    const handleGotoPrevious = () => {
        setSelectedPage(productTemplates?.previous_page);
        handleScrollToTop();
    };

    const scrollEnd =
        scrolledWidth >=
        prevOrderRef?.current?.scrollWidth - prevOrderRef?.current?.clientWidth;

    const pageStartIndex = productTemplates?.previous_page
        ? productTemplates?.previous_page * pageSize + 1
        : 1;
    const pageEndIndex = productTemplates?.next_page
        ? productTemplates?.current_page * pageSize
        : productTemplates?.total_count;
    const totalPageCount = ceil(productTemplates?.total_count / pageSize);

    const featuredDeals = allDeals?.filter((item) => item.is_featured);

    return (
        <div className="products-page">
            <div className="page-content my-4">
                {isLoggedIn && latestOrderData && (
                    <div className="d-flex justify-content-center">
                        <div className="col-12 col-xl-9">
                            <div className="previous-order-wrapper justify-content-md-center">
                                <div className="arrow-wrapper">
                                    <button
                                        onClick={() =>
                                            handleHorizantalScroll(-500)
                                        }
                                        disabled={scrolledWidth === 0}
                                        className="d-inline-block d-lg-none"
                                    >
                                        <img
                                            className={`arrow prev-arrow ${
                                                scrolledWidth === 0
                                                    ? "hidden"
                                                    : ""
                                            }`}
                                            src="/vectors/kasi-chevron-down-black.svg"
                                            alt="<"
                                        />
                                    </button>
                                </div>
                                {latestOrderData &&
                                    latestOrderData?.state !== "cancel" && (
                                        <div className="d-block overflow-auto">
                                            <h2 className="content-title">
                                                Your Previous orders
                                            </h2>
                                            <div
                                                className="previous-order-container"
                                                ref={prevOrderRef}
                                                onScroll={
                                                    handlePreviousOrdersScroll
                                                }
                                            >
                                                <PreviousOrderCard
                                                    order={latestOrderData}
                                                    onClickBuyAgain={
                                                        handleBuyAgainPreviousOrder
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                <div className="arrow-wrapper">
                                    <button
                                        onClick={() =>
                                            handleHorizantalScroll(500)
                                        }
                                        disabled={scrollEnd}
                                        className="d-inline-block d-lg-none"
                                    >
                                        <img
                                            className={`arrow next-arrow ${
                                                scrollEnd ? "hidden" : ""
                                            }`}
                                            src="/vectors/kasi-chevron-down-black.svg"
                                            alt=">"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="products-list-wrapper mx-5 row-cols-xl-5">
                    {isProductTemplatesLoading ? (
                        new Array(8).fill(1).map((val, idx) => (
                            <div
                                key={idx}
                                className="col-12 col-sm-6 col-md-4 col-lg-3"
                            >
                                <ProductCardSkeleton />
                            </div>
                        ))
                    ) : productTemplates?.response?.length ? (
                        productTemplates?.response?.map((product) => (
                            <div
                                key={product.id}
                                className="col-12 col-sm-6 col-md-4 col-lg-3"
                            >
                                <ProductCard
                                    product={product}
                                    onClick={() =>
                                        handleOnClickProduct(product.id)
                                    }
                                />
                            </div>
                        ))
                    ) : (
                        <div className="d-flex align-items-center justify-content-center w-100">
                            <div>
                                <div className="alert alert-primary">
                                    No products available yet...
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {productTemplates?.response?.length > 0 && (
                    <div className="mx-5">
                        <Pagination
                            startIndex={pageStartIndex}
                            endIndex={pageEndIndex}
                            currentPage={productTemplates?.current_page - 1}
                            totalCount={productTemplates?.total_count}
                            pageCount={totalPageCount}
                            isNext={!!productTemplates?.next_page}
                            isPrevious={!!productTemplates?.previous_page}
                            onGoToPage={handleGotoPage}
                            onGoToNext={handleGotoNext}
                            onGoToPrevious={handleGotoPrevious}
                        />
                    </div>
                )}
                <div className="mt-4">
                    {featuredDeals?.length > 0 && (
                        <PromotionCarousel
                            promotions={featuredDeals}
                            showBackground
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
