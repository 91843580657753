import React, { useState } from "react";

import "./packagingDropDown.scss";

export default function PackagingDropDown({
    selectedPackage,
    options,
    onChange,
}) {
    const [showOption, setShowOption] = useState(false);

    const handleToggleDropDown = () => {
        setShowOption((prevState) => !prevState);
    };

    const handleOnChange = (e) => {
        if (onChange) {
            onChange(Number(e.target.value));
            handleToggleDropDown();
        }
    };

    return (
        <div className="packaging-drop-down-wrapper py-2 px-3 w-100 mb-2">
            <div
                role="button"
                className="packaging-drop-down"
                onClick={handleToggleDropDown}
            >
                <div className="packaging-title">
                    {selectedPackage
                        ? `Pack type: ${
                              options.find(({ id }) => id === selectedPackage)
                                  ?.name
                          }`
                        : "Select pack type"}
                </div>
                <div className="drop-down-btn">
                    <img
                        className={`${showOption && "active"}`}
                        src="/vectors/kasi-chevron-down-black.svg"
                        alt=""
                    />
                </div>
            </div>
            {showOption && (
                <div className="drop-down-option-group">
                    {options?.map((option, index) => (
                        <div
                            key={index}
                            className="d-flex align-items-center mt-2"
                        >
                            <input
                                type="radio"
                                name={option.id}
                                id={option.name}
                                value={option.id}
                                checked={selectedPackage === option.id}
                                className="radio-btn"
                                onChange={handleOnChange}
                            />
                            <label
                                htmlFor={option.name}
                                className="radio-label ms-2"
                            >
                                {option.name}
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
