import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQueries";

export const invoiceAPI = createApi({
    reducerPath: "invoiceAPI",
    baseQuery: baseQueryWithReauth,
    tagTypes: ["Invoices", "Invoice"],
    endpoints: (build) => ({
        getInvoices: build.query({
            query: (params) => ({
                url: "api/invoices/",
                method: "POST",
                body: {
                    params: {
                        method: "GET",
                        ...params,
                    },
                },
            }),
            providesTags: ["Invoices"],
            transformResponse: (response) => response?.result,
        }),
        getInvoice: build.query({
            query: ({ invoiceId: id }) => ({
                url: `api/invoices/${id}/`,
                method: "POST",
                body: {
                    params: {
                        method: "GET",
                    },
                },
            }),
            providesTags: ["Invoice"],
            transformResponse: (response) => response?.result?.response[0],
        }),
    }),
});

export const { useGetInvoicesQuery, useGetInvoiceQuery } = invoiceAPI;
