import React from "react";
import { parseCurrencyValue } from "../../utils/currencyUtils";
import { getSanitizedContent, parseBase64ImgSrc } from "../../utils/dataUtils";

import "./cards.scss";

const fallbackImagePath = "/images/product_placeholder.png";

/** Used to represent a product template
 * @typedef props
 * @property {*} product - product template record
 * @property {(e: React.SyntheticEvent) => void} onClick - Callback when the user clicks on the card image or button
 *
 * @param {props} props
 * @returns {JSX.Element}
 */
export default function ProductCard({ product, onClick }) {
    const productPrice = product?.currency_id[1]
        ? parseCurrencyValue(
              product?.list_price || 0,
              false,
              product?.currency_id[1]
          )
        : parseCurrencyValue(product?.list_price || 0);

    const hasDiscounts = !!product?.pricelist_item_count;

    return (
        <div className="product-card">
            <div className="product-image-wrapper">
                <img
                    src={
                        parseBase64ImgSrc(product?.image_128) ||
                        fallbackImagePath
                    }
                    alt={product?.name}
                    className="product-image"
                    onClick={onClick}
                />
            </div>
            <div className="product-info">
                <div className="d-flex justify-content-between gap-3 mt-3 mb-2">
                    <div className="product-title">{product?.name}</div>
                    <div className="product-price">{productPrice}</div>
                </div>
                <div className="product-subtitle mb-2">
                    {getSanitizedContent(product?.description)}
                </div>
                <div className="supplier-name d-block mb-2">
                    {/* TODO: integrate supplier details when available */}
                    Supplied by:&nbsp;Kasi
                </div>
                {hasDiscounts && (
                    <div className="discount-availability-text mb-2">
                        Discount Available
                    </div>
                )}
            </div>
            <button
                onClick={onClick}
                type="button"
                className="view-product-btn"
            >
                VIEW PRODUCT
            </button>
        </div>
    );
}
