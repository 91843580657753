import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const publicBaseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE,
});

const baseQueryWithCookie = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE,
    credentials: "include"
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQueryWithCookie(args, api, extraOptions);
    if (result.data.error && result.data.error.code === 100) {
        window.location = "/signout?session_expired=true";
    }
    return result;
};
