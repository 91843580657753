import React from "react";
import "./skeletons.scss";

export default function ProductImageCardSkeleton() {
    return (
        <div className="product-images-card-skeleton">
            <div className="product-image-thumbnails-wrapper-skeleton d-none d-md-block">
                <div className="product-image-thumbnails-container-skeleton">
                    <div className="product-image-thumbnails-scroll-container-skeleton">
                        <div className="product-image-thumbnail-skeleton">
                            <div className="placeholder-glow h-100 w-100">
                                <div className="placeholder h-100 w-100 rounded" />
                            </div>
                        </div>
                        <div className="product-image-thumbnail-skeleton">
                            <div className="placeholder-glow h-100 w-100">
                                <div className="placeholder h-100 w-100 rounded" />
                            </div>
                        </div>
                        <div className="product-image-thumbnail-skeleton">
                            <div className="placeholder-glow h-100 w-100">
                                <div className="placeholder h-100 w-100 rounded" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="product-image-thumbnail-slider-wrapper-skeleton">
                <div className="product-image-thumbnail-slider-container-skeleton">
                    <div className="product-image-thumbnail-slider-skeleton border rounded p-4">
                        <div className="placeholder-glow h-100 w-100">
                            <div className="placeholder h-100 w-100 rounded" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
