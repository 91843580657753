import React from "react";
import { useModal } from "../../../hooks/modalsHooks";
import Button from "../../Button";

import "./alertModal.scss";

export default function AlertModal({
    title,
    message,
    actionTitle,
    onClick,
    showCancelBtn = false,
    cancelBtnText
}) {
    const { hideModal } = useModal();

    const handleOnClick = () => {
        if (onClick) {
            onClick();
        }
        hideModal();
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8 col-lg-6">
                    <div className="content-container px-5 py-4">
                        <div className="d-flex flex-column align-items-center h-100">
                            {title && <h6>{title}</h6>}
                            {message && (
                                <div className="alert-message text-center">
                                    {message}
                                </div>
                            )}

                            <div className="d-flex justify-content-center w-75 gap-4">
                                {actionTitle && (
                                    <div className="w-50 mt-3">
                                        <Button
                                            size="sm"
                                            onClick={handleOnClick}
                                        >
                                            {actionTitle}
                                        </Button>
                                    </div>
                                )}
                                {showCancelBtn && (
                                    <div className="w-50 mt-3">
                                        <Button size="sm" onClick={hideModal}>
                                            {cancelBtnText || "Cancel"}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
