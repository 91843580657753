import React from "react";

import "./cards.scss";

export default function MyAccountSectionCard({
    image = "",
    title = "",
    description = "",
    onClick,
}) {
    return (
        <div
            role="button"
            className="my-account-section-card"
            onClick={onClick}
        >
            {image && (
                <div className="section-image-wrapper">
                    <img src={image} alt="" className="section-image" />
                </div>
            )}
            <div className="d-block">
                <div className="section-title">{title}</div>
                <div className="section-description">{description}</div>
            </div>
        </div>
    );
}
