import React from "react";

import "./policyPages.scss";

export default function TermsAndConditionsPage() {
    return (
        <div className="container p-5">
            <div className="main-title text-center mb-5">
                Terms and Conditions
            </div>
            <div className="mb-4">
                <p className="content m-0">
                    The Website / App www.Kasiwarehouse.co.za is operated by
                    African Distribution Technologies Pty Ltd. This document is
                    to share terms and conditions including our policies (“
                    <b>Terms</b>"") to understand how they apply to your use of
                    our Website or Mobile App and any products or services that
                    are available for our services.
                </p>
                <br />
                <p className="content m-0">
                    You visiting our Website or Mobile App to search for
                    products and services and buying such products or services
                    you confirm to have read through this document and are
                    agreeing to these terms and conditions.
                </p>
                <br />
                <p className="content m-0">Please read the rules carefully.</p>
            </div>
            <div className="mb-4">
                <div className="sub-title">
                    Use of Website & APP to avail Kasi Warehouse services
                </div>
                <p className="content m-0">
                    Usage of Website or Mobile App is only applicable if you
                    agree to these Terms & Conditions. We will not be able to
                    provide products or services if you fail to provide
                    agreement to accepting these conditions. Your use of the
                    Website & Mobile App constitutes agreement to their terms
                    and conditions as well.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">GENERAL TERMS</div>
            </div>
            <div className="mb-4">
                <div className="sub-title">
                    Who owns this Website / Mobile App?
                </div>
                <p className="content m-0">
                    The Kasi Warehouse Website (www.Kasiwarehosue.co.za) & Kasi
                    Warehouse Mobile App is owned and operated by African
                    Distribution Technologies Pty Ltd. All rights and/or
                    defences afforded to us in these Terms shall stand to the
                    benefit of our company.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Terms and conditions apply</div>
                <p className="content m-0">
                    These Terms apply to your use of this Website & Mobile App.
                    By using this Website or Mobile App, you acknowledge that
                    you have read and understood these Terms and agree to be
                    bound by them. They represent our entire agreement with you
                    and supersede all prior terms, conditions, warranties and/or
                    representations to the extent permitted by law. Please do
                    not use our Website or Mobile App if you do not agree or not
                    in position to agree to be bound by these Terms. All Online
                    ordering from our Website & Mobile App are subject to these
                    Terms.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Amended or updated terms</div>
                <p className="content m-0">
                    We may change these terms on occasion to make it aligned
                    with any change in condition or Law without notice. You
                    should check them from time to time, as your continued use
                    of our Website & Mobile App will mean you accept any updated
                    or revised Terms.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Law and arbitration</div>
                <p className="content m-0">
                    This agreement is governed by the laws of the Republic of
                    South Africa. Any dispute arising in relation to our
                    agreement with you shall, to the extent permitted by law, be
                    referred to arbitration in Johannesburg at a venue to be
                    determined by us applying the Uniform Rules of the High
                    Court of South Africa.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Use of our Website / App</div>
                <p className="content m-0">
                    You are welcome to browse or use our Website or Mobile App
                    for your own personal, non-commercial information purposes
                    only. No other use is permitted without our prior written
                    consent. The unauthorized use, copying, reproduction,
                    variation, modification or distribution of the content of
                    this Website or Mobile App, the uploading of any unlawful or
                    damaging information or viral software, or the creation of
                    any links to our Website or Mobile App from any other site
                    whatsoever, is strictly prohibited.
                </p>
                <br />
                <p className="content m-0">
                    Unless otherwise specified, the Website & Mobile App is
                    intended for your personal use only. You may not authorize
                    others to use the Website or Mobile App, and you are
                    responsible for all use of the Website & Mobile App by you
                    and by those you allow to use, or provide access to, the
                    Website & Mobile App. You may not impersonate, imitate or
                    pretend to be somebody else when using the Website or Mobile
                    App.
                </p>
                <br />
                <p className="content m-0">
                    You may not provide to or post on or through the Website or
                    Mobile App any graphics, text, photographs, images, video,
                    audio or other material that constitutes junk mail, spam,
                    advertising and/or commercial offers. You may not repeat the
                    same posting multiple times in a day or week.
                </p>
                <br />
                <p className="content m-0">
                    You agree not to use any obscene, indecent, or offensive
                    language or to provide to or post on or through the Website
                    and Mobile App any graphics, text, photographs, images,
                    video, audio or other material that is defamatory, abusive,
                    bullying, harassing, racist, hateful or violent. You agree
                    to refrain from ethnic slurs, religious intolerance,
                    homophobia and personal attacks when using the Website or
                    Mobile App.
                </p>
                <br />
                <p className="content m-0">
                    You agree not to use any sexually explicit language or to
                    provide to or post on or through the Website or Mobile App
                    any graphics, text, photographs, images, video, audio or
                    other material that is sexually explicit.
                </p>
                <br />
                <p className="content m-0">
                    You may not provide to or post on or through the Website or
                    Mobile App any graphics, text, photographs, images, video,
                    audio or other material that is encrypted, invades anyone’s
                    privacy, or illustrates, references or encourages conduct
                    that would constitute a criminal offence, give rise to
                    criminal liability or that otherwise violates any statute or
                    regulation.
                </p>
                <br />
                <p className="content m-0">
                    You agree to use the Website & Mobile App only for lawful
                    purposes and you acknowledge that your failure to do so may
                    subject you to civil and criminal liability.
                </p>
                <br />
                <p className="content m-0">
                    You are responsible for ensuring that any graphics, text,
                    photographs, images, video, audio or other material you
                    provide to or post anywhere on the Website / App does not
                    violate the copyright, trade mark, trade secret or any other
                    personal or proprietary rights of any third party or is
                    provided or posted with the permission of the owner/s of
                    such rights.
                </p>
                <br />
                <p className="content m-0">
                    The Website & Mobile App may contain graphics, text,
                    photographs, images, video, audio and other material that is
                    clearly identified for your use (“<b>Assets”</b>). The
                    Assets are protected by copyright, trademark and other
                    intellectual property laws. Nevertheless, we grant to you
                    the limited, non-exclusive rights and license to use the
                    Assets solely as described on the Website & Mobile App, as
                    limited by this Agreement, and provided further that you
                    keep intact any and all copyright and other proprietary
                    notices. This includes the “look and feel” of the Website &
                    Mobile App and the advertising thereon (“
                    <b>the Website & Mobile App Content</b>”). The Website &
                    Mobile App Content is protected by copyright, trade mark and
                    other intellectual property laws and is the property of
                    African Distribution technologies Pty Ltd and its authorized
                    service providers. The copying, reproduction, publication,
                    display, rearrangement, redistribution, modification,
                    revision, alteration, cropping, re-sizing, reverse
                    engineering, movement, removal, deletion, or other use or
                    change by you, directly or indirectly, whether by omission
                    or commission, of any such Website or Mobile App Content,
                    including but not limited to the removal or alteration of
                    advertising, is strictly prohibited.
                </p>
                <br />
                <p className="content m-0">
                    You may not in any way make commercial or other unauthorized
                    use, by publication, re-transmission, distribution,
                    performance, caching or otherwise, of material obtained
                    through the Website or Mobile App except as permitted by
                    statute or expressly permitted in writing by this Agreement
                    or the Website & Mobile App.
                </p>
                <br />
                <p className="content m-0">
                    You agree not to disrupt, overwhelm, attack, modify, reverse
                    engineer or interfere with the Website or Mobile App or its
                    associated software, hardware and/or servers in any way, and
                    you agree not to impede or interfere with others’ use of the
                    Website or Mobile App.
                </p>
                <br />
                <p className="content m-0">
                    Other than connecting to our and our third party service
                    provider’s servers by http requests using a Web browser, you
                    may not attempt to gain access to our servers by any means,
                    including, without limitation, by using administrator’s
                    passwords or by masquerading as an administrator while using
                    the Website or Mobile App or otherwise.
                </p>
                <br />
                <p className="content m-0">
                    You acknowledge that African Distribution Technologies Pty
                    Ltd and its third party service providers have not reviewed
                    and do not endorse the content of all sites linked to and
                    from this Website or Mobile App, and are not responsible for
                    the content or actions of any sites linked to or from this
                    Website or Mobile App. Linking to any service or site is at
                    your sole risk.
                </p>
                <br />
                <p className="content m-0">
                    You agree to indemnify African Technologies Pty Ltd against
                    any loss, damage, harm, claim or any other cost whatsoever
                    that we may sustain as a result of your use of our Website
                    or Mobile App and/or its contents contrary to these Terms.
                </p>
                <br />
                <p className="content m-0">
                    We use cookies to identify the computer device with which
                    you connect to our Website Or Mobile App. We reserve the
                    right to restrict your use and access to our Website or
                    Mobile App or part thereof in the event of any unauthorized
                    use.
                </p>
                <br />
                <p className="content m-0">
                    We reserve the right to suspend our Website Or Mobile App or
                    any part thereof or terminate your account at any time if
                    we, in our sole discretion, determine that you are not using
                    the Website or Mobile App in compliance with these Terms or
                    if we believe the information provided by you is untrue,
                    inaccurate or incomplete.
                </p>
                <br />
                <p className="content m-0">
                    Any indulgence of extension of time granted to you shall not
                    be construed as a waiver or variation of any of our rights
                    or remedies in these Terms or law.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">
                    Use of our Website or Mobile App outside South Africa
                </div>
                <p className="content m-0">
                    Customers who are not resident in South Africa are welcome
                    to use our Website or Mobile App in accordance with these
                    Terms to make online purchases provided that the delivery
                    address is as per the current coverage of African
                    Distribution Technology Pty Ltd.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">
                    Privacy policy and information security
                </div>
                <p className="content m-0">
                    Your privacy is very important to us. We take reasonable
                    steps to protect the personal information we hold and
                    process from misuse, loss and from authorized access,
                    modification or disclosure. We hold personal information
                    both at our own premises and with the assistance of our
                    service providers.
                </p>
                <br />
                <p className="content m-0">
                    Some of our service providers may be located in other
                    countries that may not have the same levels of protection of
                    personal information as South Africa. If this is the case,
                    we require that they undertake to protect the personal
                    information of our customers to the same level that we do.
                    Please refer to our detail Privacy Policy for more
                    information, and for contact details should you like to
                    request an update to your information.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">
                    Accuracy of Website or Mobile App content
                </div>
                <p className="content m-0">
                    All reasonable steps are taken to ensure that the
                    information on our Website or Mobile App is accurate and
                    up-to-date. We do not, however, warrant that the content or
                    information displayed is always accurate, complete and/or
                    current.
                </p>
                <br />
                <p className="content m-0">
                    Any comments uploaded by our users or any authorized experts
                    invited as our guests are their opinions alone and do not in
                    any way represent our views, opinions, beliefs or values.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Your comments</div>
                <p className="content m-0">
                    We value your comments, ideas, suggestions and feedback
                    (“your <b>Comment</b>”) via our Website or Mobile App. We
                    will not respond to, maintain or compensate you in any way
                    whatsoever for your Comments. Once received, your Comments
                    will become our property and we will not be limited in any
                    way in the use, commercial or otherwise, of any of your
                    Comments. <br />
                    African Distribution Technologies Pty Ltd does not
                    necessarily endorse, support, sanction, encourage, verify or
                    agree with the comments, opinions or statements posted on
                    forums, blogs or otherwise contained in the Website Or
                    Mobile App. Any information or material placed online,
                    including advice and opinions, are the views and
                    responsibilities of those who post the statements and do not
                    necessarily represent the views of African Distribution
                    Technologies Pty Ltd or its third party service providers.
                    You agree that African Distribution Technologies Pty Ltd and
                    its third party service providers are not responsible, and
                    shall have no liability to you, with respect to any
                    information or materials posted by others, including
                    defamatory, offensive or illicit material, even material
                    that violates this Agreement.
                </p>
                <br />
                <p className="content m-0">
                    You warrant that your Comments do not and will not violate
                    the right(s) of any third party, including copyright,
                    trademark, privacy or any other personal or proprietary
                    right. You agree not to disclose, submit or offer any
                    Comments that are or contain any defamatory, unlawful,
                    abusive or obscene material. We disclaim any liability
                    towards any third party regarding your Comments and you
                    shall remain accountable towards any third party regarding
                    any harm caused by your Comments.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Intellectual property</div>
                <p className="content m-0">
                    All rights, including copyright, trade mark and other
                    intellectual property rights embodied in any logos, text,
                    images, video, audio or other material on this Website or
                    Mobile App are owned by or licensed to us. All data and
                    information communicated to or from the Website and Mobile
                    App including its database also belongs solely to us or our
                    licensors. You agree to sign all documents as we may
                    reasonably require in order to assign any rights that you
                    may acquire in the content of our Website or Mobile App. You
                    agree also to waive any moral rights in such content. You
                    are permitted to view, print or store electronically a copy
                    of any information on our Website Or Mobile App, including
                    these Terms, solely for your personal, lawful,
                    non-commercial use. Unauthorized use, reproduction,
                    modification and/or distribution is strictly prohibited and
                    constitutes an unlawful infringement of our intellectual
                    property rights.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">
                    Use of our logos, content and images
                </div>
                <p className="content m-0">
                    You are not permitted to use the content of our Website and
                    Mobile App, our logos or any product or other images that
                    appear on our Website or Mobile App without our prior
                    written consent. Unauthorized use, reproduction,
                    modification and/or distribution is strictly prohibited and
                    constitutes an infringement of our or our licensors’
                    intellectual property rights.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Advertising and hyperlinks</div>
                <p className="content m-0">
                    External hyperlinks may appear on our Website or Mobile App.
                    The hyperlinks may not be construed to constitute any
                    relationship between us and any linked third party or any
                    endorsement by us of such third party, and use of or
                    reliance on any external links provided is at your own risk.
                    Advertising and other promotional material of third parties
                    may appear on our Website or Mobile App from time to time.
                    We do not endorse such third parties or their products
                    and/or services. Your reliance on any information contained
                    in such material is entirely at your own risk.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">
                    Disclaimers and exclusions of liability
                </div>
                <p className="content m-0">
                    Use of our Website and Mobile App is entirely at your own
                    risk. We make no representations or warranties of any kind,
                    whether express or implied. We do not warrant that the
                    functions provided by the Website and Mobile App will be
                    uninterrupted or error free, or that the Website and Mobile
                    App or the server that makes it available are free from
                    viruses or other harmful components.
                </p>
                <br />
                <p className="content m-0">
                    We accept no liability, to the extent permitted by law, for
                    any direct, indirect, incidental, special or consequential
                    loss or damage of any kind whatsoever or howsoever caused
                    arising from the access or use of our Website or Mobile App
                    or the purchase of any of our products or services. You
                    agree, to the extent permitted by law, to indemnify us
                    against any loss or damage suffered or liability incurred by
                    reason of any act or omission on your part or that of any
                    third party acting on your behalf in connection with your
                    use of our Website or Mobile App and/or your purchase of any
                    products or services. Notwithstanding any other provisions
                    contained herein, our liability arising from any breach of
                    these Terms, negligence or otherwise shall not at any time
                    exceed more than double the monetary value of any order
                    placed via this Website or Mobile App.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Indemnification</div>
                <p className="content m-0">
                    You hereby indemnify African Distribution Technologies Pty
                    Ltd and its affiliates, employees, agents, representatives
                    and third party service providers, and hereby defend and
                    hold each of them harmless from any and all claims and
                    liabilities (including legal costs on the attorney-client
                    scale) which may arise from your submissions, from your
                    unauthorized use of material obtained through the Website or
                    Mobile App, or from your breach of this Agreement, or from
                    any such acts through your use of the Website or Mobile App.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">ONLINE ORDERING TERMS</div>
                <p className="content m-0">
                    These online ordering terms and conditions apply to all
                    online orders at https://www.kasiwarehouse.co.za Please read
                    them carefully and print a copy for future reference. You
                    are required to register with us before placing your first
                    order online. Please complete the online registration form.
                    Registration and/or use of our Website or Mobile App
                    constitute your acceptance and agreement to be bound by the
                    Terms of our Website and Mobile App, including the
                    additional online terms below.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Online registration</div>
                <p className="content m-0">
                    Registration on our online platform is conditional upon your
                    specified location being within certain specified areas. If
                    your location is not in an area where we offer our services,
                    your registration will not be completed. To register you
                    will be required to provide certain information including
                    personal details, payment card details (if needed) and your
                    address. When registering you will be required to choose a
                    user ID and a password. Please keep your password private.
                    African Distribution Technologies Pty Ltd accepts no
                    liability for any damages suffered or losses incurred from
                    the use or misuse of your password. You may be required to
                    enter your user ID and password when visiting our Website
                    and Mobile App. However browsers may autofill your user ID
                    and password as well. To amend any registration particulars
                    please click on “My Profile” and edit your details.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Products and availability</div>
                <div className="sub-title">Colours</div>
                <p className="content m-0">
                    We have made every effort to display as accurately as
                    possible the colours of the products that appear on our
                    Website or Mobile App. However, as the actual colours you
                    see will depend on the device that you use to access our
                    Website or Mobile App, we cannot warrant that your device’s
                    display of any colour will be accurate.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Content errors</div>
                <p className="content m-0">
                    We regret that no order will be accepted if we identify a
                    material error in the description of any of our goods or
                    services or their prices on our Website or Mobile App.
                    Packaging may vary from that shown on our Website or Mobile
                    App.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Pricing policy</div>
                <p className="content m-0">
                    You will be charged the prices as they are reflected on our
                    Website and Mobile App. Certain of the Products are
                    variable/random mass items. Whilst the average mass of the
                    Product is indicated on our Website & Mobile App as a guide
                    to the cost, the actual Product delivered to you may weigh
                    slightly more or less than the average mass indicated. You
                    will be charged the amount as indicated on our Website and
                    Mobile App. Prices include Value Added Tax. By using
                    service, you accept that African Distribution Technologies
                    Pty Ltd is a shopping service provider and not a store.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Methods of payment</div>
                <p className="content m-0">
                    Payment can be done in advance through EFT or by bank cards,
                    EFT or in cash upon collection of your order. By submitting
                    your order, you warrant that you are over the age of 18
                    (eighteen), are authorized to make payment with the payment
                    card and that there are sufficient funds available to pay
                    for the order.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Stock availability</div>
                <p className="content m-0">
                    African Distribution Technologies Pty Ltd cannot always
                    guarantee availability of stock.
                </p>
                <br />
                <p className="content m-0">
                    If we are unable to supply each and every item ordered or in
                    the quantities ordered, you nevertheless agree to accept
                    delivery and make payment for the items correctly delivered.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Confirmation of orders</div>
                <p className="content m-0">
                    Orders placed online constitute your offer to use our
                    services subject to these Terms. Your offer is deemed to
                    have been accepted by us when you receive our confirmation
                    of your order. Failure by us to formally confirm or notify
                    acceptance shall not affect the validity of using our
                    services or the enforceability thereof.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Delivery</div>
                <p className="content m-0">
                    African Distribution Technologies Pty Ltd collection
                    timeslots and operational location are indicated on our
                    Website and Mobile App. We only deliver to registered
                    customers in the operational locations. On collection you
                    will receive your delivery note showing the items, any
                    substitute items, any non-available items, Value-Added Tax
                    and the total amount due for payment. In the event where we
                    are not able to deliver goods as per agreed time slots on a
                    date, African Distribution technologies Pty Ltd will attempt
                    to contact you to make new arrangements for Deliveries on
                    next convenient time slot for both parties. Please arrange
                    that you or your authorized representative is available to
                    accept your delivery at the delivery address. On delivery,
                    we will require you or your authorized representative to
                    sign and print your or their name on a duplicate copy of the
                    delivery note, to confirm receipt.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Cancellation</div>
                <p className="content m-0">
                    If for any reason you would like to cancel an order this may
                    be done by visiting your order history on our Website or
                    Mobile App. You will only be able to cancel orders up to
                    midnight on the day prior to the date of delivery. We
                    reserve the right to request your payment for the delivery
                    in respect of any late cancellations and you will
                    nevertheless be bound to pay to the full price. Without
                    prejudice to any other rights or remedies in law, we reserve
                    the right to cancel forthwith any use of our services and/or
                    your registration in the event of your breach any of the
                    Terms.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Risk and ownership</div>
                <p className="content m-0">
                    Risk in the items shall pass to you or your authorized
                    representative on collection. We will retain ownership in
                    the items until payment has been received in full.
                </p>
            </div>
        </div>
    );
}
