import React from "react";

import "./policyPages.scss";

export default function ConsumerProtectionActPage() {
    return (
        <div className="container p-5">
            <div className="main-title text-center mb-5">
                Consumer Protection Act
            </div>
            <div className="mb-4">
                <p className="content m-0">
                    We at African distribution technologies Pty Ltd are
                    committed to put the customer of our website Or Mobile app
                    first, We will always try our best to uphold Consumer
                    Protection Act in words and spirit both in all our
                    transactions with our platform users.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">
                    What is the Consumer Protection Act?
                </div>
                <p className="content m-0">The act aims to:</p>
                <br />
                <ul className="ms-5">
                    <li>
                        Promote a fair, accessible and sustainable marketplace
                        for consumer products and services.
                    </li>
                    <li>
                        Establish national norms and standards to ensure
                        consumer protection.
                    </li>
                    <li>
                        To provide for improved standards of consumer
                        information, to prohibit unfair marketing and business
                        practices.
                    </li>
                    <li>To Promote reasonable consumer behaviour.</li>
                    <li>
                        To Promote a consistent legislative and enforcement
                        framework relating to consumer transactions and
                        agreements.
                    </li>
                    <li>To Establish the national consumer commission.</li>
                </ul>
            </div>
            <div className="mb-4">
                <div className="sub-title">What are the consumer’s rights?</div>
                <p className="content m-0">
                    The act has reinforced 9 rights for the consumer has:
                </p>
                <br />
                <ul className="ms-5">
                    <li>
                        Right of equality in the consumer market and protection
                        against discriminatory marketing practices
                    </li>
                    <li>
                        Right to privacy and right to restrict unwanted
                        marketing
                    </li>
                    <li>Right to choose suppliers</li>
                    <li>
                        Right to disclosure of information in plain and
                        understandable language
                    </li>
                    <li>Right to fair and responsible marketing</li>
                    <li> Right to fair and honest dealing</li>
                    <li>
                        Right to fair, just and reasonable terms and conditions
                    </li>
                    <li>Right to fair value, good quality and safety and</li>
                </ul>
            </div>
            <div className="mb-4">
                <div className="sub-title">
                    Your right to accountability by suppliers
                </div>
                <p className="content m-0">
                    We will listen to the voice of the consumer, and we
                    appreciate your feedback. Your comments, whether good or
                    bad, give us an opportunity to grow and improve, identify
                    your needs and expectations, and strive to exceed those
                    expectations daily.
                </p>
                <br />
                <p className="content m-0">
                    Should you wish to contact us we have a number of channels
                    that you can use to let us know how you feel about your
                    experience within any of our stores. We promise to address
                    your concerns as efficiently as possible, in a professional
                    manner.
                </p>
                <br />
                <p className="content m-0">
                    Yebo fresh would like to thank you, our platform users, for
                    the continued support, and we look forward to serving you
                    into the next decade.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Raising your concerns with us</div>
                <ul className="ms-5">
                    <li>
                        Fill the contact form on our website&nbsp;
                        <span>www.Kasiwarehouse.co.za</span>
                    </li>
                    <li>
                        Send us e-mail on&nbsp;
                        <span>happytohelp@kasiwarehouse.co.za</span>
                    </li>
                    <li>Call us at +27 6602 57577</li>
                </ul>
            </div>
            <div className="mb-4">
                <div className="sub-title">Additional details needed</div>
                <ul className="ms-5">
                    <li>
                        Please detail out below in case the matter is related to
                        a product related issue
                        <ul className="ms-5">
                            <li>
                                Detail out challenges you facing with the
                                product
                            </li>
                            <li>Product description</li>
                            <li>Bar code</li>
                            <li>Best before date</li>
                            <li>Batch number</li>
                            <li>Cost of product</li>
                            <li>How many items you purchased</li>
                        </ul>
                    </li>
                    <br />
                    <li>
                        Please send us following details when sharing them via
                        website or email
                        <ul className="ms-5">
                            <li>Name and surname</li>
                            <li>
                                Contact numbers (landline and cell if possible)
                            </li>
                            <li>Nature of complaint and full details.</li>
                            <li>Click and Collect location</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
}
