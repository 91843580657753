import React from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../config/routesConfig";
import { useRouting } from "../../hooks/routesHooks";
import { useLoginStatus } from "../../hooks/authHooks";

import { useGetCategoriesQuery } from "../../services/categoryService";
import { parseBase64ImgSrc } from "../../utils/dataUtils";

import Button from "../../components/Button";
import HowItWorksCard from "../../components/cards/HowItWorksCard";
import BuyerBenefitCard from "../../components/cards/BuyerBenefitCard";
import CategoryCard from "../../components/cards/CategoryCard";
import { BannerCarousel } from "../../components/carousels";
import CategoryCardSkeleton from "../../components/skeletons/CategoryCardSkeleton";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./landingPage.scss";

const SliderLeftArrow = ({ currentSlide, slideCount, children, ...props }) => (
    <img {...props} src="/vectors/kasi-prev-green-icon.svg" alt="<" />
);

const SliderRightArrow = ({ currentSlide, slideCount, children, ...props }) => (
    <img {...props} src="/vectors/kasi-next-green-icon.svg" alt=">" />
);

export default function LandingPage() {
    const navigate = useNavigate();
    const { getRouteLink } = useRouting();

    const [showPlayBtn, setShowPlayBtn] = React.useState(true);
    const [isPlaying, setIsPlaying] = React.useState(false);

    const videoRef = React.createRef();
    const sliderRef = React.createRef();
    const scrollRef = React.useRef(null);

    const isLoggedIn = useLoginStatus();
    const { data: allCategories, isFetching: isAllCategoriesLoading } =
        useGetCategoriesQuery();

    const handleOnNavigate = (path) => {
        navigate(getRouteLink(path));
    };

    const handleNavigateToProductsPage = (categoryId) => {
        navigate({
            pathname: getRouteLink(ROUTES.PRODUCTS),
            search: `?category_id=${categoryId}`,
        });
    };

    const handlePlayPauseVideo = () => {
        if (videoRef.current.paused) {
            videoRef.current.play();
            setIsPlaying(true);
        } else {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    };

    const handleOnHoverVideo = (flag) => {
        if (!videoRef.current.paused) {
            setShowPlayBtn(flag);
        }
    };

    const HowItWorksData = [
        {
            id: 1,
            title: "Download Kasi Warehouse App and Sign Up",
            description: "You can also Sign up on Website.",
        },
        {
            id: 2,
            title: "Place Order",
            description:
                "Place an order on App, Website or chat with us on Whatsapp.",
        },
        {
            id: 3,
            title: "Competitive and Standard Pricing",
            description: "No Weekly price changes.",
        },
        {
            id: 4,
            title: (
                <>
                    Same Day <br />
                    Door Step Delivery
                </>
            ),
            description: "Our Team will be there with you in a few hours.",
        },
    ];

    const buyersBenefitDate = [
        {
            id: 1,
            image: "/vectors/kasi-tracking-product.svg",
            title: "Direct from producers",
            description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
        {
            id: 2,
            image: "/vectors/kasi-transaction-secure.svg",
            title: "Secure transactions",
            description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
        {
            id: 3,
            image: "/vectors/kasi-quality-verify.svg",
            title: "Quality verified",
            description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
    ];

    const manufacturersBenefitDate = [
        {
            id: 1,
            image: "/vectors/kasi-transparent-pricing.svg",
            title: "Transparent pricing",
            description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
        {
            id: 2,
            image: "/vectors/kasi-manage-money.svg",
            title: "Improved cash flow",
            description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
        {
            id: 3,
            image: "/vectors/kasi-shopping-bag.svg",
            title: "No Intermediaries",
            description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
    ];

    const scrollToDiv = () => {
        if (scrollRef.current) {
          scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
        <div className="landing-page">
            <div className="banner-section-1 pb-4">
                <BannerCarousel />
                <div className="banner-title text-center mt-5 px-4">
                    Building E-Commerce Future for Informal Merchants in South
                    Africa
                </div>
                <div className="d-flex align-items-center justify-content-center mt-5">
                    {isLoggedIn ? (
                        <div className="user-button mx-2 mx-sm-5">
                            <Button
                                onClick={() =>
                                    handleOnNavigate(ROUTES.PRODUCTS)
                                }
                            >
                                Shop Now
                            </Button>
                        </div>
                    ) : (
                        <>
                            <div className="user-button mx-2 mx-sm-5">
                                <Button
                                    onClick={() =>
                                        handleOnNavigate(ROUTES.PRODUCTS)
                                    }
                                >
                                    Shop Now
                                </Button>
                            </div>
                            <div className="user-button mx-2 mx-sm-5">
                                <Button
                                    onClick={scrollToDiv}
                                >
                                    Kasi Benefits
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="d-flex flex-column align-items-center py-5 px-4 mt-5 mt-md-0">
                <div className="section-title text-center">Categories</div>
                <div className="px-2 px-sm-3 w-100">
                    <div className="category-container mt-3">
                        {isAllCategoriesLoading ? (
                            <Slider
                                ref={sliderRef}
                                initialSlide={0}
                                arrows
                                slidesToShow={4}
                                slidesToScroll={4}
                                infinite={false}
                                speed={500}
                                nextArrow={<SliderRightArrow />}
                                prevArrow={<SliderLeftArrow />}
                                responsive={[
                                    {
                                        breakpoint: 991,
                                        settings: {
                                            slidesToShow: 3,
                                            slidesToScroll: 3,
                                        },
                                    },
                                    {
                                        breakpoint: 767,
                                        settings: {
                                            slidesToShow: 2,
                                            slidesToScroll: 2,
                                        },
                                    },
                                    {
                                        breakpoint: 575,
                                        settings: {
                                            slidesToShow: 1,
                                            slidesToScroll: 1,
                                        },
                                    },
                                ]}
                            >
                                {new Array(4).fill(1).map((val, idx) => (
                                    <div
                                        key={idx}
                                        className="col-12 col-sm-6 col-md-4 col-lg-3"
                                    >
                                        <CategoryCardSkeleton />
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            allCategories && (
                                <Slider
                                    ref={sliderRef}
                                    initialSlide={0}
                                    arrows
                                    slidesToShow={4}
                                    slidesToScroll={4}
                                    infinite={false}
                                    speed={500}
                                    nextArrow={<SliderRightArrow />}
                                    prevArrow={<SliderLeftArrow />}
                                    responsive={[
                                        {
                                            breakpoint: 991,
                                            settings: {
                                                slidesToShow: 3,
                                                slidesToScroll: 3,
                                            },
                                        },
                                        {
                                            breakpoint: 767,
                                            settings: {
                                                slidesToShow: 2,
                                                slidesToScroll: 2,
                                            },
                                        },
                                        {
                                            breakpoint: 575,
                                            settings: {
                                                slidesToShow: 1,
                                                slidesToScroll: 1,
                                            },
                                        },
                                    ]}
                                >
                                    {allCategories?.map((category) => (
                                        <div key={category.id} className="my-3">
                                            <CategoryCard
                                                title={category.name}
                                                image={parseBase64ImgSrc(
                                                    category.image_128
                                                )}
                                                onClick={() =>
                                                    handleNavigateToProductsPage(
                                                        category.id
                                                    )
                                                }
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            )
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center py-5 px-4" ref={scrollRef} id="growth">
                <div className="section-title text-center">
                    Growth is just a click away
                </div>
                <div className="how-it-works-container mt-5">
                    {HowItWorksData?.map((item) => (
                        <div key={item.id} className="how-it-works-wrapper">
                            <HowItWorksCard
                                count={item.id}
                                title={item.title}
                                description={item.description}
                            />
                        </div>
                    ))}
                </div>
            </div>
            {/* video section temporarily removed */}
            {/* <div className="d-flex flex-column align-items-center py-5 px-5">
                <div
                    className="video-wrapper"
                    onMouseOver={() => handleOnHoverVideo(true)}
                    onMouseLeave={() => handleOnHoverVideo(false)}
                >
                    <video
                        ref={videoRef}
                        className="video"
                        poster="/images/kasi-shopping-video-poster.png"
                        loop
                    >
                        <source
                            src="https://placehold.co/1280x720.mp4?text=Welcome+To+Kasi+Warehouse"
                            type="video/mp4"
                        />
                    </video>
                    {showPlayBtn && (
                        <div
                            role="button"
                            onClick={handlePlayPauseVideo}
                            className="play-btn"
                        >
                            {isPlaying ? (
                                <img
                                    src="/vectors/kasi-pause-btn.svg"
                                    alt="pause-btn"
                                />
                            ) : (
                                <img
                                    src="/vectors/kasi-play-btn.svg"
                                    alt="play-btn"
                                />
                            )}
                        </div>
                    )}
                </div>
            </div> */}
            {/* Benefits for Merchants temporarily removed */}
            {/* <div className="d-flex flex-column align-items-center py-5 px-5">
                <div className="section-title text-center">
                    Benefits for Merchants
                </div>
                <div className="row justify-content-center mt-5 px-sm-5">
                    {buyersBenefitDate?.map((item) => (
                        <div key={item.id} className="col-lg-4 col-md-6 my-3">
                            <BuyerBenefitCard
                                image={item.image}
                                title={item.title}
                                description={item.description}
                            />
                        </div>
                    ))}
                </div>
            </div> */}
            {/* <div className="banner-section-2 my-5 py-4 px-5">
                <div className="d-flex align-items-center justify-content-between flex-column flex-sm-row h-100">
                    <div className="banner-title-wrapper mb-4 mb-sm-0">
                        <div className="banner-title">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry
                        </div>
                    </div>
                    <div className="banner-img-wrapper h-100">
                        <img src="/vectors/kasi-home-banner-2.svg" alt="" />
                    </div>
                </div>
            </div> */}
            {/* Benefits for manufacturers temporarily removed */}
            {/* <div className="d-flex flex-column align-items-center py-5 px-5">
                <div className="section-title text-center">
                    Benefits for manufacturers
                </div>
                <div className="row justify-content-center mt-5 px-sm-5">
                    {manufacturersBenefitDate?.map((item) => (
                        <div key={item.id} className="col-lg-4 col-md-6 my-3">
                            <BuyerBenefitCard
                                image={item.image}
                                title={item.title}
                                description={item.description}
                            />
                        </div>
                    ))}
                </div>
            </div> */}
            {!isLoggedIn && (
                <div className="register-banner w-100">
                    <div className="banner-title">Ready to get started?</div>
                    <div className="btn-wrapper">
                        <Button
                            onClick={() => handleOnNavigate(ROUTES.REGISTER)}
                        >
                            Register
                        </Button>
                    </div>
                </div>
            )}

            <div className="help-btn-wrapper my-5 pb-4">
                <Button onClick={() => handleOnNavigate(ROUTES.CONTACT)} fullWidth={false}>Help</Button>
            </div>
        </div>
    );
}
