import React from "react";
import { Navigate } from "react-router-dom";
import { useLoginStatus } from "../../hooks/authHooks";
import ScrollToTop from "./ScrollTop";

const PublicOnly = ({
    scrollToTop = true,
    children,
}) => {
    const isLoggedIn = useLoginStatus();

    // // Show the component only when the user is logged in
    // // Otherwise, redirect the user to home page
    if (isLoggedIn) {
        return (
            <>
                {scrollToTop && <ScrollToTop />}
                {/* This will skip the public only routes and throw the user to the last allowed route they were in */}
                <Navigate to={-1} />
            </>
        );
    }

    return (
        <>
            {scrollToTop && <ScrollToTop />} {children}
        </>
    );
};

export default PublicOnly;
