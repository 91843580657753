import React from "react";
import { useField } from "formik";
import PhoneNumberField from "../PhoneNumberField";
import "./viewableField.scss";

export default function ViewablePhoneNumberField({
    placeholder = "",
    label = "",
    name = "",
    isEditing,
    endButtonTitle = "",
    onEndButtonClick,
    isLoading = false,
}) {
    const [field, meta] = useField(name);
    const [phoneField, phoneMeta, phoneHelpers] = useField(`${name}-phone`);

    const isTextAvailable = !!field.value;

    const isTextChanged = field.value !== meta.initialValue;

    const metaError = meta.error || phoneMeta.error;

    const handleOnEndButtonClick = (e, value) => {
        e.stopPropagation();
        if (onEndButtonClick) {
            onEndButtonClick(value);
        }
    };

    return (
        <div className="viewable-field">
            <div
                className={`viewable-field-content ${
                    !endButtonTitle ? "w-100" : ""
                }`}
            >
                {label && (
                    <label htmlFor={name} className="viewable-field-label">
                        {label}
                    </label>
                )}
                <PhoneNumberField
                    placeholder={placeholder}
                    name={name}
                    label=""
                    disabled={!isEditing}
                />
            </div>
            <div className="viewable-field-actions-container ">
                <div
                    className={`${
                        metaError ? "bottom-margin" : ""
                    } ms-sm-5 mt-3 mt-sm-0`}
                >
                    {endButtonTitle && (
                        <button
                            type="button"
                            className="viewable-field-end-btn"
                            onClick={(e) =>
                                handleOnEndButtonClick(e, field.value)
                            }
                            disabled={
                                !isEditing ||
                                !isTextAvailable ||
                                metaError ||
                                !isTextChanged
                            }
                        >
                            {isLoading ? (
                                <>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    <span className="ms-2">Loading...</span>
                                </>
                            ) : (
                                endButtonTitle
                            )}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
