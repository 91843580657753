import { createApi } from "@reduxjs/toolkit/query/react";
import { publicBaseQuery } from "./baseQueries";

export const publicCategoryAPI = createApi({
    reducerPath: "publicCategoryAPI",
    baseQuery: publicBaseQuery,
    tagTypes: ["Categories", "Category"],
    endpoints: (build) => ({
        getCategories: build.query({
            query: () => ({
                url: "/api/categories/",
                method: "POST",
                body: {
                    params: { method: "GET" }
                },
            }),
            providesTags: ['Categories'],
            transformResponse: (response) => response.result.response
        }),
        getCategory: build.query({
            query: ({ id }) => ({
                url: `/api/categories/${id}/`,
                method: "POST",
                body: {
                    params: { method: "GET" }
                },
            }),
            providesTags: ['Category']
        }),
    }),
});

export const { useGetCategoriesQuery, useGetCategoryQuery } = publicCategoryAPI;
