import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";

import {
    Form,
    SubmitButton,
    TextField,
    TextAreaField
} from "../../components/forms";

import "./Contact.scss";

const ContactFormSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    subject: Yup.string().required("Required"),
    message: Yup.string().required("Required"),
});

function ContactPage() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [formState] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    });

    const handleContactSubmit = async (values, { resetForm }) => {
        setIsLoading(true);
        setIsError(false);
        setIsSuccess(false);
        

        let templateParams = {
            from_name: values.name,
            message: values.message,
            subject: values.subject,
            reply_to: values.email,
            from_email: values.email,
        };

        emailjs
            .send(
                "service_uoiww2m",
                "template_amz1bjh",
                templateParams,
                "yaIg9RO-0LK3cVdsn"
            )
            .then(
                function (response) {
                    setIsSuccess(true);

                    resetForm({
                        values: {
                            name: "",
                            email: "",
                            subject: "",
                            message: "",
                        },
                    });

                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 3000);
                    setIsLoading(false);
                },
                function (error) {
                    setIsError(true);
                    setIsLoading(false);
                }
            );
            
    };

    const handleOnBackClick = () => {
        navigate(-1);
    };
    return (
        <ContactLayout
            bannerImg="/vectors/kasi-contact-banner.svg"
            onClickBack={handleOnBackClick}
        >
            <div
                className="d-flex flex-column justify-content-center"
                style={{ minHeight: "100%" }}
            >
                <h1>GET IN TOUCH</h1>
                <div>
                    <Form
                        onSubmit={handleContactSubmit}
                        initialValues={formState}
                        validationSchema={ContactFormSchema}
                    >
                        <div>
                            <TextField
                                name="name"
                                label="Enter your name"
                                placeholder="Enter your name"
                            />
                        </div>
                        <div className="mt-4">
                            <TextField
                                name="email"
                                label="Enter your email"
                                placeholder="Enter your email"
                            />
                        </div>
                        <div className="mt-4">
                            <TextField
                                name="subject"
                                label="Type the subject"
                                placeholder="Type your message subject here"
                            />
                        </div>
                        <div className="mt-4">
                            <TextAreaField
                                name="message"
                                label="Your message"
                                placeholder="Type your message here"
                                type="textarea"
                                rows="3"
                            />
                        </div>

                        {isError && (
                            <div className="alert alert-primary text-center mt-4">
                                Something went wrong. Please try again in few minutes.
                            </div>
                        )}

                        {isSuccess && (
                            <div className="alert alert-success text-center mt-4">
                                Message sent successfully!
                            </div>
                        )}

                        <div className="mt-4">
                            <SubmitButton isLoading={isLoading}>Submit</SubmitButton>
                        </div>
                        <div className="mt-4">
                            <h6 className="fw-normal">Reach out to us at:</h6>
                        </div>
                        <div className="d-flex align-items-center gap-3 mt-3">
                            <img
                                src="/vectors/email-icon.svg"
                                alt=" "
                                className="navbar-search-start-icon"
                            />
                            <a
                                href="mailto:happytohelp@kasiwarehouse.co.za?subject=I need help with"
                                target="_self"
                                className="m-0 fw-light link-text"
                            >
                                happytohelp@kasiwarehouse.co.za
                            </a>
                        </div>
                        <div className="d-flex align-items-center gap-3 mt-3">
                            <img
                                src="/vectors/mobile-icon.svg"
                                alt=" "
                                className="navbar-search-start-icon"
                            />
                            <a
                                href="tel:+27660257577"
                                className="m-0 fw-light link-text"
                            >
                                +27 (0) 78 290 6986
                            </a>
                        </div>
                    </Form>
                </div>
            </div>
        </ContactLayout>
    );
}

export default ContactPage;

function ContactLayout({ children, onClickBack, bannerImg }) {
    return (
        <div className="contact-layout container-fluid">
            <div className="content-area row align-items-center">
                <div className="left-column col-md-6">
                    <img src={bannerImg} alt="" />
                </div>
                <div className="right-column col-md-6 py-4 ps-md-5 pe-4 p-4">
                    <div className="d-flex flex-column w-100">
                        {onClickBack && (
                            <div className="d-flex align-items-center ps-md-5 pt-md-4">
                                <img
                                    src="/vectors/kasi-black-left-arrow.svg"
                                    alt=""
                                    role="button"
                                    onClick={onClickBack}
                                />
                            </div>
                        )}
                        <div className="page-content px-md-5 mt-3">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
