import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../hooks/modalsHooks";
import { useSearchParams } from "react-router-dom";

export default function SignoutPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showModal } = useModal();
    const [params] = useSearchParams();

    useEffect(() => {
        // clear redux store
        dispatch({ type: "auth/logout" });
        // clear browser storages
        localStorage.clear();
        sessionStorage.clear();

        const isSessionExpired = params.get("session_expired");
        if (isSessionExpired) {
            showModal({
                modalType: "AlertModal",
                modalProps: {
                    title: "Session expired",
                    message: "Please login again.",
                    actionTitle: "OK",
                },
            });
        }

        //go to landing page
        navigate("/");
    }, [dispatch, navigate]);

    return null;
}
