import React from "react";
import "./cards.scss";

const fallbackImagePath = "/images/product_placeholder.png";

export default function CategoryCard({ image, title, onClick }) {
    return (
        <div role="button" className="category-card" onClick={onClick}>
            <div className="category-card-title w-100">{title}</div>
            <div className="category-card-image-wrapper">
                <img
                    src={image || fallbackImagePath}
                    alt=""
                    className="category-card-image"
                />
            </div>
        </div>
    );
}
