import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: [],
};

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        pushCartItem: (state, { payload }) => {
            const itemList = state.items.slice();

            // Check if item is already added
            const addedProductVariantIdx = itemList.findIndex(
                ({ productVariant }) => productVariant?.id === payload?.productVariant?.id,
            );
            if (addedProductVariantIdx > -1) {
                // If item is already added, update the quantity
                const { quantity } = payload;
                itemList[addedProductVariantIdx].quantity = quantity;
            } else {
                itemList.push(payload);
            }
            state.items = itemList;
        },
        popCartItem: (state) => {
            const itemList = state.items.slice();
            itemList.pop();
            state.items = itemList;
        },
        removeCartItemByIdx: (state, { payload }) => {
            if (payload >= 0) {
                const itemList = state.items.slice();
                itemList.splice(payload, 1);
                state.items = itemList;
            }
        },
        updateCartItemByIdx: (state, { payload }) => {
            if (payload.idx >= 0 && payload.item) {
                const { idx, item } = payload;
                const itemList = state.items.slice();
                itemList[idx] = item;
                state.items = itemList;
            }
        },
        resetCart: (state) => {
            state.items = [];
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    popCartItem,
    pushCartItem,
    removeCartItemByIdx,
    updateCartItemByIdx,
    resetCart,
} = cartSlice.actions;

export const cartReducer = cartSlice.reducer;
