import dayjs from "dayjs";
import { orderStatusTypes } from "../constants/orderStatusTypes";

export const transformFilterParams = (params) => {
    return params?.split(",").includes("") ? [] : params?.split(",");
};

export const parseBase64ImgSrc = (str = "") =>
    str ? `data:image/png;base64,${str}` : null;

export const getSanitizedContent = (dirtyStr = "") =>
    dirtyStr ? dirtyStr.replace(/(<([^>]+)>)/gi, "") : null;

export const getBusinessTypesOptions = (businessTypes) => {
    const formattedBusinessTypes = businessTypes?.map((type) => ({
        label: type[1],
        value: type[0],
    }));

    return formattedBusinessTypes;
};

export const getTownshipsOptions = (townships) => {
    const formattedTownships = townships?.map((type) => ({
        label: type[1],
        value: type[0],
    }));

    return formattedTownships;
};

export const getChipVariant = (status) => {
    let variant = "";
    let text = "";

    switch (status) {
        case orderStatusTypes.COMPLETE:
            variant = "success";
            text = "Complete";
            break;
        case orderStatusTypes.PLACED:
            variant = "warning";
            text = "Placed";
            break;
        case orderStatusTypes.CANCEL:
            variant = "danger";
            text = "Cancelled";
            break;
        default:
            break;
    }
    return { variant: variant, text: text };
};
export const getDiscountsOptions = (priceLists) => {
    const formattedDiscounts = priceLists?.map((priceList) => ({
        label: priceList.name,
        value: priceList.id,
    }));

    return formattedDiscounts;
};

export const getFormattedValidPeriod = (startDate, endDate, shortDate = false) => {
    const isFutureDate = dayjs().isBefore(dayjs(startDate));
    if (isFutureDate) {
        const formattedValidDate = shortDate ? `${dayjs(startDate).format("DD MMM")} - ${dayjs(
            endDate
        ).format("DD MMM")}` : `${dayjs(startDate).format("DD MMM YYYY")} | ${dayjs(
            endDate
        ).format("DD MMM YYYY")}`;

        return formattedValidDate;
    }

    return `Valid till ${dayjs(endDate).format("DD MMM YYYY")}`;
};
