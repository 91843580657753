import React from "react";
import { useNavigate } from "react-router-dom";
import { useRouting } from "../../hooks/routesHooks";
import { ROUTES } from "../../config/routesConfig";

import { useGetDealsQuery } from "../../services/dataService";

import PromotionCarousel from "../../components/carousels/PromotionCarousel";
import DealsDetailsCard from "../../components/cards/DealsDetailsCard";
import DealsImageCard from "../../components/cards/DealsImageCard";

import "./kasiBenefits.scss";

export default function KasiBenefitsPage() {
    const navigate = useNavigate();
    const { getRouteLink } = useRouting();

    const { data: allDeals } = useGetDealsQuery();

    const promotions = allDeals?.filter((item) => item.is_featured);
    const dealsWithTitle = allDeals?.filter((item) => item.name !== false);
    const dealsWithoutTitle = allDeals?.filter((item) => item.name === false);

    const handleNavigateToProductsPage = (categoryId) => {
        navigate({
            pathname: getRouteLink(ROUTES.PRODUCTS),
            search: `?category_id=${categoryId}`,
        });
    };

    return (
        <div className="kasi-benefits-page">
            {promotions?.length > 0 && (
                <PromotionCarousel promotions={promotions} showBackground />
            )}
            <div className="p-5 mx-5">
                {allDeals?.length ? (
                    <>
                        {dealsWithTitle?.length > 0 && (
                            <>
                                <div className="page-title mb-4">
                                    Kasi deals
                                </div>
                                <div className="page-sub-title mb-3">
                                    More top offers for you
                                </div>
                                <div className="deals-container pb-5">
                                    {allDeals
                                        .filter((item) => item.name !== false)
                                        .map((item) => (
                                            <div
                                                className="col-12 col-md-5"
                                                key={item.id}
                                            >
                                                <DealsDetailsCard
                                                    image={item.image}
                                                    title={item.name}
                                                    startDate={item.start_date}
                                                    endDate={item.end_date}
                                                    onClick={() =>
                                                        handleNavigateToProductsPage(
                                                            item.category_id[0]
                                                                ? item
                                                                      .category_id[0]
                                                                : ""
                                                        )
                                                    }
                                                />
                                            </div>
                                        ))}
                                </div>
                            </>
                        )}
                        {dealsWithoutTitle?.length > 0 && (
                            <>
                                <div className="page-title my-5">
                                    Best deals for you
                                </div>
                                <div className="deals-container">
                                    {allDeals
                                        .filter((item) => item.name === false)
                                        .map((item) => (
                                            <div
                                                className="col-12 col-md-5"
                                                key={item.id}
                                            >
                                                <DealsImageCard
                                                    image={item.image}
                                                    startDate={item.start_date}
                                                    endDate={item.end_date}
                                                    onClick={() =>
                                                        handleNavigateToProductsPage(
                                                            item.category_id[0]
                                                                ? item
                                                                      .category_id[0]
                                                                : ""
                                                        )
                                                    }
                                                />
                                            </div>
                                        ))}
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <div className="page-title mb-4">Kasi deals</div>
                        <div className="d-flex align-items-center justify-content-center w-100">
                            <div>
                                <div className="alert alert-primary px-5">
                                    Coming soon...
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
