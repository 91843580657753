import React, { useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../hooks/modalsHooks";
import { useRouting } from "../../../hooks/routesHooks";
import { ROUTES } from "../../../config/routesConfig";

import { useUpdateLoginMutation } from "../../../services/profileService";
import { Form, PasswordField, SubmitButton, TextField } from "../../forms";

import "./updatePhoneNumberModal.scss";

const UpdatePhoneNumberFormSchema = Yup.object().shape({
    password: Yup.string().required("Required"),
});

export default function UpdatePhoneNumberModal({ mobile }) {
    const { hideModal } = useModal();
    const navigate = useNavigate();
    const { getRouteLink } = useRouting();

    const [updateLoginError, setUpdateLoginError] = useState("");

    const [updateLogin] = useUpdateLoginMutation();

    const handleUpdatePhoneNumber = async (values) => {
        try {
            const response = await updateLogin(values);
            if (response?.data?.data && response?.data?.status === 200) {
                hideModal();
                navigate(getRouteLink(ROUTES.PROFILE_LOGIN_AND_SECURITY));
            } else {
                setUpdateLoginError(response?.data?.data?.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleModalClose = (e) => {
        e.stopPropagation();
        hideModal();
    };

    return (
        <div className="update-phone-number-container">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 offset-md-1">
                        <div className="content-container px-5">
                            <div className="d-flex flex-column justify-content-center h-100 px-4">
                                <h1>Enter Password</h1>
                                <div>
                                    <Form
                                        onSubmit={handleUpdatePhoneNumber}
                                        initialValues={{
                                            login: mobile,
                                            password: "",
                                        }}
                                        validationSchema={
                                            UpdatePhoneNumberFormSchema
                                        }
                                    >
                                        <TextField
                                            name="login"
                                            label="Mobile Number"
                                            disabled
                                        />
                                        <div className="mt-2">
                                            <PasswordField
                                                name="password"
                                                label="Password"
                                                placeholder="Enter your password"
                                            />
                                        </div>
                                        {updateLoginError && (
                                            <div className="alert alert-primary text-center mt-3">
                                                {updateLoginError}
                                            </div>
                                        )}
                                        <div className="mt-5">
                                            <SubmitButton>Done</SubmitButton>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <button
                                className="close-btn"
                                onClick={handleModalClose}
                            >
                                <img
                                    src="/vectors/kasi-close-circle-red-icon.svg"
                                    className="close-btn-img"
                                    alt=""
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
