import React from "react";

import "./banners.scss";

export default function HomeBanner({ image, title, tagLine }) {
    return (
        <div className="home-banner-wrapper d-flex align-items-center">
            <div className="col-6 col-lg-7 text-white text-center">
                <div className="title mb-3">{title}</div>
                <div className="tag-line ">{tagLine}</div>
            </div>
            <div className="col-6 col-lg-5 h-100">
                <img className="banner-image" src={image} alt="" />
            </div>
        </div>
    );
}
