import React from "react";
import { useField } from "formik";
import CreatableSelect from "react-select/creatable";

import "../forms.scss";

export default function EditableSelect({
    name,
    label = "",
    disabled = false,
    options,
    fallbackOptionLabel = "Choose...",
    onChange,
    ...rest
}) {
    const [field, meta, helpers] = useField({ name, label, ...rest });

    const customStyles = {
        dropdownIndicator: (styles, state) => ({
            ...styles,
            padding: 0,
            backgroundImage: state.isFocused
                ? "url('/vectors/kasi-chevron-down-blue-bg.svg')"
                : "url('/vectors/kasi-chevron-right-blue-bg.svg')",
            backgroundSize: "1.75rem",
            backgroundPositionY: "center",
            backgroundPositionX: "center",
        }),
        container: (styles) => ({
            ...styles,
            width: "100%",
        }),
        control: () => ({
            padding: 8,
            paddingLeft: 14,
            paddingRight: 4,
            display: "flex",
        }),
        placeholder: (styles) => ({
            ...styles,
            color: "#828282",
            whiteSpace: "nowrap",
        }),
        valueContainer: (styles) => ({
            ...styles,
            padding: 0,
        }),
        singleValue: (styles, state) => ({
            ...styles,
            color: state.isDisabled ? "#212529" : "#212529",
        }),
        input: (styles) => ({
            ...styles,
            paddingLeft: 4,
            paddingTop: 0,
            paddingBottom: 0,
            margin: 0,
        }),
        menu: (styles) => ({
            ...styles,
            width: "100%",
        }),
        option: (styles, state) => ({
            ...styles,
            padding: 4,
            paddingLeft: 8,
            fontWeight: 500,
            color: state.isFocused
                ? "#ffffff"
                : state.isSelected
                ? "#ffd26a"
                : "#000000",
            backgroundColor: state.isFocused && "#4f92f7",
        }),
    };

    const handleFieldChange = (e) => {
        helpers.setValue(e.value);
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <div className="formfield-wrapper">
            <div
                className={`formfield-root formfield-select ${
                    meta.touched && meta.error ? "invalid" : ""
                }`}
            >
                {label && (
                    <label htmlFor={name} className="formfield-label">
                        {label}
                    </label>
                )}
                <CreatableSelect
                    options={options}
                    name={name}
                    defaultValue={
                        field.value && {
                            label: field.value,
                            value: field.value,
                        }
                    }
                    isDisabled={disabled}
                    onChange={handleFieldChange}
                    classNamePrefix="react-select"
                    styles={customStyles}
                    components={{
                        IndicatorSeparator: () => null,
                    }}
                    placeholder={fallbackOptionLabel}
                    formatCreateLabel={(value) => `Add "${value}"`}
                    menuPlacement="auto"
                    menuPosition="fixed"
                />
            </div>
            <div
                hidden={!(meta.touched && meta.error)}
                className="formfield-error"
            >
                {meta.error}
            </div>
        </div>
    );
}
