import React from "react";
import * as Yup from "yup";
import {
    useGetProfileQuery,
    useUpdateProfileMutation,
} from "../../services/profileService";
import { useGetBusinessTypesQuery } from "../../services/dataService";
import { getBusinessTypesOptions } from "../../utils/dataUtils";

import SectionTitle from "../../components/SectionTitle";
import { Form, SubmitButton } from "../../components/forms";
import {
    ViewableSelect,
    ViewableTextField,
} from "../../components/forms/ViewableField";
import Button from "../../components/Button";
import ProfileFormSkeleton from "../../components/skeletons/ProfileFormSkeleton";

const ProfileBusinessDetailsFormSchema = Yup.object().shape({
    business_type: Yup.string().required("Required"),
});

export default function ProfileBusinessDetailsPage() {
    const inputRef = React.createRef();
    const [isEditing, setIsEditing] = React.useState(false);
    const [updateError, setUpdateError] = React.useState("");

    const { data: businessTypes } = useGetBusinessTypesQuery();
    const { data: profileData, isFetching: isProfileLoading } =
        useGetProfileQuery();

    const [updateProfile] = useUpdateProfileMutation();

    React.useEffect(() => {
        // Focus or blur text field based on editing state
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
        }
        if (!isEditing && inputRef.current) {
            inputRef.current.blur();
        }
    }, [isEditing]);

    const handleEditClick = () => {
        setIsEditing((prevState) => !prevState);
    };

    const handleProfileBusinessDetailsSubmit = async (values) => {
        try {
            const response = await updateProfile({
                business_type: values.business_type,
                company_registry: values.company_registry,
                vat: values.vat,
            });
            if (response?.data?.data && response?.data?.status === 200) {
                handleEditClick();
                setUpdateError("");
            } else {
                setUpdateError(response?.data?.data?.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const initialFormData = {
        company_name: profileData?.company_name || "",
        business_type: profileData?.business_type || "",
        company_registry: profileData?.business_registration_number || "",
        vat: profileData?.vat || "",
    };

    return (
        <div className="profile-sub-page-container py-5">
            <div className="mb-5">
                <SectionTitle text="Business Details" />
            </div>
            {isProfileLoading ? (
                <ProfileFormSkeleton />
            ) : (
                <Form
                    initialValues={initialFormData}
                    onSubmit={handleProfileBusinessDetailsSubmit}
                    validationSchema={ProfileBusinessDetailsFormSchema}
                >
                    <div className="row mb-5">
                        <div className="col">
                            <ViewableTextField
                                name="company_name"
                                label="Name of the Store :"
                                placeholder="Enter your store name"
                            />
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-6">
                            <ViewableSelect
                                name="business_type"
                                label="Business Type :"
                                placeholder="Select your business type"
                                options={
                                    businessTypes
                                        ? getBusinessTypesOptions(businessTypes)
                                        : []
                                }
                                isEditing={isEditing}
                            />
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col">
                            <ViewableTextField
                                ref={inputRef}
                                name="company_registry"
                                label="Business Regsitration No (As per CIPC Document) :"
                                placeholder="Business regsitration no"
                                isEditing={isEditing}
                            />
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col">
                            <ViewableTextField
                                name="vat"
                                label="VAT number :"
                                placeholder="Enter your vat number"
                                isEditing={isEditing}
                            />
                        </div>
                    </div>
                    {updateError && (
                        <div className="alert alert-primary text-center mt-3">
                            {updateError}
                        </div>
                    )}
                    <div className="row justify-content-end mt-5">
                        <div className="col-sm-6 col-md-5">
                            {isEditing ? (
                                <SubmitButton>Save changes</SubmitButton>
                            ) : (
                                <Button onClick={handleEditClick}>
                                    Edit Business details
                                </Button>
                            )}
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );
}
