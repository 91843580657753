import React from "react";
import LocationPickerField from "../LocationPickerField";
import "./viewableField.scss";

export default function ViewableLocationPickerField({
    name,
    label,
    value,
    actionTitle,
    isEditing,
    onClick,
}) {
    const isTextAvailable = !!value;
    const actionBtnLabel = !!value ? "Change" : "Add";

    const handleOnClick = (e) => {
        e.stopPropagation();
        if (onClick) {
            onClick();
        }
    };

    return (
        <div
            className={`viewable-field ${
                !isTextAvailable ? "align-items-center" : ""
            }`}
        >
            <div
                className={`viewable-field-content ${
                    !actionTitle ? "w-100" : ""
                }`}
            >
                {label && (
                    <label htmlFor={name} className="viewable-field-label">
                        {label}
                    </label>
                )}
                {isTextAvailable && (
                    <LocationPickerField
                        name={name}
                        value={value}
                        disabled={!isEditing}
                    />
                )}
            </div>
            <div className="viewable-field-actions-container ms-sm-3 mt-3 mt-sm-0">
                {actionTitle && (
                    <button
                        type="button"
                        className="viewable-field-end-btn"
                        onClick={handleOnClick}
                        disabled={!isEditing}
                    >
                        {actionBtnLabel} {actionTitle}
                    </button>
                )}
            </div>
        </div>
    );
}
