import { createApi } from '@reduxjs/toolkit/query/react';
import { publicBaseQuery } from "./baseQueries";

export const cartAPI = createApi({
    reducerPath: 'cartAPI',
    baseQuery: publicBaseQuery,
    tagTypes: ['Cart'],
    endpoints: (build) => ({
        getCart: build.query({
            query: ({ withCredentials, cartData }) => ({
                url: '/api/cart/',
                method: 'POST',
                body: {
                    params: {
                        method: 'GET',
                        ...cartData
                    },
                },
                credentials: withCredentials ? "include" : "omit"

            }),
            providesTags: ['Cart'],
            transformResponse: (response) => response?.result?.response[0],
        }),
        addCart: build.mutation({
            query: ({ withCredentials, cartData }) => ({
                url: '/api/cart/',
                method: 'POST',
                body: {
                    params: {
                        method: 'POST',
                        ...cartData
                    },
                },
                credentials: withCredentials ? "include" : "omit"
            }),
            invalidatesTags: ['Cart'],
            transformResponse: (response) => ({
                data: response?.result?.response,
                status: response?.result?.status,
            })
        }),
        updateCart: build.mutation({
            query: ({ withCredentials, cartData }) => ({
                url: '/api/cart/',
                method: 'PUT',
                body: {
                    params: {
                        method: 'PUT',
                        ...cartData
                    }
                },
                credentials: withCredentials ? "include" : "omit"
            }),
            invalidatesTags: ['Cart'],
            transformResponse: (response) => ({
                data: response?.result?.response,
                status: response?.result?.status,
            })
        }),
        deleteCart: build.mutation({
            query: ({ withCredentials, cartData }) => ({
                url: '/api/cart/',
                method: 'DELETE',
                body: {
                    params: {
                        method: 'DELETE',
                        ...cartData
                    }
                },
                credentials: withCredentials ? "include" : "omit"
            }),
            invalidatesTags: ['Cart'],
            transformResponse: (response) => ({
                data: response?.result?.response,
                status: response?.result?.status,
            })
        })
    }),
});

export const {
    useGetCartQuery,
    useAddCartMutation,
    useUpdateCartMutation,
    useDeleteCartMutation
} = cartAPI;
