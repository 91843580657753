import React from "react";
import dayjs from "dayjs";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

import { parseCurrencyValue } from "../../utils/currencyUtils";
import { paymentTypeOptions } from "../../constants/paymentTypes";

export default function InvoiceReceipt({ invoice }) {
    const paymentMethod = paymentTypeOptions.find(
        (method) => method?.value === invoice?.payment_method
    );

    const styles = StyleSheet.create({
        invoiceContainer: {
            paddingLeft: 50,
            paddingRight: 50,
            marginTop: 60,
        },
        invoiceTitle: {
            textAlign: "center",
        },
        invoiceSubTitle: {
            color: "#969696",
            marginBottom: 10,
        },
        invoiceValue: {
            marginBottom: 10,
        },
        invoiceTableContainer: {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: 14,
        },
        invoiceFooter: {
            fontSize: 12,
            marginTop: 20,
        },
        breakLine: {
            borderTopWidth: 1,
            borderTopStyle: "solid",
            borderTopColor: "#E0E0E0",
            marginTop: 20,
            marginBottom: 20,
        },
    });

    return (
        <Document>
            <Page>
                <View style={styles.invoiceContainer}>
                    <Text style={styles.invoiceTitle}>
                        Reference Number: {invoice?.name}
                    </Text>
                    <View style={styles.breakLine} />
                    <View style={styles.invoiceTableContainer}>
                        <View>
                            <Text style={styles.invoiceSubTitle}>
                                Invoice Date:
                            </Text>
                            <Text style={styles.invoiceSubTitle}>
                                Due Date:
                            </Text>
                            <Text style={styles.invoiceSubTitle}>Source:</Text>
                            <Text style={styles.invoiceSubTitle}>
                                Payment Method:
                            </Text>
                        </View>
                        <View>
                            <Text style={styles.invoiceValue}>
                                {dayjs(invoice?.invoice_date).format(
                                    "MMM D, YYYY"
                                )}
                            </Text>
                            <Text style={styles.invoiceValue}>
                                {dayjs(invoice?.invoice_date_due).format(
                                    "MMM D, YYYY"
                                )}
                            </Text>
                            <Text style={styles.invoiceValue}>
                                {invoice?.invoice_origin}
                            </Text>
                            <Text style={styles.invoiceValue}>
                                {paymentMethod?.label || "Not Provided"}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.breakLine} />
                    <View style={styles.invoiceTableContainer}>
                        <Text style={styles.invoiceSubTitle}>Amount: </Text>
                        <Text style={styles.invoiceValue}>
                            {parseCurrencyValue(
                                invoice?.amount_total,
                                false,
                                invoice?.currency_id[1]
                            )}
                        </Text>
                    </View>
                    <Text style={styles.invoiceFooter}>
                        Please use the following communication for your
                        payment:&nbsp;
                        {invoice?.name}
                    </Text>
                </View>
            </Page>
        </Document>
    );
}
