import React from "react";
import Slider from "react-slick";
import { HomeBanner } from "../banners";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.scss";

export default function BannerCarousel() {
    const homeBannerData = [
        {
            id: 1,
            image: "/vectors/kasi-landing-banner-1.svg",
            title: (
                <>
                    Buy hassle free <br /> shop online
                </>
            ),
            tagLine: "Place your orders through App, WhatsApp or Website.",
        },
        {
            id: 2,
            image: "/vectors/kasi-landing-banner-2.svg",
            title: (
                <>
                    Same day <br /> door step delivery
                </>
            ),
            tagLine: "Get your order delivered to your store.",
        },
    ];

    return (
        <div className="d-flex flex-column align-items-center">
            <div className="mw-100">
                <div className="banner-container">
                    <Slider
                        initialSlide={0}
                        arrows
                        slidesToShow={1}
                        slidesToScroll={1}
                        infinite={true}
                        autoplay={true}
                        speed={1000}
                        autoplaySpeed={3000}
                        cssEase="linear"
                    >
                        {homeBannerData.map((item) => (
                            <HomeBanner
                                key={item.id}
                                image={item.image}
                                title={item.title}
                                tagLine={item.tagLine}
                            />
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}
