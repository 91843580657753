import React from "react";
import _ from "lodash";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import { ROUTES } from "../config/routesConfig";
import { useRouting } from "../hooks/routesHooks";
import { useLoginStatus } from "../hooks/authHooks";
import { useCart } from "../hooks/cartHooks";

import { useGetProfileQuery } from "../services/profileService";

import ProductFilter from "./ProductFilter/ProductFilter";

import "./navbar.scss";

export default function Navbar() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { getRouteLink } = useRouting();
    const isLoggedIn = useLoginStatus();
    const { cartItems } = useCart();

    const [searchParams, setSearchParams] = useSearchParams();

    const searchText = searchParams.get("search");

    const { data: profileData } = useGetProfileQuery({}, { skip: !isLoggedIn });

    const handleNavigateToCartSummary = () => {
        navigate(getRouteLink(ROUTES.CART_SUMMARY));
    };

    const handleNavigateToMyAccount = () => {
        navigate(getRouteLink(ROUTES.MY_ACCOUNT));
    };

    const handleOnSearchTextChange = React.useCallback(
        _.debounce((e) => {
            const searchText = e.target.value;
            if (e.target.value) {
                searchParams.set("search", searchText);
                if (pathname === getRouteLink(ROUTES.PRODUCTS)) {
                    setSearchParams(searchParams);
                } else {
                    navigate({
                        pathname: getRouteLink(ROUTES.PRODUCTS),
                        search: `?search=${searchText}`,
                    });
                }
            } else {
                searchParams.delete("search");
                setSearchParams(searchParams);
            }
        }, 1000),
        [searchParams]
    );

    const renderSearchText = searchText || "";

    const showCartIcon =
        (pathname !== getRouteLink(ROUTES.CHECKOUT) &&
            pathname !== getRouteLink(ROUTES.LANDING)) ||
        isLoggedIn;

    const showProductFilters = pathname === getRouteLink(ROUTES.PRODUCTS);

    return (
        <header className="navbar-wrapper">
            <nav className="navbar navbar-expand-lg navbar-light bg-white px-lg-4">
                <div className="container-fluid">
                    <Link
                        className="navbar-brand col-3 col-lg-1 me-0 py-0"
                        to={getRouteLink(ROUTES.LANDING)}
                    >
                        <div className="brand-logo-wrapper">
                            <img
                                src="/images/kasi-logo.png"
                                alt="Kasi Logo"
                                className="brand-logo"
                            />
                        </div>
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item mx-3">
                                <Link
                                    className="nav-link"
                                    aria-current="page"
                                    to={getRouteLink(ROUTES.LANDING)}
                                >
                                    HOME
                                </Link>
                            </li>
                            {/* <li className="nav-item mx-3">
                                <Link
                                    className="nav-link"
                                    aria-current="page"
                                    to="#"
                                >
                                    ABOUT
                                </Link>
                            </li> */}
                            <li className="nav-item mx-3">
                                <Link
                                    className="nav-link"
                                    aria-current="page"
                                    to="/contact"
                                >
                                    CONTACT
                                </Link>
                            </li>
                            {/* Carees & Press temporarily removed */}
                            {/* <li className="nav-item mx-3">
                                <Link
                                    className="nav-link"
                                    aria-current="page"
                                    to="#"
                                >
                                    CAREERS
                                </Link>
                            </li>
                            <li className="nav-item mx-3">
                                <Link
                                    className="nav-link"
                                    aria-current="page"
                                    to="#"
                                >
                                    PRESS
                                </Link>
                            </li> */}
                        </ul>
                        <div className="navbar-search-container mx-3 mb-3 mb-lg-0">
                            <img
                                src="/vectors/kasi-search-icon.svg"
                                alt=" "
                                className="navbar-search-start-icon"
                            />
                            <input
                                className="navbar-search-input"
                                type="search"
                                name="search"
                                placeholder="Search"
                                defaultValue={renderSearchText}
                                onChange={handleOnSearchTextChange}
                                aria-label="Search"
                            />
                        </div>
                        <ul className="navbar-nav mb-0">
                            {isLoggedIn && (
                                <li className="nav-item mx-lg-2 mx-3 mb-3 mb-lg-0">
                                    <div
                                        role="button"
                                        className="my-account-nav"
                                        onClick={handleNavigateToMyAccount}
                                    >
                                        <div className="greeting">
                                            Hello, {profileData?.name}
                                        </div>
                                        <div className="d-block mt-1">
                                            Accounts & Lists
                                        </div>
                                    </div>
                                </li>
                            )}
                            {showCartIcon && (
                                <li className="nav-item mx-lg-2 mx-3 mb-3 mb-lg-0">
                                    <button
                                        className="nav-btn-cart"
                                        onClick={handleNavigateToCartSummary}
                                    >
                                        <div className="cart-img">
                                            <img
                                                src="/vectors/kasi-shopping-bag-icon.svg"
                                                alt=""
                                                width="100%"
                                                height="100%"
                                            />
                                            {!!cartItems?.order_line
                                                ?.length && (
                                                <div className="cart-badge">
                                                    {cartItems.cart_quantity}
                                                </div>
                                            )}
                                            <div className="cart-text">
                                                Cart
                                            </div>
                                        </div>
                                    </button>
                                </li>
                            )}
                            {!isLoggedIn && (
                                <>
                                    <li className="nav-item mx-lg-2 mx-3 mb-3 mb-lg-0">
                                        <Link
                                            className="nav-link btn btn-primary px-3"
                                            aria-current="page"
                                            to={getRouteLink(ROUTES.REGISTER)}
                                        >
                                            SIGN UP
                                        </Link>
                                    </li>
                                    <li className="nav-item mx-lg-2 mx-3 mb-2 mb-lg-0">
                                        <Link
                                            className="nav-link btn btn-primary px-3"
                                            aria-current="page"
                                            to={getRouteLink(ROUTES.SIGNIN)}
                                        >
                                            LOG IN
                                        </Link>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
            {showProductFilters && <ProductFilter />}
        </header>
    );
}
