import React from "react";

export default function FilterMenuOption({
    name,
    label,
    initialValue = false,
    containerClass,
    onClick,
}) {
    return (
        <div
            className={`d-flex align-items-center p-2 menu-option ${containerClass} `}
        >
            <input
                name={name}
                id={name}
                type="checkbox"
                checked={initialValue}
                value={initialValue}
                className="option-input me-2"
                onChange={onClick}
            />
            <label className="option-label" htmlFor={name}>
                {label}
            </label>
        </div>
    );
}
