import React from "react";
import { useField } from "formik";

import "../forms.scss";

/**
 * Native `text` input wrapper with Formik integration.
 * @param {{
 * name: string,
 * label: string,
 * placeholder?: string,
 * type?: string,
 * disabled?: boolean
 * }} props
 * @returns {React.FunctionComponent}
 */
const TextField = React.forwardRef(
    (
        {
            name,
            label,
            type = "text",
            placeholder,
            endButtonTitle = "",
            onEndButtonClick,
            ...rest
        },
        ref
    ) => {
        const [field, meta] = useField({
            name,
            label,
            type,
            placeholder,
            ...rest,
        });

        const handleOnEndButtonClick = (e) => {
            e.stopPropagation();
            if (onEndButtonClick) {
                onEndButtonClick();
            }
        };

        return (
            <div className="formfield-wrapper">
                <div
                    className={`formfield-root ${
                        meta.touched && meta.error ? "invalid" : ""
                    }`}
                >
                    {label && (
                        <label htmlFor={name} className="formfield-label">
                            {label}
                        </label>
                    )}
                    <input
                        className="formfield-input"
                        placeholder={
                            meta.touched && meta.error ? "" : placeholder || ""
                        }
                        type={type}
                        ref={ref}
                        {...field}
                        {...rest}
                    />
                    {endButtonTitle && (
                        <button
                            type="button"
                            className="formfield-end-btn"
                            onClick={handleOnEndButtonClick}
                        >
                            {endButtonTitle}
                        </button>
                    )}
                </div>
                <div
                    hidden={!(meta.touched && meta.error)}
                    className="formfield-error"
                >
                    {meta.error}
                </div>
            </div>
        );
    }
);

export default TextField;
