import React from "react";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";

import FilterMenu from "./FilterMenu";
import FilterMenuOption from "./FilterMenuOption";

import { transformFilterParams } from "../../utils/dataUtils";
import {
    productFilterPriceOptions,
    productSortOptions,
} from "../../utils/productUtils";
import { useGetCategoriesQuery } from "../../services/categoryService";
import { useGetBrandsQuery } from "../../services/brandService";

import "./productFilter.scss";

export default function ProductFilter() {
    const [filterParams, setFilterParams] = useSearchParams();

    const category_id = transformFilterParams(filterParams.get("category_id"));
    const brand_id = transformFilterParams(filterParams.get("brand_id"));
    const price = transformFilterParams(filterParams.get("price"));
    const offers = transformFilterParams(filterParams.get("offers"));
    const min = transformFilterParams(filterParams.get("min"));
    const max = transformFilterParams(filterParams.get("max"));

    const { data: allCategories } = useGetCategoriesQuery();
    const { data: allBrands } = useGetBrandsQuery();

    const initialFilters = {
        category_id,
        brand_id,
        price,
        offers,
        min,
        max,
    };

    const handleSelectFilter = (e, value, type) => {
        if (e.target.checked) {
            const oldFilters = initialFilters[type] ? initialFilters[type] : [];
            let newFilters = [...oldFilters, value];
            if (type === "price") {
                newFilters = [value];
            }
            initialFilters[type] = newFilters;
            filterParams.set(type, [newFilters]);

            if (initialFilters[type].length === 0) {
                filterParams.delete(type);
            }
            setFilterParams(filterParams);
        } else {
            const oldFilters = initialFilters[type] ? initialFilters[type] : [];
            oldFilters.splice(oldFilters.indexOf(value), 1);
            initialFilters[type] = oldFilters;
            filterParams.set(type, [oldFilters]);

            if (initialFilters[type].length === 0) {
                filterParams.delete(type);
            }
            setFilterParams(filterParams);
        }
    };

    const handleMinPriceRangeFilterChange = React.useCallback(
        _.debounce((e) => {
            const min = e.target.value;
            if (min) {
                const newFilters = [min];
                initialFilters["min"] = newFilters;
                filterParams.set("min", [newFilters]);
            } else {
                filterParams.delete("min");
            }
            setFilterParams(filterParams);
        }, 1000),
        [filterParams]
    );

    const handleMaxPriceRangeFilterChange = React.useCallback(
        _.debounce((e) => {
            const max = e.target.value;
            if (max) {
                const newFilters = [max];
                initialFilters["max"] = newFilters;
                filterParams.set("max", [newFilters]);
            } else {
                filterParams.delete("max");
            }
            setFilterParams(filterParams);
        }, 1000),
        [filterParams]
    );

    const handleSortProducts = ({ name }) => {
        const sortParams = filterParams.get("sort");
        if (sortParams === name) {
            filterParams.delete("sort");
        } else {
            filterParams.set("sort", name);
        }
        setFilterParams(filterParams);
    };

    const priceData = productFilterPriceOptions.map(({ id, label }) => ({
        id,
        name: label,
    }));

    const sortByData = productSortOptions.map(({ id, name }) => ({
        id,
        name,
    }));

    const offersData = [
        { id: 1, name: "Upto 20% off" },
        { id: 2, name: "daily deals" },
    ];

    return (
        <div className="product-filter-container py-3 px-5 bg-white">
            <div className="col-7">
                <div className="product-filter-wrapper">
                    <FilterMenu title="category type">
                        {allCategories?.map((category) => (
                            <FilterMenuOption
                                key={category.id}
                                name={category.name}
                                label={category.name}
                                initialValue={category_id?.includes(
                                    category.id.toString()
                                )}
                                containerClass="category-type"
                                onClick={(e) =>
                                    handleSelectFilter(
                                        e,
                                        category.id.toString(),
                                        "category_id"
                                    )
                                }
                            />
                        ))}
                    </FilterMenu>
                    <FilterMenu title="brand">
                        {allBrands?.map((brand) => (
                            <FilterMenuOption
                                key={brand.id}
                                name={brand.name}
                                label={brand.name}
                                initialValue={brand_id?.includes(
                                    brand.id.toString()
                                )}
                                containerClass="brand"
                                onClick={(e) =>
                                    handleSelectFilter(
                                        e,
                                        brand.id.toString(),
                                        "brand_id"
                                    )
                                }
                            />
                        ))}
                    </FilterMenu>
                    <FilterMenu title="order value">
                        {priceData &&
                            priceData.map((item) => (
                                <FilterMenuOption
                                    key={item.id}
                                    name={item.name}
                                    label={item.name}
                                    initialValue={price?.includes(item.name)}
                                    containerClass="order-value"
                                    onClick={(e) =>
                                        handleSelectFilter(
                                            e,
                                            item.name,
                                            "price"
                                        )
                                    }
                                />
                            ))}
                        <div className="px-2 pt-3 mb-4 ms-1">
                            <div className="min-max-wrapper">
                                <div className="min-max-input-wrapper">
                                    <input
                                        type="number"
                                        name="min"
                                        defaultValue={min}
                                        min={0}
                                        onChange={
                                            handleMinPriceRangeFilterChange
                                        }
                                        placeholder="Min"
                                        className="min-max-input py-2 px-3"
                                    />
                                </div>
                                <span className="mx-2">to</span>
                                <div className="min-max-input-wrapper">
                                    <input
                                        type="number"
                                        name="max"
                                        defaultValue={max}
                                        min={1}
                                        onChange={
                                            handleMaxPriceRangeFilterChange
                                        }
                                        placeholder="Max"
                                        className="min-max-input py-2 px-3"
                                    />
                                </div>
                            </div>
                        </div>
                    </FilterMenu>
                    <FilterMenu title="offers">
                        {offersData &&
                            offersData.map((item) => (
                                <FilterMenuOption
                                    key={item.id}
                                    name={item.name}
                                    label={item.name}
                                    initialValue={offers?.includes(item.name)}
                                    onClick={(e) =>
                                        handleSelectFilter(
                                            e,
                                            item.name,
                                            "offers"
                                        )
                                    }
                                />
                            ))}
                    </FilterMenu>
                </div>
            </div>
            <div className="col-3 ">
                <FilterMenu
                    title="sort by"
                    dropDownStyle="right-align"
                    wrapperStyle="sort-by-title"
                >
                    {sortByData &&
                        sortByData.map((item) => (
                            <div
                                role="button"
                                key={item.id}
                                className="sort-by-option py-2 px-3"
                                onClick={() => handleSortProducts(item)}
                            >
                                {item.name}
                            </div>
                        ))}
                </FilterMenu>
            </div>
        </div>
    );
}
