import React, { useState } from "react";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

import { useModal } from "../../hooks/modalsHooks";
import { useCart } from "../../hooks/cartHooks";
import { useLoginStatus } from "../../hooks/authHooks";
import { useRouting } from "../../hooks/routesHooks";
import { ROUTES } from "../../config/routesConfig";

import { parseCurrencyValue } from "../../utils/currencyUtils";

import {
    useAddOrderMutation,
    useAddPromoCodeMutation,
} from "../../services/orderService";
import { useGetProfileQuery } from "../../services/profileService";

import { Form, SubmitButton, TextField } from "../../components/forms";
import { paymentTypeOptions } from "../../constants/paymentTypes";

import "./checkoutPage.scss";

export default function CheckoutPage() {
    const navigate = useNavigate();
    const { pathname, state } = useLocation();
    const { getRouteLink } = useRouting();
    const { cartItems, refetchCart } = useCart();
    const { showModal } = useModal();
    const isLoggedIn = useLoginStatus();

    const [addPromoError, setAddPromoError] = useState("");
    const [addPromoSuccess, setAddPromoSuccess] = useState("");
    const [isPaymentMethodAvailable, setIsPaymentMethodAvailable] =
        useState(true);

    const [addOrder] = useAddOrderMutation();
    const [addPromoCode] = useAddPromoCodeMutation();

    const { data: profileData } = useGetProfileQuery({}, { skip: !isLoggedIn });

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
        state?.selectedPaymentMethod
    );

    const isPlaceOrderMode = pathname === getRouteLink(ROUTES.PLACE_ORDER);

    const handleOnPaymentMethodChange = (e) => {
        setSelectedPaymentMethod(e.target.value);
        setIsPaymentMethodAvailable(true);
    };

    const handleApplyPromo = async ({ promo_code }) => {
        try {
            if (promo_code !== "") {
                const response = await addPromoCode({
                    orderId: cartItems?.id,
                    params: { promo_code: promo_code },
                });

                if (response?.data?.status === 200) {
                    refetchCart();
                    setAddPromoError("");
                    setAddPromoSuccess(response?.data?.data);
                    setTimeout(() => {
                        setAddPromoSuccess("");
                    }, 2000);
                } else {
                    setAddPromoSuccess("");
                    setAddPromoError(response?.data?.data);
                    setTimeout(() => {
                        setAddPromoError("");
                    }, 2000);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getErrorMessage = (errorMessage, items) => {
        if (items?.length) {
            const formattedItems = items.map((item) => ({
                product: item.product_id[1],
                freeQty: item.free_qty_today,
            }));
            return (
                <>
                    <div className="mb-2">{errorMessage}</div>
                    <div className="d-flex justify-content-between mb-2">
                        <div>Products</div>
                        <div>Free Quantity</div>
                    </div>
                    {formattedItems.map((item, index) => (
                        <div
                            key={index}
                            className="d-flex justify-content-between"
                        >
                            <div>{item.product}</div>
                            <div>{item.freeQty}</div>
                        </div>
                    ))}
                </>
            );
        }

        return errorMessage;
    };

    const handleOnCheckout = async (value) => {
        if (isLoggedIn) {
            if (isPlaceOrderMode) {
                const orderData = {
                    order_id: cartItems?.id,
                    payment_method: selectedPaymentMethod,
                };

                const response = await addOrder(orderData);
                if (response?.data?.status === 200) {
                    refetchCart();
                    showModal({
                        modalType: "AlertModal",
                        modalProps: {
                            title: "Order Placed",
                            message:
                                "Successfully placed your order. You can view/track it in 'My Orders' inside your profile.",
                            actionTitle: "OK",
                        },
                    });
                    navigate(getRouteLink(ROUTES.LANDING));
                } else if (response?.data?.status === 400) {
                    showModal({
                        modalType: "AlertModal",
                        modalProps: {
                            title: "Something went wrong!",
                            message:
                                getErrorMessage(
                                    response?.data?.response,
                                    response?.data?.invalid_order_lines
                                ) ||
                                "Cannot place your order. Please try again later.",
                            actionTitle: "OK",
                        },
                    });
                }
            } else {
                if (!selectedPaymentMethod) {
                    setIsPaymentMethodAvailable(false);
                    window.scrollTo(0, 0);
                } else {
                    navigate(getRouteLink(ROUTES.PLACE_ORDER), {
                        state: {
                            selectedPaymentMethod,
                        },
                    });
                }
            }
        }
    };

    return (
        <div className="checkout-page-container p-5">
            <div className="title mb-4">
                {isPlaceOrderMode ? "Order Summary" : "Checkout"}
            </div>
            <hr className="mb-4" />
            <div className="payment-method-wrapper mx-md-5">
                {!isPlaceOrderMode && (
                    <div className="title mb-4">Select Payment Method</div>
                )}
                {isPlaceOrderMode && (
                    <div className="title mb-4">Payment Method</div>
                )}
                <div
                    className="payment-method-group"
                    onChange={handleOnPaymentMethodChange}
                >
                    {paymentTypeOptions.map(({ value, label }) => (
                        <div
                            key={value}
                            className={`radio-btn-wrapper ${
                                selectedPaymentMethod === value
                                    ? "selected"
                                    : ""
                            } px-4 py-3 me-2 me-sm-5`}
                        >
                            <input
                                type="radio"
                                name="payment_type"
                                id={value}
                                value={value}
                                defaultChecked={selectedPaymentMethod === value}
                                className="radio-btn"
                                disabled={isPlaceOrderMode}
                            />
                            <label htmlFor={value} className="radio-label">
                                {label}
                            </label>
                        </div>
                    ))}
                </div>
                {!isPaymentMethodAvailable && (
                    <div className="error-text mt-3">
                        Choose a payment method to proceed
                    </div>
                )}
            </div>

            <hr className="my-5" />

            <div className="shipping-details-container mx-md-5">
                {isLoggedIn && (
                    <>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="title">Address</div>
                        </div>
                        <div className="shipping-details">
                            <div className="shipping-name mb-2">
                                {profileData?.name}
                            </div>
                            {profileData?.address?.street && (
                                <div className="shipping-address-phone">
                                    Street: {profileData.address.street}
                                </div>
                            )}

                            <div className="shipping-address-phone">
                                {profileData?.address?.city &&
                                    `${profileData.address.city}, `}
                                {profileData?.address?.province &&
                                    profileData.address.province}
                            </div>

                            <div className="shipping-address-phone">
                                {profileData?.mobile}
                            </div>

                            {profileData?.address?.zip && (
                                <div className="shipping-address-phone">
                                    zip code - {profileData.address.zip}
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
            {!_.isEmpty(cartItems) && (
                <div className="shipping-forms-wrapper mx-md-5">
                    <Form
                        initialValues={{
                            promo_code: cartItems?.promo_code
                                ? cartItems?.promo_code
                                : "",
                            note: "",
                        }}
                        onSubmit={handleApplyPromo}
                        enableReinitialize
                    >
                        {!(isPlaceOrderMode && !cartItems?.promo_code) && (
                            <div className="promo-code-field">
                                <TextField
                                    name="promo_code"
                                    placeholder="Promo code"
                                    disabled={isPlaceOrderMode}
                                />

                                <SubmitButton
                                    className="btn btn-info promo-code-apply-btn px-5"
                                    disabled={isPlaceOrderMode}
                                >
                                    Apply
                                </SubmitButton>
                            </div>
                        )}

                        {/* Add a note temporarily removed */}
                        {/* <div className="add-note-field">
                            <TextField
                                name="note"
                                placeholder="Add a note"
                                disabled={isPlaceOrderMode}
                            />
                        </div> */}
                        {!isPlaceOrderMode && (
                            <div className="d-flex align-items-center justify-content-center w-100">
                                {addPromoError && (
                                    <div className="alert alert-primary text-center mt-3 px-5">
                                        {addPromoError}!
                                    </div>
                                )}
                                {addPromoSuccess && (
                                    <div className="alert alert-success text-center mt-3 px-5">
                                        {addPromoSuccess}!
                                    </div>
                                )}
                            </div>
                        )}
                    </Form>
                    <Form onSubmit={handleOnCheckout} initialValues={{}}>
                        <div className="d-flex justify-content-between align-items-center fw-semibold mt-5 mb-2">
                            <div>Sub-total</div>
                            <div>
                                {parseCurrencyValue(
                                    cartItems?.amount_untaxed,
                                    false,
                                    cartItems?.currency_id[1]
                                )}
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <div>VAT</div>
                            <div>
                                {parseCurrencyValue(
                                    cartItems?.amount_tax,
                                    false,
                                    cartItems?.currency_id[1]
                                )}
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <div className="delivery-title">delivery fee</div>
                            <div className="delivery-fee">
                                {parseCurrencyValue(
                                    cartItems?.amount_delivery,
                                    false,
                                    cartItems?.currency_id[1]
                                )}
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-5">
                            <div className="total-title">total</div>
                            <div className="total-price">
                                {parseCurrencyValue(
                                    cartItems?.amount_total,
                                    false,
                                    cartItems?.currency_id[1]
                                )}
                            </div>
                        </div>
                        <div className="checkout-btn-wrapper ms-auto">
                            <SubmitButton>
                                {isPlaceOrderMode
                                    ? `Place order ${parseCurrencyValue(
                                          cartItems?.amount_total,
                                          false,
                                          cartItems?.currency_id[1]
                                      )}`
                                    : "Checkout"}
                            </SubmitButton>
                        </div>
                    </Form>
                </div>
            )}
        </div>
    );
}
