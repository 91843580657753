import _ from "lodash";
import { useField } from "formik";
import React from "react";

import "./changeableNumberField.scss";

export function ChangeableNumber({
    onChange,
    value,
    disabled,
    min = 1,
    max,
}){
    const handleIncrement = (e) => {
        e.stopPropagation();
        const number = value || 0;
        if (_.isNumber(max)) {
            const result = number + 1;
            if (result <= max) {
                if (onChange) {
                    onChange(result);
                }
            }
        }
        if (!_.isNumber(max)) {
            if (onChange) {
                onChange(number + 1);
            }
        }
    };

    const handleDecrement = (e) => {
        e.stopPropagation();
        const number = value || 0;
        if (value > min) {
            if (onChange) {
                onChange(number - 1);
            }
        } else {
            if (onChange) {
                onChange(min);
            }
        }
    };
    return (
        <div className="changeable-number-field">
                <button
                    disabled={disabled}
                    type="button"
                    onClick={handleDecrement}
                    className="decrement-btn"
                >
                    -
                </button>
                <div className="display-number">{value || 0}</div>
                <button
                    disabled={disabled}
                    type="button"
                    onClick={handleIncrement}
                    className="increment-btn"
                >
                    +
                </button>
            </div>
    );
}


export function ChangeableNumberField({
    name,
    min = 1,
    max,
    onChange,
    disabled = false,
}) {
    const [field, meta, helpers] = useField(name);

    const handleFieldChange = (value) => {
        helpers.setValue(value);
        if (onChange) {
            onChange(value);
        }
    }

    return (
        <>
            <ChangeableNumber
                min={min}
                max={max}
                disabled={disabled}
                value={field.value}
                onChange={handleFieldChange}
            />
            <div
                hidden={!(meta.touched && meta.error)}
                className="formfield-error"
            >
                {meta.error}
            </div>
        </>
    );
}
