import React, { useState } from "react";
import _ from "lodash";
import Slider from "react-slick";
import { useLoginStatus } from "../../hooks/authHooks";
import { useCart } from "../../hooks/cartHooks";

import { ChangeableNumberField, Form, SubmitButton } from "../forms";
import VariantDropDown from "../VariantDropDown";
import DiscountDropDown from "../DiscountDropDown";
import PackagingDropDown from "../PackagingDropDown";

import { useAddCartMutation } from "../../services/cartService";

import { parseCurrencyValue } from "../../utils/currencyUtils";
import {
    getColorForVariant,
    getProductVariantPriceFormat,
    getAllAttributeOptionsForProductVariants,
    resolveProductAttributesWithIds,
} from "../../utils/productUtils";
import {
    getSanitizedContent,
    parseBase64ImgSrc,
    getDiscountsOptions,
} from "../../utils/dataUtils";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./productInformation.scss";

const fallbackImagePath = "/images/product_placeholder.png";

const SliderLeftArrow = ({ currentSlide, slideCount, children, ...props }) => (
    <img {...props} src="/vectors/kasi-chevron-left-blue.svg" alt="<" />
);

const SliderRightArrow = ({ currentSlide, slideCount, children, ...props }) => (
    <img {...props} src="/vectors/kasi-chevron-right-blue.svg" alt=">" />
);

export default function ProductInformation({
    activeVariantIdx,
    setActiveVariantIdx,
    productVariantList = [],
    discountList,
}) {
    const sliderRef = React.createRef();
    const isLoggedIn = useLoginStatus();
    const { cartItems, getPublicOrderId, setPublicOrderId } = useCart();

    const [viewMore, setViewMore] = useState(false);
    const [isVariantAvailable, setIsVariantAvailable] = useState(true);
    const [selectedDiscountIdx, setselectedDiscountIdx] = useState();
    const [selectedPackType, setSelectedPackType] = useState(null);

    const [addCart] = useAddCartMutation();

    const productVariant = productVariantList[activeVariantIdx];

    const initialDiscountIdx = discountList[0]?.id;

    const { listPrice, discountedPrice, discountPercentage } =
        getProductVariantPriceFormat(
            productVariant,
            selectedDiscountIdx ? selectedDiscountIdx : initialDiscountIdx
        );

    const productOfferPrice = productVariant?.currency_id[1]
        ? parseCurrencyValue(
              discountedPrice,
              false,
              productVariant?.currency_id[1]
          )
        : parseCurrencyValue(discountedPrice);

    const productPrice = productVariant?.currency_id[1]
        ? parseCurrencyValue(listPrice, false, productVariant?.currency_id[1])
        : parseCurrencyValue(listPrice);

    const maxAllowedOrderQty =
        productVariant?.pack_types?.length > 0
            ? selectedPackType
                ? productVariant?.free_qty /
                  productVariant?.pack_types?.find(
                      ({ id }) => id === selectedPackType
                  )?.qty
                : 0
            : productVariant?.free_qty || 0;
    const isProductInStock =
        productVariant?.pack_types?.length > 0
            ? selectedPackType
                ? !!(
                      productVariant?.free_qty /
                      productVariant?.pack_types?.find(
                          ({ id }) => id === selectedPackType
                      )?.qty
                  )
                : false
            : !!productVariant?.free_qty;

    const productDescription = getSanitizedContent(productVariant?.description);

    const attributes = resolveProductAttributesWithIds(productVariant);

    const publicOrderId = getPublicOrderId();

    const handleBeforeChangeSlide = (prevIdx, nextIdx) => {
        setActiveVariantIdx(nextIdx);
    };

    const handleMoveToSlide = (idx) => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(idx);
        }
    };

    const handleProductImageClick = (idx) => {
        setIsVariantAvailable(true);
        setActiveVariantIdx(idx);
    };

    const getDiscountPrice = () => {
        return parseCurrencyValue(listPrice - discountedPrice);
    };

    const handleToggleViewMore = () => {
        setViewMore(!viewMore);
    };

    const handleAddItemToCart = async (cartData) => {
        try {
            if (isLoggedIn) {
                await addCart({ cartData, withCredentials: true });
            } else {
                if (_.isEmpty(cartItems)) {
                    const response = await addCart({ cartData });
                    if (
                        response?.data?.status === 200 &&
                        response?.data?.data
                    ) {
                        const publicOrderId = response.data.data[0].id;
                        setPublicOrderId(publicOrderId);
                    }
                } else {
                    cartData.order_id = publicOrderId;
                    await addCart({ cartData });
                }
            }
            handleChangePackType(null);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCartSubmit = async ({ qty }) => {
        if (isProductInStock) {
            const quantity = Number(qty);
            let selectedProductData = {};
            if (productVariant?.pack_types?.length > 0 && selectedPackType) {
                selectedProductData = {
                    id: productVariant.id,
                    packaging_id: selectedPackType,
                    packaging_qty: quantity,
                };
            } else {
                selectedProductData = {
                    id: productVariant.id,
                    quantity: quantity,
                };
            }

            if (
                productVariant?.pricelist_items.length > 0 &&
                isDiscountSelected
            ) {
                const selectedPriceListId = selectedDiscountIdx
                    ? selectedDiscountIdx
                    : initialDiscountIdx;
                selectedProductData.pricelist_id = selectedPriceListId;
            }

            const cartData = {
                products: [selectedProductData],
            };
            await handleAddItemToCart(cartData);
        }
    };

    const handleFocusSliderImage = (selectedAttributes) => {
        let selectedAttributeIds = Object.keys(selectedAttributes).map(
            (key) => selectedAttributes[key]
        );
        if (productVariantList?.length) {
            const idx = productVariantList.findIndex(
                ({
                    product_template_attribute_value_ids: productAttributeIds,
                }) => {
                    if (productAttributeIds?.length) {
                        return productAttributeIds.every((id) => {
                            return selectedAttributeIds.includes(id);
                        });
                    }
                    return false;
                }
            );
            if (idx >= 0) {
                setActiveVariantIdx(idx);
                setIsVariantAvailable(true);
            } else {
                setIsVariantAvailable(false);
            }
        }
    };

    const handleChangeVariantOption = (e, attributeName) => {
        let selectedAttributes = Object.assign({}, attributes);
        selectedAttributes[attributeName] = Number(e.target.value);
        handleFocusSliderImage(selectedAttributes);
        handleChangePackType(null);
    };

    const handleChangeDiscountOption = (value) => {
        setselectedDiscountIdx(value);
    };

    const handleChangePackType = (type) => {
        setSelectedPackType(type);
    };

    React.useEffect(() => {
        setTimeout(() => {
            handleMoveToSlide(activeVariantIdx);
        }, 0);
    }, [activeVariantIdx, sliderRef]);

    const allAttributeOptions = productVariantList?.length
        ? getAllAttributeOptionsForProductVariants(productVariantList)
        : [];

    const allDiscountOptions = discountList?.length
        ? getDiscountsOptions(discountList)
        : [];

    const isDiscountSelected =
        productVariant?.pricelist_items.length &&
        productVariant?.pricelist_items.some((data) =>
            selectedDiscountIdx
                ? data.pricelist_id[0] === selectedDiscountIdx
                : data.pricelist_id[0] === initialDiscountIdx
        );

    const thumbnailList = productVariantList.map(
        ({ image_128: image, id }) => ({ image, id })
    );
    const imageList = thumbnailList.slice();

    return (
        <React.Fragment>
            <div className="w-md-50 w-100 px-lg-5">
                <div className="product-images-card">
                    <div className="product-image-thumbnails-wrapper d-none d-md-block">
                        <div className="product-image-thumbnails-container">
                            <div className="product-image-thumbnails-scroll-container">
                                {thumbnailList.map(({ image, id }, idx) => {
                                    const borderColor = getColorForVariant(
                                        productVariantList[idx]
                                    );
                                    return (
                                        <img
                                            role="button"
                                            key={id}
                                            src={
                                                parseBase64ImgSrc(image) ||
                                                fallbackImagePath
                                            }
                                            alt=" "
                                            style={{
                                                border:
                                                    idx === activeVariantIdx &&
                                                    borderColor
                                                        ? `1px solid ${borderColor}`
                                                        : undefined,
                                            }}
                                            className={`product-image-thumbnail ${
                                                idx === activeVariantIdx
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                handleProductImageClick(idx);
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="product-image-thumbnail-slider-wrapper">
                        <div className="product-image-thumbnail-slider-container">
                            <div className="product-image-thumbnail-slider p-4">
                                <Slider
                                    ref={sliderRef}
                                    beforeChange={handleBeforeChangeSlide}
                                    nextArrow={<SliderRightArrow />}
                                    prevArrow={<SliderLeftArrow />}
                                    arrows
                                >
                                    {imageList.map(({ image, id }) => (
                                        <div
                                            key={id}
                                            className="product-image-wrapper"
                                        >
                                            <img
                                                key={id}
                                                src={
                                                    parseBase64ImgSrc(image) ||
                                                    fallbackImagePath
                                                }
                                                alt=" "
                                                className={`product-image ${
                                                    !isVariantAvailable &&
                                                    "opacity-25"
                                                }`}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-md-50 w-100 mt-4 mt-md-0">
                <div className="product-info-container ms-md-5 ms-0 mb-2">
                    <div className="container-title">
                        <div className="product-title mb-3">
                            {productVariant?.name}
                        </div>
                        <div className="product-price ms-0 ms-md-5 mb-4">
                            {discountedPrice && isDiscountSelected
                                ? productOfferPrice
                                : productPrice}
                        </div>
                    </div>

                    <div
                        className={`product-description mb-1 ${
                            !viewMore ? "line-clamp" : ""
                        }`}
                    >
                        {productDescription}
                    </div>
                    {productDescription && (
                        <div
                            className="view-more mb-3"
                            role="button"
                            onClick={handleToggleViewMore}
                        >
                            {!viewMore ? "View more" : "View less"}
                        </div>
                    )}
                    <Form
                        initialValues={{ qty: 1 }}
                        onSubmit={handleCartSubmit}
                        enableReinitialize
                    >
                        <div className="supplier-name d-block mb-3">
                            Supplied by:&nbsp;
                            <span className="mb-0">
                                {/* TODO: integrate supplier name here */}
                                Kasi
                            </span>
                        </div>
                        <div className="variants-container mb-3">
                            {productVariant?.product_attribute_values &&
                                productVariant?.product_attribute_values.map(
                                    (variant, index) => {
                                        const attribute =
                                            variant.display_name.split(":")[0];
                                        return (
                                            <VariantDropDown
                                                key={index}
                                                variantAttribute={attribute}
                                                variantName={variant.name}
                                                variantDisplayName={
                                                    variant.display_name
                                                }
                                                options={
                                                    allAttributeOptions[
                                                        attribute
                                                    ]
                                                }
                                                onChange={
                                                    handleChangeVariantOption
                                                }
                                            />
                                        );
                                    }
                                )}
                        </div>
                        <div className="variants-container mb-3">
                            {productVariant?.pack_types?.length > 0 && (
                                <PackagingDropDown
                                    selectedPackage={selectedPackType}
                                    options={productVariant?.pack_types}
                                    onChange={handleChangePackType}
                                />
                            )}
                        </div>
                        {!isVariantAvailable && (
                            <div className="alert alert-primary text-center">
                                Unable to select. <br />
                                This combination is unavailable.
                            </div>
                        )}
                        <div
                            className={`stock-status mb-3 ${
                                isProductInStock ? "in-stock" : ""
                            }`}
                        >
                            {productVariant?.pack_types?.length > 0
                                ? selectedPackType
                                    ? isProductInStock
                                        ? "In Stock"
                                        : "Out of Stock"
                                    : "Select pack type to check available stock"
                                : isProductInStock
                                ? "In Stock"
                                : "Out of Stock"}
                        </div>
                        <div className="qty-container mb-3">
                            <div className="label d-inline-block">
                                {productVariant?.pack_types?.length > 0
                                    ? "Pack "
                                    : ""}
                                Qty:
                            </div>
                            <div className="d-inline-block">
                                <ChangeableNumberField
                                    name="qty"
                                    max={maxAllowedOrderQty}
                                />
                            </div>
                        </div>
                        <div className="d-block">
                            {!!discountedPrice && isDiscountSelected && (
                                <div className="d-flex align-items-center mb-4">
                                    <div className="product-original-price">
                                        {productPrice}
                                    </div>
                                    <div className="discount-percentage ms-3">
                                        Save:&nbsp;
                                        {getDiscountPrice()}&nbsp;(
                                        {discountPercentage})%
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            {discountList.length > 1 && (
                                <DiscountDropDown
                                    options={allDiscountOptions}
                                    value={
                                        selectedDiscountIdx
                                            ? selectedDiscountIdx
                                            : initialDiscountIdx
                                    }
                                    onChange={handleChangeDiscountOption}
                                />
                            )}
                        </div>
                        <SubmitButton
                            className={`add-to-cart-btn ${
                                !isProductInStock ? "opacity-75" : ""
                            }`}
                            disabled={!isProductInStock}
                        >
                            {productVariant?.pack_types?.length > 0
                                ? selectedPackType
                                    ? isProductInStock
                                        ? "Add to cart"
                                        : "Out of Stock"
                                    : "Select pack type to proceed"
                                : isProductInStock
                                ? "Add to cart"
                                : "Out of Stock"}
                        </SubmitButton>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
}
