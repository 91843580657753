import React from "react";
import { useField } from "formik";
import TextField from "../TextField/TextField";
import "./viewableField.scss";

const ViewableTextField = React.forwardRef(
    (
        {
            type = "text",
            placeholder = "",
            label = "",
            name = "",
            endButtonTitle = "",
            onEndButtonClick,
            isEditing,
            disabled,
        },
        ref
    ) => {
        const [field] = useField(name);

        const isTextAvailable = !!field.value || !!placeholder;
        const actionBtnLabel = !!field.value ? "Change" : "Add";

        const handleOnEndButtonClick = (e) => {
            e.stopPropagation();
            if (onEndButtonClick) {
                onEndButtonClick();
            }
        };

        return (
            <div
                className={`viewable-field ${
                    !isTextAvailable ? "align-items-center" : ""
                }`}
            >
                <div
                    className={`viewable-field-content ${
                        !endButtonTitle ? "w-100" : ""
                    }`}
                >
                    {label && (
                        <label htmlFor={name} className="viewable-field-label">
                            {label}
                        </label>
                    )}
                    {isTextAvailable && (
                        <TextField
                            type={type}
                            placeholder={placeholder}
                            name={name}
                            disabled={!isEditing || disabled}
                            ref={ref}
                        />
                    )}
                </div>
                <div className="viewable-field-actions-container ms-sm-3 mt-3 mt-sm-0">
                    {endButtonTitle && (
                        <button
                            type="button"
                            className="viewable-field-end-btn"
                            onClick={handleOnEndButtonClick}
                            disabled={!isEditing}
                        >
                            {actionBtnLabel} {endButtonTitle}
                        </button>
                    )}
                </div>
            </div>
        );
    }
);

export default ViewableTextField;
