const paymentTypes = {
    CASH: "CASH",
    SHOP_TO_SHOP: "SHOP_TO_SHOP",
    BANK_DEPOSIT: "BANK_DEPOSIT",
    EFT: "EFT",
};

const paymentTypeOptions = [
    { label: "Cash", value: paymentTypes.CASH },
    { label: "Shop to Shop", value: paymentTypes.SHOP_TO_SHOP },
    { label: "Cash deposit in Bank", value: paymentTypes.BANK_DEPOSIT },
    { label: "EFT", value: paymentTypes.EFT },
];

export { paymentTypes as default, paymentTypeOptions };