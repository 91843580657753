import React from "react";
import dayjs from "dayjs";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { useNavigate, useParams } from "react-router-dom";
import { useRouting } from "../../hooks/routesHooks";
import { ROUTES } from "../../config/routesConfig";

import { parseCurrencyValue } from "../../utils/currencyUtils";
import { useGetInvoiceQuery } from "../../services/invoiceService";
import { paymentStatusTypes } from "../../constants/paymentStatusTypes";
import { paymentTypeOptions } from "../../constants/paymentTypes";

import Button from "../../components/Button";
import InvoiceDetailSkeleton from "../../components/skeletons/InvoiceDetailSkeleton";
import InvoiceReceipt from "../../components/InvoiceReceipt";

import "./profilePages.scss";

export default function ProfileInvoiceDetailPage() {
    const navigate = useNavigate();
    const { getRouteLink } = useRouting();
    const { invoiceId: id } = useParams();

    const { data: invoiceData, isLoading: isInvoiceLoading } =
        useGetInvoiceQuery({ invoiceId: id });

    const invoiceDate =
        invoiceData?.invoice_date && dayjs(invoiceData?.invoice_date).isValid()
            ? dayjs(invoiceData?.invoice_date).format("MMM D, YYYY")
            : null;

    const paymentMethod = paymentTypeOptions.find(
        (method) => method?.value === invoiceData?.payment_method
    );

    const handleNavigateToMyInvoice = () => {
        navigate(getRouteLink(ROUTES.PROFILE_INVOICES));
    };

    return (
        <div className="order-detail-page col-md-9 py-5">
            {isInvoiceLoading ? (
                <InvoiceDetailSkeleton />
            ) : (
                <>
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-center">
                            <div className="payment-success-status flex-column gap-4">
                                <div className="checkmark-wrapper">
                                    <img
                                        src={
                                            invoiceData?.payment_state ===
                                            paymentStatusTypes.COMPLETE
                                                ? "/vectors/kasi-checkmark-circle-green-glow.svg"
                                                : "/vectors/kasi-checkmark-circle-yellow-glow.svg"
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="h6 ms-3 mb-0">
                                    {invoiceData?.payment_state ===
                                    paymentStatusTypes.COMPLETE
                                        ? "Payment Successful!"
                                        : "Payment Pending!"}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="order-table-container mt-5">
                        <div className="table-responsive w-100">
                            <table className="table table-borderless">
                                <tbody className="invoice-table-body">
                                    <tr>
                                        <td className="py-6">
                                            Reference Number
                                        </td>
                                        <td className="align-right py-6">
                                            {invoiceData?.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="py-6">Date</td>
                                        <td className="align-right py-6">
                                            {invoiceDate}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="py-6">Payment Method</td>
                                        <td className="align-right py-6">
                                            {paymentMethod?.label}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot className="table-footer invoice-table-footer border-bottom-0">
                                    <tr>
                                        <td className="py-3 text-black">
                                            Delivery fee
                                        </td>
                                        <td />
                                        <td />
                                        <td className="align-right text-black">
                                            {parseCurrencyValue(
                                                invoiceData?.amount_delivery,
                                                false,
                                                invoiceData?.currency_id[1]
                                            )}
                                        </td>
                                    </tr>
                                    <tr className="border-top border-dark">
                                        <td className="py-3 text-black">
                                            Grand Total
                                        </td>
                                        <td className="align-right text-black">
                                            {parseCurrencyValue(
                                                invoiceData?.amount_total,
                                                false,
                                                invoiceData?.currency_id[1]
                                            )}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-end my-5 pb-5">
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1 mb-2 mb-sm-0">
                                <Button onClick={handleNavigateToMyInvoice}>
                                    Back to My Invoice
                                </Button>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1 ms-md-3">
                                <PDFDownloadLink
                                    document={
                                        <InvoiceReceipt invoice={invoiceData} />
                                    }
                                    fileName={`Kasi_Invoice_${invoiceData?.name}`}
                                >
                                    {({ loading }) => (
                                        <Button isLoading={loading}>
                                            <img
                                                src="/vectors/kasi-cloud-download-icon.svg"
                                                alt=""
                                                className="me-2"
                                            />
                                            Get PDF Receipt
                                        </Button>
                                    )}
                                </PDFDownloadLink>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
