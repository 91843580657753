import React, { useMemo } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import Pagination from "../Pagination";

import "./paginatedTable.scss";

export default function PaginatedTable({
    data,
    columns,
    pageSize = 10,
    currentPage = 0,
    totalCount,
    pageCount,
    isSortable = false,
    isPaginated = false,
    startIndex = 1,
    endIndex = 12,
    isNext = true,
    isPrevious = true,
    onGoToPage,
    onGoToPreviousPage,
    onGoToNextPage,
}) {
    const tableColumns = useMemo(
        () =>
            columns.map((col) => ({
                ...col,
                Header: col.header,
            })),
        []
    );

    const tableData = useMemo(() => data, [data]);

    const pluginList = useMemo(() => {
        let list = [];

        if (isSortable) {
            list.push(useSortBy);
        }

        if (isPaginated) {
            list.push(usePagination);
        }

        return list;
    });

    const tableInstance = useTable(
        {
            columns: tableColumns,
            data: tableData,
            disableSortRemove: true,
            initialState: {
                pageSize: pageSize,
                pageIndex: currentPage,
            },
        },
        ...pluginList
    );

    const getHeaderProps = (tableInstance, column) => {
        return column.getHeaderProps(column.getSortByToggleProps());
    };

    const handleGotoPage = (e) => {
        if (onGoToPage) {
            onGoToPage(e);
        }
    };

    const handleGotoPreviousPage = () => {
        if (onGoToPreviousPage) {
            onGoToPreviousPage();
        }
    };

    const handleGotoNextPage = () => {
        if (onGoToNextPage) {
            onGoToNextPage();
        }
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
    } = tableInstance;

    return (
        <div className="paginated-table">
            <div className="table-responsive">
                <table className="table table-borderless" {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...getHeaderProps(
                                            tableInstance,
                                            column
                                        )}
                                        className="pt-4 pb-5"
                                    >
                                        {column.render("Header")}
                                        <span>
                                            {column.canSort ? (
                                                <img
                                                    src="/vectors/kasi-chevron-down-black.svg"
                                                    alt="down-arrow"
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                className="py-3"
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <Pagination
                startIndex={startIndex}
                endIndex={endIndex}
                currentPage={currentPage}
                totalCount={totalCount}
                pageCount={pageCount}
                isNext={isNext}
                isPrevious={isPrevious}
                onGoToPage={handleGotoPage}
                onGoToNext={handleGotoNextPage}
                onGoToPrevious={handleGotoPreviousPage}
            />
        </div>
    );
}
