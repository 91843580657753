import React from "react";
import * as Yup from "yup";
import { useModal } from "../../hooks/modalsHooks";
import Geocode from "react-geocode";

import {
    useGetProfileQuery,
    useUpdateProfileMutation,
} from "../../services/profileService";
import {
    useGetTownshipsQuery,
    useGetStatesQuery,
} from "../../services/dataService";
import { getTownshipsOptions } from "../../utils/dataUtils";

import SectionTitle from "../../components/SectionTitle";
import { Form, SubmitButton } from "../../components/forms";
import {
    ViewableEditableSelect,
    ViewableLocationPickerField,
    ViewableSelect,
    ViewableTextField,
} from "../../components/forms/ViewableField";
import Button from "../../components/Button";
import ProfileFormSkeleton from "../../components/skeletons/ProfileFormSkeleton";

Geocode.setApiKey(process.env.REACT_APP_GEOCODE_API_KEY);

const alphabetOnlyRegExp = /^[a-zA-Z '-]*$/;

const ProfileShippingAddressFormSchema = Yup.object().shape({
    location: Yup.string().required("Required"),
    addressLine1: Yup.string().required("Required"),
    city: Yup.string()
        .matches(alphabetOnlyRegExp, "Only alphabets are allowed")
        .required("Required"),
    province: Yup.string()
        .matches(alphabetOnlyRegExp, "Only alphabets are allowed")
        .required("Required"),
    township: Yup.string().required("Required"),
});

export default function ProfileShippingAddressPage() {
    const inputRef = React.createRef();
    const [isEditing, setIsEditing] = React.useState(false);
    const [updateError, setUpdateError] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [location, setLocation] = React.useState({
        lat: "",
        long: "",
    });

    const { showModal } = useModal();

    const { data: townships } = useGetTownshipsQuery();
    const { data: states } = useGetStatesQuery({ countryId: 247 });
    const { data: profileData, isFetching: isProfileLoading } =
        useGetProfileQuery();

    const [updateProfile] = useUpdateProfileMutation();

    React.useEffect(() => {
        // Focus or blur text field based on editing state
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
        }
        if (!isEditing && inputRef.current) {
            inputRef.current.blur();
        }
    }, [isEditing]);

    React.useEffect(() => {
        if (profileData) {
            resolveAddressForLatLng(
                profileData?.location?.lat,
                profileData?.location?.long
            );
        }
    }, [profileData]);

    const getStateOptions = () => {
        return states.map((item) => ({ label: item.name, value: item.name }));
    };

    const handleEditClick = () => {
        setIsEditing((prevState) => !prevState);
    };

    const handleProfileShippingAddressSubmit = async (values) => {
        try {
            const response = await updateProfile({
                location: location,
                address: {
                    street: values.addressLine1,
                    city: values.city,
                    province: values.province,
                    zip: values.zipcode,
                    township: values.township,
                    landmark: values.landmark,
                },
            });
            if (response?.data?.data && response?.data?.status === 200) {
                handleEditClick();
                setUpdateError("");
            } else {
                setUpdateError(response?.data?.data?.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleOnAddLocation = (geoLocation) => {
        const location = {
            lat: geoLocation.location.lat,
            long: geoLocation.location.lng,
        };
        setAddress(geoLocation.shippingAddress);
        setLocation(location);
    };

    const handleAddLocation = () => {
        showModal({
            modalType: "MapLocationPickerModal",
            modalProps: {
                initialPosition: { lat: location.lat, lng: location.long },
                onAddLocation: handleOnAddLocation,
            },
        });
    };

    const resolveAddressForLatLng = async (lat, lng) => {
        const response = await Geocode.fromLatLng(lat, lng);
        const selectedLocation = response?.results[0];
        setAddress(selectedLocation.formatted_address);
        setLocation({ lat: lat, long: lng });
    };

    const initialFormData = {
        addressLine1: profileData?.address?.street || "",
        city: profileData?.address?.city || "",
        province: profileData?.address?.province || "",
        zipcode: profileData?.address?.zip || "",
        township: profileData?.address?.township || "",
        landmark: profileData?.address?.landmark || "",
    };

    return (
        <div className="profile-sub-page-container py-5">
            <div className="mb-5">
                <SectionTitle text="Your address" />
            </div>
            {isProfileLoading ? (
                <ProfileFormSkeleton />
            ) : (
                <Form
                    initialValues={initialFormData}
                    onSubmit={handleProfileShippingAddressSubmit}
                    validationSchema={ProfileShippingAddressFormSchema}
                    enableReinitialize
                >
                    <div className="row mb-5">
                        <div className="col">
                            <ViewableLocationPickerField
                                name="location"
                                label="Store Location :"
                                value={address}
                                actionTitle="Location"
                                onClick={handleAddLocation}
                                isEditing={isEditing}
                            />
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col">
                            <ViewableTextField
                                ref={inputRef}
                                name="addressLine1"
                                label="Address Line 1 :"
                                placeholder="Enter your shipping address"
                                isEditing={isEditing}
                            />
                        </div>
                    </div>
                    <div className="row mb-5 gap-5">
                        <div className="col-md">
                            <ViewableTextField
                                name="city"
                                label="City :"
                                placeholder="Enter your city"
                                isEditing={isEditing}
                            />
                        </div>
                        <div className="col-md">
                            <ViewableEditableSelect
                                name="province"
                                label="Province :"
                                placeholder="Select province"
                                options={states ? getStateOptions() : []}
                                isEditing={isEditing}
                            />
                        </div>
                    </div>
                    <div className="row mb-5 gap-5">
                        <div className="col-md">
                            <ViewableTextField
                                name="zipcode"
                                label="Postal code :"
                                placeholder="Enter your postal code"
                                isEditing={isEditing}
                            />
                        </div>
                        <div className="col-md">
                            <ViewableSelect
                                name="township"
                                label="Township :"
                                placeholder="Select township"
                                options={
                                    townships
                                        ? getTownshipsOptions(townships)
                                        : []
                                }
                                isEditing={isEditing}
                            />
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col">
                            <ViewableTextField
                                name="landmark"
                                label="Landmark :"
                                placeholder="Enter your landmark"
                                isEditing={isEditing}
                            />
                        </div>
                    </div>
                    {updateError && (
                        <div className="alert alert-primary text-center mt-3">
                            {updateError}
                        </div>
                    )}
                    <div className="row justify-content-end mt-5">
                        <div className="col-sm-6 col-md-5">
                            {isEditing ? (
                                <SubmitButton>Save changes</SubmitButton>
                            ) : (
                                <Button onClick={handleEditClick}>
                                    Edit address
                                </Button>
                            )}
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );
}
