import React from "react";
import { useNavigate } from "react-router-dom";
import { useRouting } from "../../hooks/routesHooks";
import { ROUTES } from "../../config/routesConfig";

import { useSignOutMutation } from "../../services/authService";

import SectionTitle from "../../components/SectionTitle";
import MyAccountSectionCard from "../../components/cards/MyAccountSectionCard";
import Button from "../../components/Button";

import "./myAccountPage.scss";

export default function MyAccountPage() {
    const navigate = useNavigate();
    const { getRouteLink } = useRouting();

    const [signOut, { isLoading: isSignOutLoading }] = useSignOutMutation();

    const MyAccountSections = [
        {
            id: 1,
            title: "Your Orders",
            description:
                "Track, return, cancel an order, download invoice or buy again",
            image: "/vectors/kasi-your-order-icon.svg",
            routeId: "PROFILE_ORDERS",
        },
        {
            id: 2,
            title: "Login & Security",
            description: "Manage password, email, phone number",
            image: "/vectors/kasi-login-security-icon.svg",
            routeId: "PROFILE_LOGIN_AND_SECURITY",
        },
        {
            id: 3,
            title: "Your Address",
            description: "Edit , remove or set default address",
            image: "/vectors/kasi-your-address-icon.svg",
            routeId: "PROFILE_SHIPPING_ADDRESS",
        },
        {
            id: 4,
            title: "Business Details",
            description:
                "Track, return, cancel an order, download invoice or buy again",
            image: "/vectors/kasi-business-info-icon.svg",
            routeId: "PROFILE_BUSINESS_DETAILS",
        },
        {
            id: 5,
            title: "Your Invoice",
            description: "Manage password, email, phone number",
            image: "/vectors/kasi-invoice-icon.svg",
            routeId: "PROFILE_INVOICES",
        },
        // Customer service temporarily removed
        // {
        //     id: 6,
        //     title: "Customer service",
        //     description: "Edit , remove or set default address",
        //     image: "/vectors/kasi-customer-service-icon.svg",
        //     routeId: "", //TODO: add the respective routeId here
        // },
        {
            id: 7,
            title: "Reset password",
            description: "Reset your password",
            image: "/vectors/kasi-reset-password-icon.svg",
            routeId: "PROFILE_RESET_PASSWORD",
        },
        {
            id: 8,
            title: "Data and Privacy",
            description: (
                <ul>
                    <li>Request Your Information</li>
                    <li>Close your account</li>
                    <li>Privacy notice</li>
                </ul>
            ),
            image: "",
            routeId: "", //TODO: add the respective routeId here
        },
    ];

    const handleMyAccountSectionNavigation = (routeId) => {
        if (routeId) {
            navigate(getRouteLink(ROUTES[routeId]));
        }
    };

    const handleGoToHome = () => {
        navigate(getRouteLink(ROUTES.LANDING));
    };

    const handleLogout = async () => {
        try {
            const response = await signOut();
            if (response?.data?.status === 200) {
                navigate(getRouteLink(ROUTES.SIGNOUT));
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="my-account-page-container py-4">
            <div className="row mb-5">
                <div className="col-md-10">
                    <SectionTitle text="My Account" textClassName="title" />
                </div>
            </div>
            <div className="my-account-cards-wrapper">
                {MyAccountSections?.map((section) => (
                    <MyAccountSectionCard
                        key={section.id}
                        title={section.title}
                        description={section.description}
                        image={section.image}
                        onClick={() =>
                            handleMyAccountSectionNavigation(section.routeId)
                        }
                    />
                ))}
            </div>
            <Button
                className="logout-btn"
                onClick={handleLogout}
                isLoading={isSignOutLoading}
            >
                Logout
            </Button>
            <div className="go-to-home-btn">
                <Button onClick={handleGoToHome}>Go to the Home Page</Button>
            </div>
        </div>
    );
}
