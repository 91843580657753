import React from "react";
import EditableSelect from "../EditableSelect";

import "./viewableField.scss";

export default function ViewableEditableSelect({
    label = "",
    name = "",
    placeholder = "",
    options = [],
    isEditing,
}) {
    return (
        <div className="viewable-field viewable-editable-select">
            <div className="viewable-field-content">
                {label && (
                    <label htmlFor={name} className="viewable-field-label">
                        {label}
                    </label>
                )}
                <EditableSelect
                    name={name}
                    options={options}
                    fallbackOptionLabel={placeholder}
                    disabled={!isEditing}
                />
            </div>
        </div>
    );
}
