import React from "react";
import * as Yup from "yup";

import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../config/routesConfig";
import { useRouting } from "../../hooks/routesHooks";

import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { Form, SubmitButton } from "../../components/forms";
import {
    ViewablePhoneNumberField,
    ViewableTextField,
} from "../../components/forms/ViewableField";
import Button from "../../components/Button";
import ProfileFormSkeleton from "../../components/skeletons/ProfileFormSkeleton";

import {
    useGetProfileQuery,
    useUpdateProfileMutation,
} from "../../services/profileService";
import { useAddMobileMutation } from "../../services/authService";

const countryCodeRegExp = /^(\+\d{1,4})/;

const ProfileLoginAndSecurityFormSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email format"),
    // mobile_number: Yup.string().when("mobile_number-country", {
    //     is: (country) => country && country.match(countryCodeRegExp),
    //     then: Yup.string().min(12, "Invalid phone number"),
    //     otherwise: Yup.string()
    //         .required("Required")
    //         .matches(countryCodeRegExp, "Invalid phone number"),
    // }),
    mobile_number: Yup.string().when("mobile_number-phone", {
        is: (phone) => phone,
        then: Yup.string().min(12, "Invalid phone number"),
        otherwise: Yup.string().required("Required"),
    }),
    "mobile_number-phone": Yup.string().required("Required"),
});

export default function ProfileLoginAndSecurityPage() {
    const navigate = useNavigate();
    const { getRouteLink } = useRouting();

    const inputRef = React.createRef();
    const [isEditing, setIsEditing] = React.useState(false);
    const [updateError, setUpdateError] = React.useState("");

    const { data: profileData, isLoading: isProfileLoading } =
        useGetProfileQuery();

    const [updateProfile] = useUpdateProfileMutation();
    const [addMobile, { isLoading: isAddMobileSubmitting }] =
        useAddMobileMutation();

    React.useEffect(() => {
        // Focus or blur text field based on editing state
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
        }
        if (!isEditing && inputRef.current) {
            inputRef.current.blur();
        }
    }, [isEditing]);

    const handleEditClick = () => {
        setIsEditing((prevState) => !prevState);
    };

    const handleUpdatePhone = async (value) => {
        try {
            const response = await addMobile({ mobile: value });

            if (
                response?.data?.message === "success" &&
                response?.data?.status === 200
            ) {
                navigate(
                    {
                        pathname: getRouteLink(ROUTES.OTP_VERIFY),
                        search: `?mobile_number=${value}`,
                    },
                    {
                        state: {
                            referrer: ROUTES.PROFILE_LOGIN_AND_SECURITY,
                        },
                    }
                );
            } else {
                setUpdateError(response?.data?.response?.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleProfileLoginAndSecuritySubmit = async (values) => {
        try {
            const response = await updateProfile({
                name: values.name,
                email: values.email,
            });
            if (response?.data?.data && response?.data?.status === 200) {
                handleEditClick();
                setUpdateError("");
            } else {
                setUpdateError(response?.data?.data?.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const initialFormData = {
        name: profileData?.name || "",
        email: profileData?.email || "",
        mobile_number: profileData?.login || "",
    };

    return (
        <div className="profile-sub-page-container py-5">
            <div className="mb-5">
                <SectionTitle text="Login & security" />
            </div>
            {isProfileLoading ? (
                <ProfileFormSkeleton />
            ) : (
                <Form
                    initialValues={initialFormData}
                    onSubmit={handleProfileLoginAndSecuritySubmit}
                    validationSchema={ProfileLoginAndSecurityFormSchema}
                >
                    <div className="row mb-5">
                        <div className="col">
                            <ViewableTextField
                                ref={inputRef}
                                name="name"
                                label="Name : "
                                placeholder="Enter your name"
                                isEditing={isEditing}
                            />
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col">
                            <ViewableTextField
                                name="email"
                                label="Email :"
                                placeholder="Enter your e-mail"
                                isEditing={isEditing}
                            />
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col">
                            <ViewablePhoneNumberField
                                name="mobile_number"
                                label="Primary Phone Number :"
                                isEditing={isEditing}
                                endButtonTitle="Add Number"
                                onEndButtonClick={handleUpdatePhone}
                                isLoading={isAddMobileSubmitting}
                            />
                        </div>
                    </div>
                    {updateError && (
                        <div className="alert alert-primary text-center mt-3">
                            {updateError}
                        </div>
                    )}
                    <div className="row justify-content-end mt-5">
                        <div className="col-sm-6 col-md-5">
                            {isEditing ? (
                                <SubmitButton>Save changes</SubmitButton>
                            ) : (
                                <Button onClick={handleEditClick}>
                                    Edit login & security details
                                </Button>
                            )}
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );
}
