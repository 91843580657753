import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQueries";

export const orderAPI = createApi({
    reducerPath: "orderAPI",
    baseQuery: baseQueryWithReauth,
    tagTypes: ["Orders", "Order"],
    endpoints: (build) => ({
        getOrders: build.query({
            query: (params) => ({
                url: "api/orders/",
                method: "POST",
                body: {
                    params: {
                        method: "GET",
                        ...params,
                    },
                },
            }),
            providesTags: ["Orders"],
            transformResponse: (response) => response?.result,
        }),
        getOrder: build.query({
            query: ({ orderId: id }) => ({
                url: `api/orders/${id}/`,
                method: "POST",
                body: {
                    params: {
                        method: "GET",
                    },
                },
            }),
            providesTags: ["Order"],
            transformResponse: (response) => response?.result?.response[0],
        }),
        addOrder: build.mutation({
            query: (orderData) => ({
                url: "/api/orders/",
                method: "POST",
                body: {
                    params: {
                        method: "POST",
                        ...orderData,
                    },
                },
            }),
            invalidatesTags: ["Orders"],
            transformResponse: (response) => response?.result,
        }),
        updateOrder: build.mutation({
            query: ({ orderId: id, orderData }) => ({
                url: `/api/orders/${id}/`,
                method: "PUT",
                body: {
                    params: {
                        method: "PUT",
                        ...orderData,
                    },
                },
            }),
            invalidatesTags: ["Orders"],
            transformResponse: (response) => ({
                data: response?.result?.response,
                status: response?.result?.status,
            }),
        }),
        cancelOrder: build.mutation({
            query: ({ orderId: id }) => ({
                url: `/api/orders/${id}/cancel`,
                method: "PUT",
                body: {
                    params: {
                        method: "PUT",
                        public_order: true,
                    },
                },
            }),
            invalidatesTags: ["Orders", "Order"],
            transformResponse: (response) => ({
                data: response?.result?.response,
                message: response?.result?.message,
                status: response?.result?.status,
            }),
        }),
        addPromoCode: build.mutation({
            query: ({ orderId: id, params }) => ({
                url: `/api/orders/${id}/promotions/`,
                method: "POST",
                body: {
                    params: {
                        method: "POST",
                        ...params,
                    },
                },
            }),
            transformResponse: (response) => ({
                data: response?.result?.response,
                status: response?.result?.status,
            }),
        }),
        reorder: build.mutation({
            query: (params) => ({
                url: `/api/reorders/`,
                method: "POST",
                body: {
                    params: {
                        method: "POST",
                        ...params,
                    },
                },
            }),
            transformResponse: (response) => response?.result,
        }),
    }),
});

export const {
    useGetOrdersQuery,
    useGetOrderQuery,
    useAddOrderMutation,
    useUpdateOrderMutation,
    useCancelOrderMutation,
    useAddPromoCodeMutation,
    useReorderMutation,
} = orderAPI;
