import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../config/routesConfig";
import { useRouting } from "../../hooks/routesHooks";
import { useLoginStatus } from "../../hooks/authHooks";
import { useCart } from "../../hooks/cartHooks";

import Button from "../../components/Button";
import CartItemCard from "../../components/cards/CartItemCard";
import PromotionCarousel from "../../components/carousels/PromotionCarousel";

import {
    useUpdateCartMutation,
    useDeleteCartMutation,
} from "../../services/cartService";
import { useGetDealsQuery } from "../../services/dataService";

import { parseCurrencyValue } from "../../utils/currencyUtils";

import "./cartSummaryPage.scss";

export default function CartSummaryPage() {
    const navigate = useNavigate();
    const { getRouteLink } = useRouting();
    const isLoggedIn = useLoginStatus();
    const { cartItems, getPublicOrderId } = useCart();

    const [updateCart] = useUpdateCartMutation();
    const [deleteCart] = useDeleteCartMutation();

    const publicOrderId = getPublicOrderId();

    const { data: allDeals } = useGetDealsQuery();

    const handleRemoveCartItem = async (idx) => {
        if (isLoggedIn) {
            await deleteCart({
                cartData: { order_line_ids: [idx] },
                withCredentials: true,
            });
        } else {
            await deleteCart({
                cartData: { order_line_ids: [idx], order_id: publicOrderId },
            });
        }
    };

    const handleItemQuantityChange = async ({
        newValue,
        oldValue,
        idx,
        packagingId,
    }) => {
        const quantity = Number(newValue);
        if (newValue !== oldValue) {
            let cartData = {};

            if (packagingId) {
                cartData = {
                    order_line_id: idx,
                    product_packaging_qty: newValue,
                    packaging_id: packagingId,
                };
            } else {
                cartData = {
                    order_line_id: idx,
                    quantity: quantity,
                };
            }

            if (isLoggedIn) {
                await updateCart({
                    cartData: cartData,
                    withCredentials: true,
                });
            } else {
                await updateCart({
                    cartData: {
                        ...cartData,
                        order_id: publicOrderId,
                    },
                });
            }
        }
    };

    const hasItems = !!cartItems?.order_line?.length;

    const handleOnClickCartItem = (productId) => {
        navigate(getRouteLink(ROUTES.PRODUCT_DETAIL, { productId }));
    };

    const handleProceedCheckout = () => {
        if (hasItems) {
            if (!isLoggedIn) {
                navigate(getRouteLink(ROUTES.SIGNIN_WELCOME), {
                    state: {
                        referrer: ROUTES.CHECKOUT,
                    },
                });
            } else {
                navigate(getRouteLink(ROUTES.CHECKOUT));
            }
        }
    };

    const featuredDeals = allDeals?.filter((item) => item.is_featured);

    return (
        <div className="cart-summary-page">
            <div className="px-5">
                <div className="container-fluid">
                    <div className="w-100">
                        <div className="row my-5">
                            <div className="cart-items-container col-lg-9 px-lg-0 mb-3 mb-lg-0">
                                <div className="w-100 px-4 pt-3">
                                    <div className="row">
                                        <div className="col">
                                            <div className="h3">My Cart</div>
                                        </div>
                                    </div>
                                    <div className="row d-none d-md-block">
                                        <div className="col-2 offset-10">
                                            <div className="h5 text-muted">
                                                Price
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider" />
                                {!hasItems && (
                                    <div className="text-center mt-3">
                                        (Your cart is empty)
                                    </div>
                                )}
                                {cartItems?.order_lines?.map((item) => {
                                    const productPackagingId =
                                        item.product_packaging_id[0];

                                    return (
                                        <div key={item.id} className="w-100">
                                            <div className="px-md-4 py-4 py-md-5">
                                                <CartItemCard
                                                    cartItem={item}
                                                    quantity={
                                                        productPackagingId ? item.product_packaging_qty : item.product_uom_qty
                                                    }
                                                    onDelete={() => {
                                                        handleRemoveCartItem(
                                                            item.id
                                                        );
                                                    }}
                                                    onChangeItemQuantity={(
                                                        value
                                                    ) => {
                                                        handleItemQuantityChange(
                                                            {
                                                                packagingId:
                                                                    productPackagingId,
                                                                newValue: value,
                                                                oldValue:
                                                                    item.product_uom_qty,
                                                                idx: item.id,
                                                            }
                                                        );
                                                    }}
                                                    onClick={() => {
                                                        handleOnClickCartItem(
                                                            item
                                                                .product_template_id[0]
                                                        );
                                                    }}
                                                    isPackagingLine={!!productPackagingId}
                                                />
                                            </div>
                                            <div className="divider" />
                                        </div>
                                    );
                                })}
                                {hasItems && (
                                    <div className="w-100 px-md-4 pt-3 pb-4 h5">
                                        <div className="row">
                                            <div className="fw-semibold col-md-10 text-md-end">
                                                Sub-total (
                                                {cartItems?.cart_quantity}
                                                &nbsp;Items):
                                            </div>
                                            <div className="fw-semibold col-md-2">
                                                {parseCurrencyValue(
                                                    cartItems?.amount_untaxed
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-lg-3 px-0 ps-lg-3 pe-lg-0">
                                <div className="cart-actions-container p-3">
                                    <div className="shipping-details-container mb-5">
                                        <img
                                            src="/vectors/kasi-checkmark-circle-green.svg"
                                            alt=" "
                                            className="checkmark-icon"
                                        />
                                        <div className="pe-md-4">
                                            Your order qualifies for Shipping.
                                            Choose this option at checkout. See
                                            details
                                        </div>
                                    </div>
                                    <div className="billing-details-containter">
                                        <div className="d-flex align-items-center justify-content-between fw-semibold mb-2">
                                            Sub-total (
                                            {cartItems?.cart_quantity}
                                            &nbsp;Items)
                                            <div className="sub-total">
                                                {parseCurrencyValue(
                                                    cartItems?.amount_untaxed
                                                )}
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            VAT
                                            <div className="vat">
                                                {parseCurrencyValue(
                                                    cartItems?.amount_tax
                                                )}
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            Delivery Fee
                                            <div className="delivery-fee">
                                                {parseCurrencyValue(cartItems?.amount_delivery)}
                                            </div>
                                        </div>
                                        <div className="h6 fw-semibold d-flex align-items-center justify-content-between mb-3">
                                            Total
                                            <div className="total">
                                                {parseCurrencyValue(
                                                    cartItems?.amount_total
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <Button
                                        className="btn btn-lg btn-primary w-100 fw-semibold mt-2"
                                        onClick={handleProceedCheckout}
                                    >
                                        Proceed to Checkout
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {featuredDeals?.length > 0 && (
                <PromotionCarousel promotions={featuredDeals} showBackground />
            )}
        </div>
    );
}
