import { useField } from "formik";
import React from "react";

/**
 * Native `password` input wrapper with Formik integration.
 * @param {{
 * name: string,
 * label: string,
 * disabled?: boolean
 * options: { value: any, label: string }[]
 * fallbackOptionLabel: string,
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function Select({
    name,
    label = "",
    disabled = false,
    options,
    fallbackOptionLabel = "Choose...",
    onChange,
    ...rest
}) {
    const [field, meta] = useField({ name, label, ...rest });

    const handleFieldChange = (e) => {
        field.onChange(e);
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <div className="formfield-wrapper">
            <div className={`formfield-root formfield-select ${
                        meta.touched && meta.error ? "invalid" : ""
                    }`}>
                {label && (
                    <label htmlFor={name} className="formfield-label">
                        {label}
                    </label>
                )}
                <select
                    className={`formfield-input ${
                        field.value === "" ? "input-placeholder" : ""
                    }`}
                    id={name}
                    name={name}
                    disabled={disabled}
                    {...field}
                    {...rest}
                    onChange={handleFieldChange}
                >
                    <option
                        value=""
                        className={field.value === "" ? "active" : undefined}
                    >
                        {fallbackOptionLabel}
                    </option>
                    {options.map((optn) => (
                        <option
                            className={
                                optn.value === field.value
                                    ? "active"
                                    : undefined
                            }
                            style={{ color: optn?.color }}
                            value={optn.value}
                            label={optn.label || optn.value}
                            key={optn.value}
                        />
                    ))}
                </select>
            </div>
            <div
                hidden={!(meta.touched && meta.error)}
                className="formfield-error"
            >
                {meta.error}
            </div>
        </div>
    );
}
