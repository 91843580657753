import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { cartReducer } from "./services/cart";
import { registerReducer } from "./state/register";

import { authAPI } from "./services/authService";
import { userAPI } from "./services/userService";

import { publicProductAPI } from "./services/productService";
import { publicCategoryAPI } from "./services/categoryService";
import { dataAPI } from "./services/dataService";
import { profileAPI } from "./services/profileService";
import { cartAPI } from "./services/cartService";
import { orderAPI } from "./services/orderService";
import { invoiceAPI } from "./services/invoiceService";
import { brandAPI } from "./services/brandService";

const combinedReducer = combineReducers({
    cart: cartReducer,
    registerFormData: registerReducer,
    [authAPI.reducerPath]: authAPI.reducer,
    [userAPI.reducerPath]: userAPI.reducer,
    [publicProductAPI.reducerPath]: publicProductAPI.reducer,
    [publicCategoryAPI.reducerPath]: publicCategoryAPI.reducer,
    [dataAPI.reducerPath]: dataAPI.reducer,
    [profileAPI.reducerPath]: profileAPI.reducer,
    [cartAPI.reducerPath]: cartAPI.reducer,
    [orderAPI.reducerPath]: orderAPI.reducer,
    [invoiceAPI.reducerPath]: invoiceAPI.reducer,
    [brandAPI.reducerPath]: brandAPI.reducer,
    // ... add your reducers here
});

// we structure store like this so that we can easily reset the store on logout.
const rootReducer = (state, action) => {
    if (action.type === "auth/logout") {
        state = undefined;
    }
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    //add rtkq middleware to below aray
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(authAPI.middleware)
            .concat(userAPI.middleware)
            .concat(publicProductAPI.middleware)
            .concat(publicCategoryAPI.middleware)
            .concat(dataAPI.middleware)
            .concat(profileAPI.middleware)
            .concat(cartAPI.middleware)
            .concat(orderAPI.middleware)
            .concat(invoiceAPI.middleware)
            .concat(brandAPI.middleware),
    devTools: true,
});
