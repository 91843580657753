import AppLayout from "../components/AppLayout";
import {
    SignInWelcomePage,
    SignInPage,
    RegisterPage,
    BusinessDetailsPage,
    AddPhonePage,
    OTPVerifyPage,
    ForgotPasswordPage,
    WelcomePage,
} from "../pages/AuthPages";
import SignoutPage from "../pages/auth/SignoutPage";
import LandingPage from "../pages/LandingPage";
import {
    ProductListPage,
    ProductDetailPage,
    CartSummaryPage,
} from "../pages/ProductPages";
import CheckoutPage from "../pages/CheckoutPage";
import MyAccountPage, {
    ProfileLoginAndSecurityPage,
    ProfileShippingAddressPage,
    ProfileBusinessDetailsPage,
    ProfileOrdersPage,
    ProfileOrderDetailPage,
    ProfileInvoicesPage,
    ProfileInvoiceDetailPage,
    ProfileResetPasswordPage,
} from "../pages/MyAccountPages";

import RequireAuth from "../components/routing/PrivateRoute";
import PublicOnly from "../components/routing/PublicOnlyRoute";
import ScrollToTop from "../components/routing/ScrollTop";
import { getRouteObj } from "../utils/routeUtils";
import KasiBenefitsPage from "../pages/KasiBenefitsPage/KasiBenefitsPage";
import ContactPage from "../pages/ContactPage/ContactPage";
import {
    ConsumerProtectionActPage,
    PrivacyPolicyPage,
    ReturnPolicyPage,
    ShippingPolicyPage,
    TermsAndConditionsPage,
} from "../pages/PolicyPages";

export const routesConfig = [
    {
        title: "Landing",
        path: "/",
        element: <AppLayout />,
        routeId: "LANDING",
        children: [
            {
                path: "",
                element: (
                    <>
                        <ScrollToTop key={"LANDING"} />
                        <LandingPage />
                    </>
                ),
            },
            {
                path: "kasi-benefits",
                element: (
                    <>
                        <ScrollToTop key={"KASI_BENEFITS"} />
                        <KasiBenefitsPage />
                    </>
                ),
                routeId: "KASI_BENEFITS",
            },
            {
                title: "Products",
                path: "products",
                element: (
                    <>
                        <ScrollToTop key={"PRODUCTS"} />
                        <ProductListPage />
                    </>
                ),
                routeId: "PRODUCTS",
            },
            {
                title: "ProductDetail",
                path: "products/:productId",
                element: <ProductDetailPage />,
                routeId: "PRODUCT_DETAIL",
            },
            {
                title: "CartSummary",
                path: "cart-summary",
                element: (
                    <>
                        <ScrollToTop key={"CART_SUMMARY"} />
                        <CartSummaryPage />
                    </>
                ),
                routeId: "CART_SUMMARY",
            },
            {
                title: "Checkout",
                path: "checkout",
                element: (
                    <>
                        <ScrollToTop key={"CHECKOUT"} />
                        <CheckoutPage />
                    </>
                ),
                routeId: "CHECKOUT",
            },
            {
                title: "PlaceOrder",
                path: "place-order",
                element: (
                    <>
                        <ScrollToTop key={"PLACE_ORDER"} />
                        <CheckoutPage />
                    </>
                ),
                routeId: "PLACE_ORDER",
            },
            {
                title: "MyAccount",
                path: "my-account",
                element: (
                    <RequireAuth>
                        <MyAccountPage />
                    </RequireAuth>
                ),
                routeId: "MY_ACCOUNT",
            },
            {
                title: "ProfileLoginAndSecurity",
                path: "profile-login-and-security",
                element: (
                    <RequireAuth>
                        <ProfileLoginAndSecurityPage />
                    </RequireAuth>
                ),
                routeId: "PROFILE_LOGIN_AND_SECURITY",
            },
            {
                title: "ProfileShippingAddress",
                path: "profile-shipping-address",
                element: (
                    <RequireAuth>
                        <ProfileShippingAddressPage />
                    </RequireAuth>
                ),
                routeId: "PROFILE_SHIPPING_ADDRESS",
            },
            {
                title: "ProfileBusinessDetails",
                path: "profile-business-details",
                element: (
                    <RequireAuth>
                        <ProfileBusinessDetailsPage />
                    </RequireAuth>
                ),
                routeId: "PROFILE_BUSINESS_DETAILS",
            },
            {
                title: "ProfileOrders",
                path: "profile-orders",
                element: (
                    <RequireAuth>
                        <ProfileOrdersPage />
                    </RequireAuth>
                ),
                routeId: "PROFILE_ORDERS",
            },
            {
                title: "ProfileOrderDetail",
                path: "profile-orders/:orderId",
                element: (
                    <RequireAuth>
                        <ScrollToTop key={"PROFILE_ORDER_DETAIL"} />
                        <ProfileOrderDetailPage />
                    </RequireAuth>
                ),
                routeId: "PROFILE_ORDER_DETAIL",
            },
            {
                title: "ProfileInvoices",
                path: "profile-invoices",
                element: (
                    <RequireAuth>
                        <ProfileInvoicesPage />
                    </RequireAuth>
                ),
                routeId: "PROFILE_INVOICES",
            },
            {
                title: "ProfileInvoiceDetail",
                path: "profile-invoices/:invoiceId",
                element: (
                    <RequireAuth>
                        <ScrollToTop key={"PROFILE_INVOICE_DETAIL"} />
                        <ProfileInvoiceDetailPage />
                    </RequireAuth>
                ),
                routeId: "PROFILE_INVOICE_DETAIL",
            },
            {
                title: "ProfileResetPassword",
                path: "profile-reset-password",
                element: (
                    <RequireAuth>
                        <ProfileResetPasswordPage />
                    </RequireAuth>
                ),
                routeId: "PROFILE_RESET_PASSWORD",
            },
        ],
    },
    {
        title: "SignInWelcome",
        path: "/signin-welcome",
        element: (
            <PublicOnly>
                <SignInWelcomePage />
            </PublicOnly>
        ),
        routeId: "SIGNIN_WELCOME",
    },
    {
        title: "SignIn",
        path: "/signin",
        element: (
            <PublicOnly>
                <SignInPage />
            </PublicOnly>
        ),
        routeId: "SIGNIN",
    },
    {
        title: "Register",
        path: "/register",
        element: (
            <PublicOnly>
                <RegisterPage />
            </PublicOnly>
        ),
        routeId: "REGISTER",
    },
    {
        title: "BusinessDetails",
        path: "/business-details",
        element: (
            <PublicOnly>
                <BusinessDetailsPage />
            </PublicOnly>
        ),
        routeId: "BUSINESS_DETAILS",
    },
    {
        title: "AddPhoneNumber",
        path: "/add-phone-number",
        element: (
            <PublicOnly>
                <AddPhonePage />
            </PublicOnly>
        ),
        routeId: "ADD_PHONE_NUMBER",
    },
    {
        title: "OPTVerify",
        path: "/otp-verify",
        element: <OTPVerifyPage />,
        routeId: "OTP_VERIFY",
    },
    {
        title: "Contact",
        path: "/contact",
        element: <ContactPage />,
        routeId: "CONTACT",
    },
    {
        title: "ForgotPassword",
        path: "/forgot-password",
        element: (
            <PublicOnly>
                <ForgotPasswordPage />
            </PublicOnly>
        ),
        routeId: "FORGOT_PASSWORD",
    },
    {
        title: "Welcome",
        path: "/welcome",
        element: (
            <RequireAuth>
                <WelcomePage />
            </RequireAuth>
        ),
        routeId: "WELCOME",
    },
    {
        title: "Signout",
        path: "/signout",
        element: <SignoutPage />,
        routeId: "SIGNOUT",
    },
    {
        title: "TermsAndConditions",
        path: "/terms-and-conditions",
        element: <TermsAndConditionsPage />,
        routeId: "TERMS_AND_CONDITIONS",
    },
    {
        title: "PrivacyPolicy",
        path: "/privacy-policy",
        element: <PrivacyPolicyPage />,
        routeId: "PRIVACY_POLICY",
    },
    {
        title: "ReturnPolicy",
        path: "/return-policy",
        element: <ReturnPolicyPage />,
        routeId: "RETURN_POLICY",
    },
    {
        title: "ShippingPolicy",
        path: "/shipping-policy",
        element: <ShippingPolicyPage />,
        routeId: "SHIPPING_POLICY",
    },
    {
        title: "ConsumerProtectionAct",
        path: "/consumer-protection-act",
        element: <ConsumerProtectionActPage />,
        routeId: "CONSUMER_PROTECTION_ACT",
    },
];

// Returns Route ID Dictionary
export const ROUTES = getRouteObj(routesConfig);
