import React from "react";
import "./cards.scss";

export default function HowItWorksCard({
    count = "",
    title = "",
    description = "",
}) {
    return (
        <div className="how-it-works-card">
            <div className="card-header">
                <div className="header-icon-container d-flex justify-content-center align-items-center">
                    <div className="step-count">{count}</div>
                </div>
            </div>
            <div className="card-body d-flex flex-column">
                <div className="card-title">{title}</div>
                <div className="card-content">{description}</div>
            </div>
        </div>
    );
}
