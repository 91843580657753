import React from "react";

import "./policyPages.scss";

export default function PrivacyPolicyPage() {
    return (
        <div className="container p-5">
            <div className="main-title text-center mb-5">Privacy Policy</div>
            <div className="mb-4">
                <p className="content m-0">
                    Policy in this document is related to African Distribution
                    Technologies Pty Ltd and its business operating under the
                    name Kasi Warehouse.
                </p>
                <br />
                <p className="content m-0">
                    Kasi Warehouse is an interactive platform which provides
                    access to the services that African Distributor Technologies
                    Pty LTD. This Privacy Policy applies to the all services
                    provided by African Distribution Technologies Pty Ltd and
                    henceforth will be addressed as ADT Or Company.
                </p>
                <br />
                <p className="content m-0">
                    Policy document will always be made available on the ADT
                    platform – Website & APPs. From time to time we will update
                    the policy document on the ADT website / APPs to be
                    compliant and following any changes related to Protection of
                    Personal Information Act
                </p>
                <br />
                <p className="content m-0">
                    ADT will collect personal information of customers only
                    after taking consent from people using ADT platform, For
                    instance, we will collect information such as your e-mail
                    address, name or phone number. ADT servers may also
                    automatically collect information about your computer when
                    you visit the Website, including without limitation the type
                    of browser software you use, the operating system you are
                    running, the Website that referred you, and your Internet
                    Protocol address.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">
                    Use of the personal information collected
                </div>
                <p className="content m-0">
                    The personal information collected about people will only be
                    used to deliver service requested by people on ADT platform.
                    For example, we may use your phone number or e-mail address
                    to send you a confirmation notice, updates and promotional
                    information if given consent to provide such services. We
                    will also use personal information provided like address,
                    company registration number, VAT number etc to make sure
                    that legal contractual obligation like – Invoices, delivery
                    of goods, timely information can be managed as per the law
                    of the land. Person can easily opt out of non-critical
                    incidents like promo info, updates on trends, new product
                    launch etc.
                </p>
                <br />
                <p className="content m-0">
                    Other than as provided in this Privacy Policy and our Terms
                    of Use, or as set forth when you submit the information to
                    the Website, we will not share or sell your personally
                    identifiable information to third parties without your
                    consent.
                </p>
                <br />
                <p className="content m-0">
                    We reserve the right to use the information we collect about
                    your computer, which may at times be able to identify you,
                    for any lawful business purpose, including without
                    limitation to help diagnose problems with our servers, to
                    gather broad demographic information and to otherwise
                    administer our Website. While your personally identifiable
                    information is protected as outlined above, we reserve the
                    right to use, transfer, sell, and share aggregated,
                    anonymous data about our users as a group for any business
                    purpose, such as analysing usage trends and seeking
                    compatible advertisers and partners.
                </p>
                <br />
                <p className="content m-0">
                    In addition, as our business changes, we may buy or sell
                    various assets. In the event all or a portion of the assets
                    owned or controlled by ADT, its parent or any subsidiary or
                    affiliated entity are sold, assigned, transferred or
                    acquired by another company, the information from and/or
                    about our Website users may be among the transferred assets.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Privacy policy</div>
                <p className="content m-0">
                    A cookie is a piece of information that is deposited on your
                    computer’s hard drive by your web browser when you use our
                    computer server. The cookies enable us to recognise you and
                    give us information about your previous visits. We learn
                    which advertisements bring users to our Website. Most
                    browsers accept cookies automatically, but usually you can
                    alter your settings to prevent automatic acceptance. If you
                    choose not to accept cookies, this may disable some of the
                    features of our Website. The information that we collect and
                    may share with our advertisers is anonymous and not
                    personally identifiable. It does not contain your name,
                    address, telephone number or email address.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Mobile Device Identifiers</div>
                <p className="content m-0">
                    Certain mobile service providers uniquely identify mobile
                    devices and we or our third-party service providers may
                    receive such device information if you access the Website
                    through mobile devices. Certain features of our Website may
                    require collection of mobile phone numbers, and we may
                    associate that phone number to mobile device identification
                    information. Additionally, some mobile phone service
                    providers operate systems that pinpoint the physical
                    location of devices that use their service. Depending on the
                    provider, we or our third-party service providers may
                    receive this information.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">Embedded Scripts</div>
                <p className="content m-0">
                    An embedded script is a programming code that is designed to
                    collect information about your interactions with the
                    Website, such as the links you click on. The code is
                    temporarily downloaded onto your computer from our web
                    server or a third-party service provider, is active only
                    while you are connected to the Website, and is deactivated
                    or deleted thereafter. Information collected through passive
                    means may be non-identifying or may be associated with you.
                    If the latter, it will be treated as personally identifiable
                    information.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">
                    Information You Provide About A Third Party
                </div>
                <p className="content m-0">
                    If you send someone else a communication from the Website,
                    such as sending Website content to a friend, the information
                    you provide (names, e-mail addresses etc.) is used to
                    facilitate the communication and is not used for any other
                    marketing purpose unless we obtain consent from that person
                    or we explicitly say otherwise. Please be aware that when
                    you use any send-to-a-friend functionality on our Website,
                    your e-mail address may be included in the communication
                    sent to your friend.
                </p>
            </div>
            <div className="mb-4">
                <div className="sub-title">
                    Privacy policy – Sharing the information
                </div>
                <p className="content m-0">
                    ADT will disclose personal information collected about
                    person to only fulfil below conditions : (a) fulfil a
                    government request; (b) conform with the requirements of the
                    law or legal process; (c) protect or defend our legal rights
                    or property, our Website, or other users; or (d) in an
                    emergency to protect the health and safety of our users or
                    the general public.
                </p>
                <br />
                <p className="content m-0">
                    This Privacy Policy doesn’t restrict ADT from collecting
                    information from other sources, unless specifically stated.
                    We have adopted this Privacy Policy to explain what
                    information may be collected on our Website, how we use this
                    information and under what circumstances we may disclose the
                    information to third parties.
                </p>
            </div>
            <div className="disclaimer-note">
                WHEN YOU ACCESS OUR WEBSITE YOU AGREE TO THIS PRIVACY POLICY. IF
                YOU DO NOT AGREE TO THIS PRIVACY POLICY, OR TO ANY CHANGES WE
                MAY SUBSEQUENTLY MAKE, YOU MUST IMMEDIATELY STOP SHARING
                INFORMATION THE WEBSITE / APP COLLECTS FROM YOU.
            </div>
        </div>
    );
}
