import React from "react";
import {
    parseBase64ImgSrc,
    getFormattedValidPeriod,
} from "../../utils/dataUtils";

import "./cards.scss";

const fallbackImagePath = "/images/product_placeholder.png";

export default function DealsDetailsCard({
    image,
    title,
    startDate,
    endDate,
    onClick,
}) {
    const validPeriod = getFormattedValidPeriod(startDate, endDate, true);
    const dealsImage = parseBase64ImgSrc(image) || fallbackImagePath;

    return (
        <div className="deals-details-card" role="button" onClick={onClick}>
            <div className="deals-image-wrapper">
                <img className="deals-image" src={dealsImage} alt="" />
            </div>
            <div className="deals-details-wrapper w-50 ms-3">
                <div className="deals-name">{title}</div>
                <div className="valid-date px-3 py-1 mt-3">{validPeriod}</div>
            </div>
        </div>
    );
}
