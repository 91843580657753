import React from "react";

import "./tableFilter.scss";

export default function TableFilter({ options = [], value, onChange }) {
    const handleOnOptionClick = (value) => {
        if (onChange && value) {
            onChange(value);
        }
    };

    return (
        <div className="table-filter-wrapper">
            {options.map(({ label, value: optionValue }) => (
                <div
                    key={optionValue}
                    role="button"
                    className={`table-filter-item ${
                        optionValue === value ? "active" : ""
                    }`}
                    onClick={() => handleOnOptionClick(optionValue)}
                >
                    {label}
                </div>
            ))}
        </div>
    );
}
