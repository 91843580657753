import React from "react";
import * as Yup from "yup";

import { useUpdatePasswordMutation } from "../../services/profileService";
import { useModal } from "../../hooks/modalsHooks";

import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { Form, SubmitButton } from "../../components/forms";
import { ViewablePasswordField } from "../../components/forms/ViewableField";

const ResetPasswordFormSchema = Yup.object().shape({
    password: Yup.string().required("Required"),
    new_password: Yup.string().required("Required"),
    confirm_password: Yup.string()
        .oneOf([Yup.ref("new_password"), null], "Passwords must match")
        .required("Required"),
});

export default function ProfileResetPasswordPage() {
    const { showModal } = useModal();
    const [updateError, setUpdateError] = React.useState("");

    const [updatePassword] = useUpdatePasswordMutation();

    const handleResetPasswordSubmit = async (values, { resetForm }) => {
        try {
            const response = await updatePassword({
                password: values.password,
                new_password: values.new_password,
                confirm_password: values.confirm_password,
            });
            if (response?.data?.data && response?.data?.status === 200) {
                resetForm({
                    values: {
                        password: "",
                        new_password: "",
                        confirm_password: "",
                    },
                });
                showModal({
                    modalType: "AlertModal",
                    modalProps: {
                        title: "Your password has been reset successfully",
                        actionTitle: "OK",
                    },
                });
            } else {
                setUpdateError(response?.data?.data?.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="profile-sub-page-container py-5">
            <div className="mb-5">
                <SectionTitle text="Reset password" />
            </div>
            <Form
                initialValues={{
                    password: "",
                    new_password: "",
                    confirm_password: "",
                }}
                onSubmit={handleResetPasswordSubmit}
                validationSchema={ResetPasswordFormSchema}
            >
                <div className="row mb-3">
                    <div className="col">
                        <ViewablePasswordField
                            name="password"
                            label="Old Password :"
                            placeholder="*********"
                            isEditing={true}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <ViewablePasswordField
                            name="new_password"
                            label="New Password :"
                            placeholder="*********"
                            isEditing={true}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <ViewablePasswordField
                            name="confirm_password"
                            label="Confirm New Password :"
                            placeholder="*********"
                            isEditing={true}
                        />
                    </div>
                </div>
                {updateError && (
                    <div className="alert alert-primary text-center mt-3">
                        {updateError}
                    </div>
                )}
                <div className="row justify-content-end mt-5">
                    <div className="col-sm-6 col-md-5">
                        <SubmitButton>Save changes</SubmitButton>
                    </div>
                </div>
            </Form>
        </div>
    );
}
