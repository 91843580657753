import React from "react";
import dayjs from "dayjs";

import { useParams, useNavigate } from "react-router-dom";
import { useRouting } from "../../hooks/routesHooks";
import { useLoginStatus } from "../../hooks/authHooks";
import { useModal } from "../../hooks/modalsHooks";
import { ROUTES } from "../../config/routesConfig";

import SectionTitle from "../../components/SectionTitle";
import Button from "../../components/Button";
import OrderDetailSkeleton from "../../components/skeletons/OrderDetailSkeleton";
import TableSkeleton from "../../components/skeletons/TableSkeleton";

import {
    useCancelOrderMutation,
    useGetOrderQuery,
} from "../../services/orderService";
import { useGetProfileQuery } from "../../services/profileService";
import { parseCurrencyValue } from "../../utils/currencyUtils";

import "./profilePages.scss";

export default function ProfileOrderDetailPage() {
    const navigate = useNavigate();
    const { getRouteLink } = useRouting();
    const { orderId: id } = useParams();
    const { showModal } = useModal();

    const { data: orderData, isLoading: isOrderLoading } = useGetOrderQuery({
        orderId: id,
    });
    const isLoggedIn = useLoginStatus();

    const { data: profileData } = useGetProfileQuery({}, { skip: !isLoggedIn });
    const [cancelOrder] = useCancelOrderMutation();

    const orderDate =
        orderData?.date_order && dayjs(orderData?.date_order).isValid()
            ? dayjs(orderData?.date_order).format("DD MMM , YYYY")
            : null;

    const handleNavigateToMyOrders = () => {
        navigate(getRouteLink(ROUTES.PROFILE_ORDERS));
    };

    const handleOpenCancelOrderPrompt = () => {
        showModal({
            modalType: "AlertModal",
            modalProps: {
                title: "Are you sure?",
                message:
                    "Do you want to cancel this order? This action is not reversible!",
                actionTitle: "Yes",
                onClick: handleCancelOrder,
                showCancelBtn: true,
                cancelBtnText: "No",
            },
        });
    };

    const handleCancelOrder = async () => {
        try {
            const orderCancelRes = await cancelOrder({
                orderId: id,
            });

            if (orderCancelRes.data.status === 200) {
                showModal({
                    modalType: "AlertModal",
                    modalProps: {
                        title: orderCancelRes.data.data,
                        message: orderCancelRes.data.message,
                        actionTitle: "OK",
                    },
                });
            } else {
                showModal({
                    modalType: "AlertModal",
                    modalProps: {
                        title: "Error",
                        message:
                            orderCancelRes.data.data || "Could't cancel order",
                        actionTitle: "OK",
                    },
                });
            }
        } catch (error) {
            console.error(error);
            showModal({
                modalType: "AlertModal",
                modalProps: {
                    title: "Error",
                    message: "Could't cancel order",
                    actionTitle: "OK",
                },
            });
        }
    };

    return (
        <div className="order-detail-page py-5">
            {isOrderLoading ? (
                <OrderDetailSkeleton />
            ) : (
                <>
                    <div className="d-flex justify-content-between mb-3">
                        <div className="col-md-10">
                            <SectionTitle
                                text={
                                    <>
                                        Order&nbsp;
                                        <span className="order-number">
                                            #{orderData?.name}
                                        </span>
                                    </>
                                }
                            />
                        </div>
                        <div className="col-md-2 d-flex">
                            <Button
                                size="sm"
                                variant="primary ms-auto mb-auto"
                                fullWidth={false}
                                onClick={handleOpenCancelOrderPrompt}
                            >
                                Cancel order
                            </Button>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="h4">Placed From</div>
                            <div className="h6 my-3">{profileData?.name}</div>
                            {profileData?.address?.street && (
                                <div>Street: {profileData.address.street}</div>
                            )}

                            <div>
                                {profileData?.address?.city &&
                                    `${profileData.address.city}, `}
                                {profileData?.address?.province &&
                                    profileData.address.province}
                            </div>

                            <div>{profileData?.mobile}</div>

                            {profileData?.address?.zip && (
                                <div>Zip Code - {profileData.address.zip}</div>
                            )}
                        </div>
                        <div className="d-flex flex-wrap flex-column-reverse flex-sm-row justify-content-end justify-content-sm-between gap-0 gap-sm-2 gap-md-3 gap-lg-5">
                            <div className="d-flex flex-column align-items-end align-items-sm-start">
                                <div className="h4 text-end text-sm-start">
                                    Order Placed Date
                                </div>
                                <div className="order-date mt-3">
                                    {orderDate}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {isOrderLoading ? (
                <div className="mt-5 pt-5">
                    <TableSkeleton columnCount={4} />
                </div>
            ) : (
                <div className="order-table-container mt-5">
                    <div className="text-secondary mb-2">Order Details</div>
                    <div className="table-responsive w-100">
                        <table className="table table-borderless">
                            <thead className="table-header">
                                <tr>
                                    <th className="py-3">Description</th>
                                    <th className="py-3 align-right">Price</th>
                                    <th className="py-3 align-right">Qty</th>
                                    <th className="py-3 align-right">
                                        Total amount
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderData?.order_lines?.length &&
                                Array.isArray(orderData?.order_lines) ? (
                                    orderData.order_lines.map(
                                        (product, index) => (
                                            <tr key={product.id}>
                                                <td className="py-3">
                                                    {index + 1}.&nbsp;
                                                    {product.name_short}
                                                    {product.product_packaging_id
                                                        ? ` - ${product?.product_packaging_id[1]}`
                                                        : ""}
                                                </td>
                                                <td className="align-right py-3">
                                                    {parseCurrencyValue(
                                                        product.price_unit,
                                                        false,
                                                        product.currency_id[1]
                                                    )}
                                                </td>
                                                <td className="align-right py-3">
                                                    {product.product_uom_qty}
                                                </td>

                                                <td className="align-right py-3">
                                                    {parseCurrencyValue(
                                                        product.price_total,
                                                        false,
                                                        product.currency_id[1]
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    )
                                ) : (
                                    <span>No orders yet</span>
                                )}
                            </tbody>
                            <tfoot className="table-footer">
                                <tr>
                                    <td className="py-3 text-black">
                                        Delivery fee
                                    </td>
                                    <td />
                                    <td />
                                    <td className="align-right text-black">
                                        {parseCurrencyValue(
                                            orderData?.amount_delivery,
                                            false,
                                            orderData?.currency_id[1]
                                        )}
                                    </td>
                                </tr>
                                <tr className="border-top border-dark">
                                    <td className="py-3">Grand Total</td>
                                    <td />
                                    <td />
                                    <td className="align-right">
                                        {parseCurrencyValue(
                                            orderData?.amount_total,
                                            false,
                                            orderData?.currency_id[1]
                                        )}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 ms-auto my-5 pb-5">
                        <Button onClick={handleNavigateToMyOrders}>
                            Back to My Orders
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}
