import React from "react";
import {
    parseBase64ImgSrc,
    getFormattedValidPeriod,
} from "../../utils/dataUtils";

import "./cards.scss";

const fallbackImagePath = "/images/product_placeholder.png";

export default function DealsImageCard({ image, startDate, endDate, onClick }) {
    const validPeriod = getFormattedValidPeriod(startDate, endDate);
    const dealsImage = parseBase64ImgSrc(image) || fallbackImagePath;

    return (
        <div className="deals-image-card" role="button" onClick={onClick}>
            <div className="deals-image-wrapper">
                <img className="deals-image" src={dealsImage} alt="" />
            </div>
            <div className="deals-details-wrapper">
                <div className="valid-date py-2 px-4 mt-2">{validPeriod}</div>
            </div>
        </div>
    );
}
