import React from "react";
import { useField } from "formik";
import "../forms.scss";

export default function LocationPickerField({
    name,
    label,
    placeholder,
    value,
    actionTitle,
    onClick,
    ...rest
}) {
    const [field, meta, helpers] = useField({ name, label, ...rest });

    React.useEffect(() => {
        if (value) {
            helpers.setValue(value);
        }
    }, [value]);

    const handleOnClick = (e) => {
        e.stopPropagation();
        if (onClick) {
            onClick();
        }
    };

    return (
        <div className="formfield-wrapper">
            <div
                className={`formfield-root ${
                    meta.touched && meta.error ? "invalid" : ""
                }`}
            >
                {label && (
                    <label htmlFor={name} className="formfield-label">
                        {label}
                    </label>
                )}
                <input
                    className="formfield-input"
                    placeholder={placeholder || ""}
                    type="text"
                    disabled
                    {...field}
                    {...rest}
                />
                {actionTitle && (
                    <button
                        type="button"
                        className="formfield-end-btn"
                        onClick={handleOnClick}
                    >
                        {actionTitle}
                    </button>
                )}
            </div>
            <div
                hidden={!(meta.touched && meta.error)}
                className="formfield-error"
            >
                {meta.error}
            </div>
        </div>
    );
}
