import { createApi } from "@reduxjs/toolkit/query/react";
import { publicBaseQuery } from "./baseQueries";

export const authAPI = createApi({
    reducerPath: "authAPI",
    baseQuery: publicBaseQuery,
    endpoints: (build) => ({
        signIn: build.mutation({
            query: (formData) => ({
                url: `/api/session/authenticate/`,
                method: "POST",
                body: {
                    params: {
                        ...formData,
                        method: "POST",
                    },
                },
                credentials: "include",
            }),
            transformResponse: (response) => ({
                data: response?.result?.response,
                status: response?.result?.status,
            }),
        }),
        signUp: build.mutation({
            query: (formData) => ({
                url: "/api/register/",
                method: "POST",
                body: {
                    params: {
                        ...formData,
                        method: "POST",
                    },
                },
                credentials: "include",
            }),
            transformResponse: (response) => ({
                data: response?.result?.response,
                status: response?.result?.status,
            }),
        }),
        addMobile: build.mutation({
            query: (formData) => ({
                url: "/api/otp/",
                method: "POST",
                body: {
                    params: {
                        ...formData,
                        method: "POST",
                    },
                },
            }),
            transformResponse: (response) => response?.result,
        }),
        verifyMobile: build.mutation({
            query: (formData) => ({
                url: "/api/otp/",
                method: "PUT",
                body: {
                    params: {
                        ...formData,
                        method: "PUT",
                    },
                },
            }),
            transformResponse: (response) => ({
                data: response?.result?.response,
                status: response?.result?.status,
            }),
        }),
        checkSession: build.mutation({
            query: () => ({
                url: "/api/session/check/",
                method: "POST",
                body: {
                    params: {
                        method: "GET",
                    },
                },
                credentials: "include",
            }),
        }),
        signOut: build.mutation({
            query: () => ({
                url: "/api/session/logout/",
                method: "POST",
                body: {
                    params: {
                        method: "POST",
                    },
                },
                credentials: "include",
            }),
            transformResponse: (response) => response?.result,
        }),
        resetPassword: build.mutation({
            query: (formData) => ({
                url: "/api/reset_password",
                method: "POST",
                body: {
                    params: {
                        method: "POST",
                        ...formData,
                    },
                },
            }),
            transformResponse: (response) => ({
                data: response?.result?.response,
                status: response?.result?.status,
            }),
        }),
    }),
});

export const {
    useSignInMutation,
    useSignUpMutation,
    useAddMobileMutation,
    useVerifyMobileMutation,
    useCheckSessionMutation,
    useSignOutMutation,
    useResetPasswordMutation,
} = authAPI;
