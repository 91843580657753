import React from "react";

function ProductInformationSkeleton() {
    return (
        <div className="d-flex ms-md-5 ms-0 mb-2">
            <div className="col-10">
                <div className="product-title mb-3">
                    <div className="placeholder-glow">
                        <div className="placeholder p-3 rounded w-100" />
                    </div>
                </div>
                <div className="mb-1">
                    <div className="placeholder-glow">
                        <div className="placeholder rounded col-12" />
                        <div className="placeholder rounded col-9" />
                        <div className="placeholder rounded col-6" />
                    </div>
                </div>
                <div className="mb-3">
                    <div className="placeholder-glow">
                        <div className="placeholder rounded col-2" />
                    </div>
                </div>
                <div>
                    <div className="d-block mb-3">
                        <div className="placeholder-glow">
                            <div className="placeholder rounded col-4" />
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="placeholder-glow w-100">
                            <div className="placeholder rounded col-3" />
                        </div>
                    </div>
                    <div className="stock-status text-dark mb-3">
                        <div className="placeholder-glow">
                            <div className="placeholder rounded col-2" />
                        </div>
                    </div>
                    <div className=" mb-3 w-100">
                        <div className="label d-flex gap-3 w-100">
                            <div className="placeholder-glow col-1">
                                <div className="placeholder rounded w-100" />
                            </div>
                            <div className="placeholder-glow col-2">
                                <div className="placeholder rounded w-100" />
                            </div>
                        </div>
                        <div className="d-inline-block">
                            <div className="placeholder-glow">
                                <div className="placeholder rounded col-2" />
                            </div>
                        </div>
                    </div>
                    <div className="d-block">
                        <div className="placeholder-glow">
                            <div className="placeholder rounded col-2" />
                        </div>
                    </div>
                    <div className="placeholder-glow mt-3">
                        <div className="placeholder rounded col-4 p-4" />
                    </div>
                </div>
            </div>
            <div className="col-1 ms-0 ms-md-5 mb-4">
                <div className="placeholder-glow">
                    <div className="placeholder p-3 rounded w-100" />
                </div>
            </div>
        </div>
    );
}

export default ProductInformationSkeleton;
